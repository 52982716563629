import styled, { css } from 'styled-components';
import { Box, Link, Typography } from '@mui/material';
import { cellStyles, cellWithIconStyles, ROWS_HEIGHT } from 'src/components/DataGrid/DataGrid.styles';
import { StyledIcon } from 'src/components/CopyIcon/CopyIcon.styles';

const variantSelector = (props: { 'data-has-link'?: boolean }) => {
    if (!props['data-has-link']) {
        return css`
            & ${StyledIcon} {
                ${cellWithIconStyles}
            }
        `;
    }
};

export const StyledLinkedCell = styled(Link)`
    &&.MuiLink-root {
        overflow: hidden;
        display: flex;
        align-items: center;
        width: inherit;
        height: ${ROWS_HEIGHT}px;
        text-decoration: none;
        box-sizing: border-box;
        gap: 8px;

        &:hover {
            background: none;

            svg {
                filter: brightness(0.9)
            }
        }
    }
`;

export const StyledContent = styled(Typography)`
    && {
        font-family: ${({ theme }) => theme.fonts.normal};
        color: ${({ theme }) => theme.palette.black};
        text-transform: none;
        font-size: 16px;
        box-sizing: border-box;

        ${cellStyles}
    }
`;

export const StyledLinkedContent = styled(Typography)`
    && {
        font-family: ${({ theme }) => theme.fonts.normal};
        color: ${({ theme }) => theme.palette.black};
        text-transform: none;
        font-size: 16px;
        box-sizing: border-box;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`;

export const StyledContainer = styled(Box)`
    &&& {
        flex-grow: 1;
        height: 100%;
        display: flex;
        align-items: center;
        overflow: hidden;
        justify-content: space-between;

        ${variantSelector}
        
        &:hover ${StyledIcon} {
            opacity: 1;
        }
    }
`;
