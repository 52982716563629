import React from 'react';
import WalletIcon from './WalletIcon';
import CardIcon from './CardIcon';

interface CreditCardProps {
    brand?: string | null;
    wallet?: string | null;
}

const CardType: React.FC<CreditCardProps> = ({ brand, wallet }) => {
    return (
        <>
            {wallet && <WalletIcon wallet={wallet} />}
            {brand && (
                <>
                    <CardIcon brand={brand} /> {brand}
                </>
            )}
        </>
    );
};

export default CardType;
