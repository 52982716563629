import styled from 'styled-components';
import { Box } from '@mui/material';

export const StyledInfo = styled(Box)`
  && {
    font-family: ${({ theme }) => theme.fonts.normal};
    color: ${({ theme }) => theme.palette.black};
    display: flex;
    align-items: center;  
    height: 40px;  
    font-size: 16px;
      
    @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
      font-size: 14px;
      height: 24px;
    }
  }
`;
