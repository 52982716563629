import { Box } from '@mui/material';
import React from 'react';
import { DiscountDuration } from './constants';
import { additionalShortInputWidth, BillingCycleLengthInMonth, billingCycleLengthRenderMapping } from 'src/constants';
import { makePlural } from 'src/services/text-modifier';
import { RadioGroupItem } from 'src/components/Radio/RadioGroup';
import { DurationInput } from 'src/components/Inputs';

export const getDiscountDurationRenderLabels = (
    billingCycleLength: BillingCycleLengthInMonth,
    discountDurationLength?: number,
): Record<DiscountDuration, string> => ({
    [DiscountDuration.FIRST_CYCLE]: `First ${billingCycleLengthRenderMapping[billingCycleLength]}`,
    [DiscountDuration.MULTIPLE_CYCLE]:
        makePlural(
            `${discountDurationLength ?? 'Multiple'} ${billingCycleLengthRenderMapping[billingCycleLength]}`,
            Number(discountDurationLength),
        ),
    [DiscountDuration.FOREVER]: 'Forever',
});

export const getDiscountDurationRadioButtons = (
    {
        billingCycleLength,
        numberOfCycles,
        discountDuration,
        setDiscountDurationLength,
        discountDurationLength,
    }: {
        billingCycleLength: BillingCycleLengthInMonth;
        numberOfCycles: number;
        discountDuration: DiscountDuration | undefined;
        discountDurationLength: number;
        setDiscountDurationLength: (value?: number) => void;
    },
): Array<RadioGroupItem<DiscountDuration>> => {
    const discountDurationRenderLabels = getDiscountDurationRenderLabels(billingCycleLength);

    return [
        {
            value: DiscountDuration.FIRST_CYCLE,
            label: discountDurationRenderLabels[DiscountDuration.FIRST_CYCLE],
        },
        {
            value: DiscountDuration.MULTIPLE_CYCLE,
            label: discountDurationRenderLabels[DiscountDuration.MULTIPLE_CYCLE],
        },
        {
            value: DiscountDuration.FOREVER,
            label: discountDurationRenderLabels[DiscountDuration.FOREVER],
            ...(discountDuration === DiscountDuration.MULTIPLE_CYCLE && {
                additionalNode: (
                    <Box maxWidth={additionalShortInputWidth}>
                        <DurationInput
                            value={discountDurationLength}
                            onChange={setDiscountDurationLength}
                            minValue={Number(numberOfCycles)}
                            adornmentText={billingCycleLength && (
                                billingCycleLengthRenderMapping[billingCycleLength]
                            )}
                        />
                    </Box>
                ),
                additionalNodeProps: {
                    direction: 'column',
                    spacing: 2,
                },
            }),
        },
    ];
};
