import React from 'react';
import { ActiveStep } from './constants';
import { SubscriptionBillingType, SubscriptionType } from 'src/constants';
import { ConfirmationStep } from 'src/pages/udb/CreateSubscription/components/ConfirmationStep';
import { AccountOwnerStep } from 'src/pages/udb/CreateSubscription/components/AccountOwnerStep';
import { User } from 'src/@types/unified-db-api';
import { PricingTermsStep } from 'src/pages/udb/CreateSubscription/components/PricingTermsStep';
import { PricingTermsData } from 'src/pages/udb/CreateSubscription/components/PricingTermsStep/PricingTermsStep';
import { TypeAndBillingStep } from 'src/pages/udb/CreateSubscription/components/TypeAndBillingStep';
import type {
    TypeAndBillingData,
} from 'src/pages/udb/CreateSubscription/components/TypeAndBillingStep/TypeAndBillingStep';

export const isFreeInsider = (data: TypeAndBillingData) => data?.selectedBillingType === SubscriptionBillingType.FREE
    && data?.selectedSubscriptionType === SubscriptionType.INSIDER;

export const typeAndBillingNextClickHandler = (
    setActiveStep: React.Dispatch<React.SetStateAction<ActiveStep>>,
    setTypeAndBillingData: React.Dispatch<React.SetStateAction<Required<TypeAndBillingData> | undefined>>,
) => (
    data: Required<TypeAndBillingData>,
) => {
    setTypeAndBillingData(data);

    if (isFreeInsider(data)) {
        setActiveStep(ActiveStep.ACCOUNT_OWNER);
        return;
    }

    setActiveStep(ActiveStep.PRICING_TERMS);
};

export const pricingTermsChangeStepHandler = (
    nextStep: ActiveStep,
    setActiveStep: React.Dispatch<React.SetStateAction<ActiveStep>>,
    setPricingTermsData: React.Dispatch<React.SetStateAction<PricingTermsData>>,
) => (
    data: PricingTermsData,
) => {
    setPricingTermsData(data);
    setActiveStep(nextStep);
};

export const accountOwnerNextClickHandler = (
    setActiveStep: React.Dispatch<React.SetStateAction<ActiveStep>>,
    setOwnerData: React.Dispatch<React.SetStateAction<User | undefined>>,
) => (
    data: User,
) => {
    setOwnerData(data);
    setActiveStep(ActiveStep.CONFIRMATION);
};

export const accountOwnerGoBackHandler = (
    setActiveStep: React.Dispatch<React.SetStateAction<ActiveStep>>,
    setOwnerData: React.Dispatch<React.SetStateAction<User | undefined>>,
    typeAndBillingData: Required<TypeAndBillingData>,
) => (userData: User | null) => {
    if (userData) {
        setOwnerData(userData);
    }

    if (isFreeInsider(typeAndBillingData)) {
        setActiveStep(ActiveStep.TYPE_AND_BILLING);
        return;
    }

    setActiveStep(ActiveStep.PRICING_TERMS);
};

export const confirmStepGoBackHandler = (
    setActiveStep: React.Dispatch<React.SetStateAction<ActiveStep>>,
) => () => {
    setActiveStep(ActiveStep.ACCOUNT_OWNER);
};

export const reloadPageHandler = (
    {
        setActiveStep,
        setOwnerData,
        setPricingTermsData,
        setTypeAndBillingData,
    }: {
        setActiveStep: React.Dispatch<React.SetStateAction<ActiveStep>>;
        setOwnerData: React.Dispatch<React.SetStateAction<User | undefined>>;
        setPricingTermsData: React.Dispatch<React.SetStateAction<PricingTermsData>>;
        setTypeAndBillingData: React.Dispatch<React.SetStateAction<Required<TypeAndBillingData> | undefined>>;
    },
) => () => {
    setOwnerData(undefined);
    setPricingTermsData({});
    setTypeAndBillingData(undefined);
    setActiveStep(ActiveStep.TYPE_AND_BILLING);
};

export const creationStepController = ({
    ownerState,
    activeStepState,
    pricingTermsState,
    typeAndBillingState,
}: {
    ownerState: [User | undefined, React.Dispatch<React.SetStateAction<User | undefined>>];
    pricingTermsState: [PricingTermsData, React.Dispatch<React.SetStateAction<PricingTermsData>>];
    typeAndBillingState: [
        Required<TypeAndBillingData> | undefined,
        React.Dispatch<React.SetStateAction<Required<TypeAndBillingData> | undefined>>,
    ];
    activeStepState: [ActiveStep, React.Dispatch<React.SetStateAction<ActiveStep>>];
}) => {
    const [activeStep, setActiveStep] = activeStepState;

    const [ownerData, setOwnerData] = ownerState;
    const [pricingTermsData, setPricingTermsData] = pricingTermsState;
    const [typeAndBillingData, setTypeAndBillingData] = typeAndBillingState;

    switch (activeStep) {
        case ActiveStep.CONFIRMATION:
            if (!typeAndBillingData) {
                setActiveStep(ActiveStep.TYPE_AND_BILLING);
                return null;
            }
            if (!pricingTermsData[typeAndBillingData.selectedSubscriptionType] && !isFreeInsider(typeAndBillingData)) {
                setActiveStep(ActiveStep.PRICING_TERMS);
                return null;
            }
            if (!ownerData) {
                setActiveStep(ActiveStep.ACCOUNT_OWNER);
                return null;
            }

            return (
                <ConfirmationStep
                    handleGoBack={(confirmStepGoBackHandler(setActiveStep))}
                    reloadPage={
                        reloadPageHandler({
                            setActiveStep,
                            setOwnerData,
                            setPricingTermsData,
                            setTypeAndBillingData,
                        })
                    }
                    typeAndBillingData={typeAndBillingData}
                    pricingTermsData={pricingTermsData}
                    ownerData={ownerData}
                />
            );
        case ActiveStep.ACCOUNT_OWNER:
            if (!typeAndBillingData) {
                setActiveStep(ActiveStep.TYPE_AND_BILLING);
                return null;
            }
            if (!pricingTermsData[typeAndBillingData.selectedSubscriptionType] && !isFreeInsider(typeAndBillingData)) {
                setActiveStep(ActiveStep.PRICING_TERMS);
                return null;
            }

            return (
                <AccountOwnerStep
                    ownerData={ownerData}
                    handleGoBack={accountOwnerGoBackHandler(setActiveStep, setOwnerData, typeAndBillingData)}
                    handleNextButtonClick={accountOwnerNextClickHandler(setActiveStep, setOwnerData)}
                />
            );
        case ActiveStep.PRICING_TERMS:
            if (!typeAndBillingData) {
                setActiveStep(ActiveStep.TYPE_AND_BILLING);
                return null;
            }

            return (
                <PricingTermsStep
                    initialData={pricingTermsData}
                    numberOfCycles={typeAndBillingData.numberOfCycles}
                    billingCycleLength={typeAndBillingData.selectedBillingCycleLength}
                    selectedSubscriptionType={typeAndBillingData.selectedSubscriptionType}
                    selectedBillingType={typeAndBillingData.selectedBillingType}
                    handleGoBackClick={
                        pricingTermsChangeStepHandler(ActiveStep.TYPE_AND_BILLING, setActiveStep, setPricingTermsData)
                    }
                    handleNextButtonClick={
                        pricingTermsChangeStepHandler(ActiveStep.ACCOUNT_OWNER, setActiveStep, setPricingTermsData)
                    }
                />
            );
        case ActiveStep.TYPE_AND_BILLING:
        default:
            return (
                <TypeAndBillingStep
                    initialData={typeAndBillingData}
                    handleNextButtonClick={typeAndBillingNextClickHandler(setActiveStep, setTypeAndBillingData)}
                />
            );
    }
};
