import dayjs from 'dayjs';
import { DiscountDuration, PricingModel } from 'src/pages/udb/CreateSubscription/services/constants';
import { convertDateTimeZone, convertToUTCDate } from 'src/services/date-time-zone-converter';
import type { PricingTermsData } from 'src/pages/udb/CreateSubscription/components/PricingTermsStep/PricingTermsStep';
import {
    CustomPricingTerms,
    InitialBillingRecordRequest,
    SubscriptionActionParams,
    SubscriptionCreateParams,
} from 'src/@types/subscription-service-api';
import type {
    TypeAndBillingData,
} from 'src/pages/udb/CreateSubscription/components/TypeAndBillingStep/TypeAndBillingStep';
import {
    ActivationDate,
    BillingCycleLengthInMonth,
    DATE_TIME_ISO_8601,
    DiscountType,
    SubscriptionBillingType,
    SubscriptionType,
} from 'src/constants';

export const buildCustomAttributes = (
    selectedBillingCycleLength: BillingCycleLengthInMonth,
    numberOfCycles: number,
    selectedBillingType: SubscriptionBillingType,
) => ({
    ...(Number(selectedBillingCycleLength) === BillingCycleLengthInMonth.MONTH && {
        cycleLengthInMonths: Number(selectedBillingCycleLength),
    }),
    ...(selectedBillingType === SubscriptionBillingType.FREE
        && numberOfCycles > 1 && {
        cycleCount: Number(numberOfCycles),
    }),
});

export const buildCreateSubscriptionParams = ({
    selectedSubscriptionType,
    selectedBillingType,
    selectedBillingCycleLength,
    numberOfCycles,
}: Required<TypeAndBillingData>): SubscriptionCreateParams => {
    const customAttributes = buildCustomAttributes(selectedBillingCycleLength, numberOfCycles, selectedBillingType);

    return {
        type: selectedSubscriptionType,
        billingType: selectedBillingType,
        verticalIds: [1, 2],
        ...(!!Object.keys(customAttributes).length && { customAttributes }),
    };
};

export const buildCreateBillingRecordParams = (
    {
        selectedSubscriptionType,
        selectedBillingType,
    }: Required<TypeAndBillingData>,
    pricingTermsData: PricingTermsData,
): Partial<InitialBillingRecordRequest> => {
    return {
        requestedAmountOfUsers: selectedSubscriptionType === SubscriptionType.ENTERPRISE
            ? pricingTermsData[selectedSubscriptionType]!.priceData?.userCount
            : 1,
        priceInCents: selectedBillingType !== SubscriptionBillingType.FREE
            ? pricingTermsData[selectedSubscriptionType]!.finalPriceInCents
            : 0,
    };
};

export const buildActivationParams = ({
    activationDate,
    numberOfCycles,
    selectedActivationDateType,
}: Required<TypeAndBillingData>): SubscriptionActionParams => {
    const isSpecifiedDate = selectedActivationDateType === ActivationDate.SPECIFIED;

    return {
        ...(isSpecifiedDate && { activationDate: convertToUTCDate(activationDate!).format(DATE_TIME_ISO_8601) }),
        ...(numberOfCycles > 1 && { cycleCount: numberOfCycles }),
    };
};

export const shouldUpdateActivationDate = (typeAndBillingData: TypeAndBillingData) => {
    const { activationDate, selectedActivationDateType } = typeAndBillingData;

    return selectedActivationDateType === ActivationDate.SPECIFIED
        && dayjs(activationDate).isAfter(convertDateTimeZone(dayjs()))
        && !!activationDate;
};

export const getDiscountDurationInCycles = (discountDurationType?: DiscountDuration, cycleCount?: number) => {
    if (discountDurationType === DiscountDuration.FOREVER) {
        return null;
    }

    if (discountDurationType === DiscountDuration.FIRST_CYCLE) {
        return 1;
    }

    return cycleCount;
};

export const shouldUpdateCustomPricingTerms = (
    typeAndBillingData: Required<TypeAndBillingData>,
    pricingTerms: PricingTermsData,
) => {
    const { selectedBillingType, selectedSubscriptionType } = typeAndBillingData;
    const isInvoiced = selectedBillingType === SubscriptionBillingType.INVOICED;

    if (selectedSubscriptionType === SubscriptionType.ENTERPRISE) {
        return false;
    }

    const { hasDiscount, selectedPricingModel } = pricingTerms[SubscriptionType.INSIDER] || {};
    const isRenewableWithoutDiscount = selectedPricingModel === PricingModel.RENEWABLE_PRICE
        && !hasDiscount;

    if (isRenewableWithoutDiscount) {
        return false;
    }

    return isInvoiced;
};

export const getDiscountValue = (type?: DiscountType, amount?: number) => (
    type === DiscountType.FIXED && amount ? amount / 100 : amount
);

export const buildUpdateCustomPricingTermsParams = (
    pricingTerms: PricingTermsData,
): Partial<CustomPricingTerms> | undefined => {
    const {
        selectedPricingModel,
        finalPriceInCents,
        discountDuration,
        discountDurationLength,
        selectedDiscountType,
        discountAmount,
    } = pricingTerms[SubscriptionType.INSIDER]!;

    if (selectedPricingModel === PricingModel.FIXED_PRICE) {
        return {
            fixedPriceInCents: finalPriceInCents,
        };
    }

    return {
        discountDurationInCycles: getDiscountDurationInCycles(discountDuration, discountDurationLength),
        discountValue: getDiscountValue(selectedDiscountType, discountAmount),
        discountType: selectedDiscountType,
    };
};

export const buildUpdateSubscriptionParams = (
    typeAndBillingData: Required<TypeAndBillingData>,
    pricingTerms: PricingTermsData,
) => {
    const { activationDate } = typeAndBillingData;

    return {
        ...(shouldUpdateCustomPricingTerms(typeAndBillingData, pricingTerms)
            && { customPricingTerms: buildUpdateCustomPricingTermsParams(pricingTerms) }
        ),
        ...(shouldUpdateActivationDate(typeAndBillingData) && activationDate
            && { activationDate: convertToUTCDate(activationDate).format(DATE_TIME_ISO_8601) }
        ),
    };
};
