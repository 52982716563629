import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PageTitle from 'src/components/PageTitle';
import { Button, TextButton } from 'src/components/Buttons';
import * as intercomService from 'src/services/intercom';
import { configSelector, userSelector } from 'src/redux/slices';
import { SingleModalLayout } from 'src/layouts';
import { ConfigState, CurrentUserState } from 'src/@types/redux';
import type { RootState } from 'src/redux/root-reducer';

const TechnicalProblemMessage: FC = () => {
    const navigate = useNavigate();
    const { mainSiteUrl } = useSelector<RootState, ConfigState>(configSelector);
    const { currentUser } = useSelector<RootState, CurrentUserState>(userSelector);

    const handleNotifyClick = () => {
        intercomService.showIntercom();
    };

    return (
        <SingleModalLayout variant="message" testId="technical-problem-message">
            <>
                <PageTitle title="A technical problem has occured" variant="form" textAlign="center">
                        Please try again. If the problem persists, please&nbsp;
                    <TextButton onClick={handleNotifyClick}>notify us</TextButton> directly.
                </PageTitle>
                <Stack direction="row" spacing={2.5} marginTop={3}>
                    <Button href={mainSiteUrl} fullWidth variant="secondary">
                        Latest news
                    </Button>
                    <Button onClick={() => navigate(currentUser ? '/' : '/log-in')} fullWidth variant="secondary">
                        {currentUser ? 'Reader profile' : 'Log in'}
                    </Button>
                </Stack>
            </>
        </SingleModalLayout>
    );
};

export default TechnicalProblemMessage;
