import styled from 'styled-components';
import { Typography } from '@mui/material';

export const StyledHeaderName = styled(Typography)`
    && {
        color: ${({ theme }) => theme.palette.lightGrey};
        font-family: ${({ theme }) => theme.fonts.bold};
        font-size: 12px;
        line-height: normal;
        text-transform: uppercase;
        
        @media (min-width: ${({ theme }) => theme.brakePoints.md}) {
            font-family: ${({ theme }) => theme.fonts.bold};
            font-size: 14px;
        }
    }
`;
