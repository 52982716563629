import React, { FC, useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import QRCode from 'qrcode.react';
import { useSelector } from 'react-redux';
import { StyledDescription, StyledQR, StyledQRDescription, StyledText } from './TwoFactorAuthForm.styles';
import { tfaCodeValidate } from 'src/services/validators';
import TFACodeInput from 'src/components/Inputs/TFACodeInput/TFACodeInput';
import { StyledSwitch } from 'src/components/Switch';
import StyledTitle from 'src/components/Title';
import { Button } from 'src/components/Buttons';
import { LoaderOverlay } from 'src/components/LoaderOverlay';
import Line from 'src/components/Line';
import { useSnackbarMessage } from 'src/hooks';
import { configSelector, userSelector } from 'src/redux/slices';
import { generateQRUrl, generateTfaKey, tfaKeyToSecret } from 'src/services/tfa-key-generator';
import { useConfirmationModal } from 'src/hooks/useConfirmationModal';
import { GeneralModalKey, SnackbarMessageVariants } from 'src/constants';
import { disableTwoFactorAuth, enabledTwoFactorAuth, ssoApiClient } from 'src/services/sso-api';
import { ConfigState, CurrentUserState } from 'src/@types/redux';
import type { RootState } from 'src/redux/root-reducer';

const TwoFactorAuthForm: FC = () => {
    const { addMessage } = useSnackbarMessage();
    const { appName } = useSelector<RootState, ConfigState>(configSelector);
    const { currentUser } = useSelector<RootState, CurrentUserState>(userSelector);
    const [twoFactorKey, setTwoFactorKey] = useState<string>('');
    const [qrLink, setQrLink] = useState<string>('');

    useEffect(() => {
        if (!currentUser) {
            return;
        }

        setQrLink(generateQRUrl(appName, currentUser.email, twoFactorKey));
    }, [twoFactorKey, currentUser]);

    if (!currentUser) {
        return null;
    }

    const [isChecked, setIsChecked] = useState<boolean>(currentUser.tfaEnabled);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [codeInput, setCodeInput] = useState<string>('');

    const isValidCode = tfaCodeValidate(codeInput);

    useEffect(() => {
        setIsChecked(currentUser.tfaEnabled);
    }, [currentUser.tfaEnabled]);

    const enableTwoFactorAuth = () => {
        setIsSubmitting(true);

        enabledTwoFactorAuth(twoFactorKey, codeInput)
            .then(() => ssoApiClient.refreshToken())
            .then(() => {
                addMessage('Two-factor authentication enabled', SnackbarMessageVariants.SUCCESS);
            })
            .catch(() => {
                addMessage('Failed to enable two-factor authentication', SnackbarMessageVariants.ERROR);
            })
            .finally(() => {
                setCodeInput('');
                setIsSubmitting(false);
            });
    };

    const { openModal } = useConfirmationModal({
        onConfirm: async () => {
            try {
                await disableTwoFactorAuth();
                await ssoApiClient.refreshToken();
                addMessage('Two-factor authentication disabled', SnackbarMessageVariants.SUCCESS);
                setIsChecked(false);
            } catch {
                addMessage('Failed to disable two-factor authentication', SnackbarMessageVariants.ERROR);
            }
        },
    });

    const handleOpenQRCodeModal = () => {
        openModal({
            key: GeneralModalKey.QRCodeModal,
            extraProps: {
                tfaSecret: tfaKeyToSecret(twoFactorKey),
            },
        });
    };

    const toggleChecked = () => {
        if (currentUser.tfaEnabled) {
            openModal({
                key: GeneralModalKey.confirmationModal,
                title: 'Disable two-factor authentication',
                extraProps: {
                    header: 'Are you sure you want to proceed?',
                    text: 'Disabling two-factor authentication will make your account less secure.',
                    confirmButtonText: 'Proceed',
                },
            });
        } else {
            setTwoFactorKey(generateTfaKey());
            setIsChecked(!isChecked);
        }
    };

    return (
        <Stack>
            {isSubmitting && (
                <LoaderOverlay />
            )}
            <Box gap={5.75} display="flex" alignItems="center" justifyContent="space-between">
                <Stack spacing={0.5}>
                    <StyledText data-testid="enable-tfa-text">
                      Enable two-factor authentication
                    </StyledText>
                    <StyledDescription>
                      Get codes from an app like Microsoft Authenticator, Authy, 1Password, or Google Authenticator
                    </StyledDescription>
                </Stack>
                <StyledSwitch
                    checked={isChecked}
                    onClick={toggleChecked}
                    data-testid="toggle-tfa-switch"
                />
            </Box>
            {!currentUser.tfaEnabled && isChecked && (
                <>
                    <Stack paddingY={3}>
                        <Line />
                    </Stack>
                    <Box display="flex" gap={{ xs: 3, sm: 4 }} flexDirection={{ xs: 'column', md: 'row' }} alignItems="center">
                        <StyledQR alignItems="center" flexDirection="column">
                            <QRCode value={qrLink} size={170} />
                            <StyledQRDescription
                                onClick={handleOpenQRCodeModal}
                            >
                            I can&apos;t scan the QR code
                            </StyledQRDescription>
                        </StyledQR>
                        <Stack>
                            <Stack gap={1}>
                                <StyledTitle>How to enable two-factor authentication?</StyledTitle>
                                <StyledText>
                                1. Download an &quot;authenticator&quot; app from your app store and open it.
                                </StyledText>
                                <StyledText>
                                2. Pair the app by scanning the QR code.
                                </StyledText>
                                <StyledText>
                                3. Verify that the pairing was successful by entering a code below:
                                </StyledText>
                            </Stack>
                            <Box
                                width={{ xs: '100%', md: 404 }}
                                paddingTop={{ xs: 3, md: 2 }}
                                gap={{ xs: 3, md: 1 }}
                                display="flex"
                                flexDirection={{ xs: 'column', md: 'row' }}
                            >
                                <TFACodeInput
                                    codeInput={codeInput}
                                    setCodeInput={setCodeInput}
                                    isValidCode={isValidCode}
                                    disabled={isSubmitting}
                                    enableTwoFactorAuth={enableTwoFactorAuth}
                                />
                                <Button
                                    minSize="small"
                                    onClick={enableTwoFactorAuth}
                                    disabled={!isValidCode || isSubmitting}
                                    data-testid="enable-tfa-button"
                                >
                                    Save
                                </Button>
                            </Box>
                        </Stack>
                    </Box>
                </>
            )}
        </Stack>
    );
};

export default TwoFactorAuthForm;
