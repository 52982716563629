import { useSelector } from 'react-redux';
import type { ConfigState } from 'src/@types/redux';
import type { RootState } from 'src/redux/root-reducer';
import { configSelector } from 'src/redux/slices';

export enum Feature {
    accountUpgrade = 'accountUpgrade',
    accountCreate = 'accountCreate',
}

export const useIsFeatureEnabled = (featureName: Feature): boolean => {
    const { features } = useSelector<RootState, ConfigState>(configSelector);

    return !!features[featureName];
};
