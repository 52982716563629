import dayjs from 'dayjs';
import {
    BillingRecordModel,
    BillingRecordWithRenewal,
    CalculatedRenewalItem,
    RenewalItem,
} from 'src/@types/subscription-service-api';
import { formatDate } from 'src/services/formatters';
import { DateFormats } from 'src/constants';

export type UpcomingRenewalModalData = {
    renewalPeriod: string;
    amountOfUsers: number;
    renewalIndex: number | null;
    calculatedOn: string;
    activeUsers: number;
    contentViews: number;
    currentPrice?: number | null;
    suggestedPriceInCents: number | null;
    pricePerUserInCents: number | null;
    finalPriceInCents: number | null;
    finalPricePerUserInCents: number | null;
    contractLink: string | null;
};

export type PulledRenewalData = Pick<UpcomingRenewalModalData, 'amountOfUsers' | 'renewalIndex' | 'calculatedOn' | 'activeUsers' | 'contentViews' | 'suggestedPriceInCents' | 'pricePerUserInCents' | 'currentPrice'>;

export const prepareUpcomingRenewalModalData = (
    renewal: RenewalItem,
    upcomingBillingRecord: BillingRecordWithRenewal,
    currentBillingRecord: BillingRecordModel | undefined,
): UpcomingRenewalModalData => ({
    renewalPeriod: `${formatDate(upcomingBillingRecord.cycleStart, DateFormats.DATE)} - ${formatDate(upcomingBillingRecord.cycleEnd, DateFormats.DATE)}`,
    amountOfUsers: upcomingBillingRecord.requestedAmountOfUsers ?? 0,
    renewalIndex: renewal.renewalIndex,
    calculatedOn: formatDate(renewal.calculatedOn),
    activeUsers: renewal.activeUsers,
    contentViews: renewal.contentViews,
    currentPrice: currentBillingRecord?.priceInCents && currentBillingRecord.priceInCents / 100,
    suggestedPriceInCents: renewal.calculatedPriceInCents,
    pricePerUserInCents:
        renewal.calculatedPriceInCents !== null && upcomingBillingRecord.requestedAmountOfUsers ? (
            renewal.calculatedPriceInCents / upcomingBillingRecord.requestedAmountOfUsers
        ) : null,
    finalPriceInCents: upcomingBillingRecord.priceInCents,
    finalPricePerUserInCents:
        upcomingBillingRecord.priceInCents !== null && upcomingBillingRecord.requestedAmountOfUsers ? (
            upcomingBillingRecord.priceInCents / upcomingBillingRecord.requestedAmountOfUsers
        ) : null,
    contractLink: upcomingBillingRecord.contractLink,
});

export const preparePulledData = (calculatedRenewal: CalculatedRenewalItem): PulledRenewalData => ({
    amountOfUsers: calculatedRenewal.totalUsers,
    renewalIndex: calculatedRenewal.renewalIndex,
    calculatedOn: dayjs().toString(),
    activeUsers: calculatedRenewal.activeUsers,
    contentViews: calculatedRenewal.contentViews,
    currentPrice: calculatedRenewal.currentPriceInCents / 100,
    suggestedPriceInCents: calculatedRenewal.calculatedPriceInCents,
    pricePerUserInCents: calculatedRenewal.totalUsers ? (
        calculatedRenewal.calculatedPriceInCents / calculatedRenewal.totalUsers) : null,
});
