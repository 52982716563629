import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { useReloadPage } from 'src/hooks/useReloadPage';
import { TabBarLabel } from 'src/components/Label/TabBarLabel';
import { Button } from 'src/components/Buttons';
import { TabBarVariants } from 'src/components/TabBar/TabBar';
import { TabBar } from 'src/components/TabBar';
import { userSelector } from 'src/redux/slices';
import { updateSettings } from 'src/services/sso-api';
import { SnackbarMessageVariants } from 'src/constants';
import { useGeneralModal, useSnackbarMessage } from 'src/hooks';
import { getTimeZoneType, TimeZoneType } from 'src/services/profile-settings-helpers';
import { LoaderOverlay } from 'src/components/LoaderOverlay';
import type { CurrentUserState } from 'src/@types/redux';
import type { RootState } from 'src/redux/root-reducer';

export type SettingsModalProps = {
    confirmButtonText?: string;
    cancelButtonText?: string;
};

const SettingsModal: FC<SettingsModalProps> = ({ confirmButtonText = 'Save', cancelButtonText = 'Cancel' }) => {
    const { currentUser } = useSelector<RootState, CurrentUserState>(userSelector);

    if (!currentUser) {
        return null;
    }

    const [selectedType, setSelectedType] = useState<TimeZoneType>(getTimeZoneType(currentUser.settings.timeZone));
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const { reloadPage } = useReloadPage();
    const { addMessage } = useSnackbarMessage();
    const { closeModal } = useGeneralModal();

    const handleClose = () => {
        closeModal();
        setSelectedType(getTimeZoneType(currentUser.settings.timeZone ?? TimeZoneType.UTC));
    };

    const handleTabChange = (value: string | number) => {
        setSelectedType(getTimeZoneType(value.toString()));
    };

    const handleSaveSettings = async () => {
        setIsSubmitting(true);

        const options = {
            timeZone: selectedType === TimeZoneType.UTC ? TimeZoneType.UTC : dayjs.tz.guess(),
        };

        await updateSettings(options)
            .then(async ({ timeZone }) => {
                reloadPage();
                setSelectedType(getTimeZoneType(timeZone));
                closeModal();
                addMessage('Settings updated', SnackbarMessageVariants.SUCCESS);
            })
            .catch(() => {
                addMessage('Failed to update settings', SnackbarMessageVariants.ERROR);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <Box data-testid="settings-modal">
            {isSubmitting && <LoaderOverlay />}
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
                alignItems={{ xs: 'flex-start', sm: 'center' }}
                paddingTop={{ xs: 0.5, sm: 0 }}
            >
                <TabBarLabel
                    paddingBottom={{ xs: 0.5, sm: 0 }}
                    fontSize={{ xs: 14, sm: 16 }}
                >
                        Timezone:
                </TabBarLabel>
                <TabBar
                    variant={TabBarVariants.SWITCHER}
                    onChange={handleTabChange}
                    initialValue={selectedType}
                    items={[
                        { label: 'UTC', value: TimeZoneType.UTC },
                        { label: 'Local time', value: TimeZoneType.LOCAL_TIME },
                    ]}
                />
            </Stack>
            <Stack
                direction="row"
                spacing={{ xs: 2, sm: 2.5 }}
                paddingTop={3}
            >
                <Button
                    data-testid="settings-modal-cancel"
                    variant="secondary"
                    fullWidth
                    minSize="small"
                    onClick={handleClose}
                >
                    {cancelButtonText}
                </Button>
                <Button
                    data-testid="settings-modal-save"
                    variant="primary"
                    fullWidth
                    minSize="small"
                    disabled={
                        getTimeZoneType(currentUser.settings.timeZone) === selectedType || isSubmitting
                    }
                    onClick={handleSaveSettings}
                >
                    {confirmButtonText}
                </Button>
            </Stack>
        </Box>
    );
};

export default SettingsModal;
