import React, { FC, useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import type { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import CheckoutForm from './CheckoutForm';
import { makeURL } from 'src/services/url-maker';
import { theme } from 'src/theme';
import type { CheckoutConfig } from 'src/@types/sso-api';
import type {
    AccountToUpgrade,
} from 'src/components/Forms/UpgradeToInsiderForms/UpgradeAccountForm/UpgradeAccountForm';
import RequestError from 'src/components/Forms/components/RequestError/RequestError';
import { Spinner } from 'src/components/Spinner';
import { useSnackbarMessage } from 'src/hooks';
import { getCheckoutConfig } from 'src/services/sso-api';
import { SnackbarMessageVariants } from 'src/constants';
import TTNormsProMediumWoff2 from 'src/assets/fonts/TT_Norms_Pro_Medium.woff2';
import TTNormsProMediumWoff from 'src/assets/fonts/TT_Norms_Pro_Medium.woff';
import TTNormsProMediumOtf from 'src/assets/fonts/TT_Norms_Pro_Medium.otf';
import TTNormsProMediumTtf from 'src/assets/fonts/TT_Norms_Pro_Medium.ttf';
import TTNormsProNormalWoff2 from 'src/assets/fonts/TT_Norms_Pro_Normal.woff2';
import TTNormsProNormalWoff from 'src/assets/fonts/TT_Norms_Pro_Normal.woff';
import TTNormsProNormalOtf from 'src/assets/fonts/TT_Norms_Pro_Normal.otf';
import TTNormsProNormalTtf from 'src/assets/fonts/TT_Norms_Pro_Normal.ttf';
import TTNormsProDemiBoldWoff2 from 'src/assets/fonts/TT_Norms_Pro_DemiBold.woff2';
import TTNormsProDemiBoldWoff from 'src/assets/fonts/TT_Norms_Pro_DemiBold.woff';
import TTNormsProDemiBoldOtf from 'src/assets/fonts/TT_Norms_Pro_DemiBold.otf';
import TTNormsProDemiBoldTtf from 'src/assets/fonts/TT_Norms_Pro_DemiBold.ttf';

type CheckoutProps = {
    accountToUpgrade: AccountToUpgrade;
    isNewUser: boolean;
};

const CheckoutFlow: FC<CheckoutProps> = ({ accountToUpgrade, isNewUser }) => {
    const [isLoading, setLoading] = useState<boolean>(true);
    const [checkoutConfig, setCheckoutConfig] = useState<CheckoutConfig>();
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>();
    const { addMessage } = useSnackbarMessage();

    useEffect(() => {
        getCheckoutConfig()
            .then(async (config) => {
                setCheckoutConfig(config);
                setStripePromise(loadStripe(config.apiPublicKey));
            })
            .catch(() => {
                addMessage('Failed to get checkout config', SnackbarMessageVariants.ERROR);
            })
            .finally(() => setLoading(false));
    }, []);

    if (isLoading) {
        return <Spinner />;
    }

    if (!checkoutConfig?.product || !stripePromise) {
        return <RequestError>Configuration issue occurred. Please reload the page or contact us</RequestError>;
    }

    return (
        <Elements
            stripe={stripePromise}
            options={{
                mode: 'subscription',
                amount: checkoutConfig.product.priceInCents,
                currency: 'usd',
                fonts: [{
                    family: theme.fonts.medium,
                    src: `url('${makeURL(TTNormsProMediumWoff2).toString()}') format('woff2'), url('${makeURL(TTNormsProMediumWoff).toString()}') format('woff'), url('${makeURL(TTNormsProMediumOtf).toString()}') format("opentype"), url('${makeURL(TTNormsProMediumTtf).toString()}') format("truetype")`,
                },
                {
                    family: theme.fonts.normal,
                    src: `url('${makeURL(TTNormsProNormalWoff2).toString()}') format('woff2'), url('${makeURL(TTNormsProNormalWoff).toString()}') format('woff'), url('${makeURL(TTNormsProNormalOtf).toString()}') format("opentype"), url('${makeURL(TTNormsProNormalTtf).toString()}') format("truetype")`,
                },
                {
                    family: theme.fonts.demiBold,
                    src: `url('${makeURL(TTNormsProDemiBoldWoff2).toString()}') format('woff2'), url('${makeURL(TTNormsProDemiBoldWoff).toString()}') format('woff'), url('${makeURL(TTNormsProDemiBoldOtf).toString()}') format("opentype"), url('${makeURL(TTNormsProDemiBoldTtf).toString()}') format("truetype")`,
                }],
            }}
        >
            <CheckoutForm
                priceInCents={checkoutConfig.product.priceInCents}
                priceId={checkoutConfig.product.priceId}
                productName={checkoutConfig.product.productName}
                isNewUser={isNewUser}
                userUUID={accountToUpgrade.uuid}
            />
        </Elements>
    );
};

export default CheckoutFlow;
