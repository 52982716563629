import React, { FC } from 'react';
import { useGeneralModal } from 'src/hooks';
import { BaseModal } from 'src/components/Modals/BaseModal';
import { EnableTFAModalForm } from 'src/components/Forms/EnableTFAModalForm';

const EnableTFAModal: FC = () => {
    const { closeModal } = useGeneralModal();

    return (
        <EnableTFAModalForm
            ModalContainer={BaseModal}
            titleMarginTop={{ xs: 0, sm: 1 }}
            manualSetupGoBackFunctionName="handleGoBack"
            modalContainerProps={{
                open: true,
                title: 'Enable two-factor authentication',
                dataTestId: 'enable-2fa-form',
                handleClose: closeModal,
            }}
            manualSetupContainerProps={{
                open: true,
                title: 'I can’t scan the QR code',
                handleClose: closeModal,
                showCloseAndGoBack: true,
                dataTestId: 'enable-2fa-manually-setup',
            }}
        />
    );

};

export default EnableTFAModal;
