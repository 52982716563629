import React, { FC, useState } from 'react';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import StyledTitle from 'src/components/Title';
import { useSnackbarMessage } from 'src/hooks';
import { SnackbarMessageVariants } from 'src/constants';
import {
    DEFAULT_MODAL_MAX_WIDTH,
    DEFAULT_MODAL_PADDING_BOTTOM,
    DEFAULT_MODAL_PADDING_X,
} from 'src/components/Modals/BaseModal';
import { LoaderOverlay } from 'src/components/LoaderOverlay';
import { generateLink } from 'src/services/sso-api';
import { CopyInput } from 'src/components/Inputs';
import { CountDown } from 'src/components/CountDown';
import { Button } from 'src/components/Buttons';

export enum LinkType {
    logInLink = 'logInLink',
    passwordLink = 'passwordLink',
    emailLink = 'emailLink',
}

export type GenerateLinkModalProps = {
    uuid: string;
    generateLinkType: LinkType;
};

const LinkTypeToScopeMap: Record<LinkType, 'change-email' | 'change-password' | 'log-in'> = {
    [LinkType.logInLink]: 'log-in',
    [LinkType.passwordLink]: 'change-password',
    [LinkType.emailLink]: 'change-email',
};

export const GenerateLinkTitleList: Record<LinkType, 'login' | 'password change' | 'email change'> = {
    [LinkType.logInLink]: 'login',
    [LinkType.passwordLink]: 'password change',
    [LinkType.emailLink]: 'email change',
};

const GenerateLinkModal: FC<GenerateLinkModalProps> = ({ uuid, generateLinkType }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [link, setLink] = useState<string>('');

    const generateTitle = GenerateLinkTitleList[generateLinkType];
    const requestTitle = generateTitle === 'email change' ? `an ${generateTitle}` : `a ${generateTitle}`;
    const { addMessage } = useSnackbarMessage();

    const handleGenerateLink = () => {
        setIsLoading(true);

        generateLink({
            uuid,
            scope: LinkTypeToScopeMap[generateLinkType],
        })
            .then((response) => {
                setLink(response.link);
            })
            .catch(() => {
                addMessage('Failed to generate a link', SnackbarMessageVariants.ERROR);
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <Box
            data-testid="generate-link-modal"
            position="relative"
            maxWidth={DEFAULT_MODAL_MAX_WIDTH}
            paddingX={DEFAULT_MODAL_PADDING_X}
            paddingBottom={DEFAULT_MODAL_PADDING_BOTTOM}
        >
            {link ? (
                <Box data-testid="generate-link-modal-content">
                    <StyledTitle data-font="medium-modal" marginBottom={{ xs: 1.5, sm: 1 }} textAlign="center">
                        Your requested {generateTitle} link has been generated. It will be valid for:
                    </StyledTitle>
                    <CountDown
                        marginBottom={2.5}
                        textAlign="center"
                        endDate={dayjs().add(1, 'day').format()}
                    />
                    <CopyInput
                        testId="copy-input"
                        name="copy"
                        label={`${generateTitle} link`}
                        value={link}
                        tooltipType="link"
                    />
                </Box>
            ) : (
                <Box>
                    {isLoading && <LoaderOverlay />}
                    <StyledTitle data-font="medium-modal" marginBottom={3}>
                        {`Click below to generate 
                        ${requestTitle} 
                        link. This link is a one-time use only and will be valid for 24 hours.`}
                    </StyledTitle>
                    <Button
                        data-testid="generate-link-modal-button"
                        onClick={handleGenerateLink}
                        fullWidth
                    >
                        Generate link
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default GenerateLinkModal;
