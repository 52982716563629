import React, { FC } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import Line from 'src/components/Line';
import { userRoleRenderMapping } from 'src/constants';
import { BadgeVariant } from 'src/components/Badge/Badge/Badge.styles';
import type { ManageActionType } from 'src/components/ManageActions/ManageActions';
import { ReactComponent as LinkIcon } from 'src/assets/icons/stripe-link-icon.svg';
import { ManageActions } from 'src/components/ManageActions';
import { Badge } from 'src/components/Badge';
import {
    LinkContainer,
    SecondaryTitle,
    StyledGrid,
    StyledStack,
    SubscriptionInfo,
} from 'src/components/SubscriptionManageHeader/ManageHeader.styles';
import type { Credentials } from 'src/@types/credentials';

type ManageUserHeaderProps = {
    credentials: Credentials;
    userName?: string;
    actionsList: Record<string, ManageActionType>;
};

const ManageCredentialsHeader: FC<ManageUserHeaderProps> = ({
    actionsList,
    userName,
    credentials,
}) => {
    const { email, role, uuid } = credentials;

    return (
        <StyledGrid data-testid="credentials-header" container alignItems="flex-start" marginY={{ xs: 2, sm: 2.5 }}>
            <Grid item xs={12} sm={9}>
                <StyledStack direction="row" gap={1}>
                    <SecondaryTitle>{userName}</SecondaryTitle>
                    <Badge label={userRoleRenderMapping[role]} variant={BadgeVariant.BLUE} />
                </StyledStack>
                <StyledStack direction="row" marginTop={0.5}>
                    <SubscriptionInfo>
                        {email}
                    </SubscriptionInfo>
                </StyledStack>
            </Grid>
            <Grid
                item
                container
                xs={3}
                justifyContent="flex-end"
                position={{ xs: 'absolute', sm: 'relative' }}
                top={0}
                right={0}
            >
                <ManageActions credentials={credentials} uuid={credentials.uuid} actionsList={actionsList} />
            </Grid>
            <Box flexGrow={1}>
                <Stack paddingY={1.5}><Line /></Stack>
                <LinkContainer
                    href={`/udb/users/${uuid}`}
                    target="_blank"
                >
                      Open associated user page
                    <LinkIcon width="16px" />
                </LinkContainer>
            </Box>
        </StyledGrid >
    );
};

export default ManageCredentialsHeader;
