import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import type { PricingTermsData } from 'src/pages/udb/CreateSubscription/components/PricingTermsStep/PricingTermsStep';
import type { User } from 'src/@types/unified-db-api';
import { subscriberTypeRenderMapping, SubscriptionType } from 'src/constants';
import PageTitle from 'src/components/PageTitle';
import { SingleModalLayout } from 'src/layouts';
import { Button } from 'src/components/Buttons';

type SuccessMessageProps = {
    subscriptionType: SubscriptionType;
    pricingTermsData: PricingTermsData;
    createdSubscriptionUUID: string;
    ownerData: User;
    reloadPage: () => void;
};

export const getOwnerTextText = (
    subscriptionType: SubscriptionType,
    ownerData: User,
    pricingTermsData: PricingTermsData,
) => {
    if (subscriptionType === SubscriptionType.INSIDER) {
        return <b>{ownerData.email.email}</b>;
    }

    if (ownerData.jobInfo?.companyName) {
        return <b>{ownerData.jobInfo.companyName}</b>;
    }

    if (pricingTermsData[subscriptionType]?.domains.length === 1) {
        return <b>{pricingTermsData[subscriptionType]!.domains[0].domain}</b>;
    }

    const biggestDomain = pricingTermsData[subscriptionType]?.domains.reduce((prev, current) => {
        return prev.userCount > current.userCount ? prev : current;
    });

    return (
        <>
            <b>
                {biggestDomain?.domain}
            </b> (and {(pricingTermsData[subscriptionType]?.domains.length || 1) - 1} other)
        </>
    );
};

const SuccessMessage: FC<SuccessMessageProps> = ({
    ownerData,
    pricingTermsData,
    subscriptionType,
    reloadPage,
    createdSubscriptionUUID,
}) => {
    const navigate = useNavigate();

    return (
        <SingleModalLayout>
            <Stack spacing={{ xs: 2, sm: 2.5 }}>
                <PageTitle
                    paddingBottom={{ xs: 1, sm: 0.5 }}
                    variant="form"
                    title="Subscription created successfully"
                    subtitle={(
                        <>
                            An {subscriberTypeRenderMapping[subscriptionType]} subscription&nbsp;
                            {subscriptionType === SubscriptionType.INSIDER ? 'owned by' : 'for'}&nbsp;
                            {getOwnerTextText(subscriptionType, ownerData, pricingTermsData)} has been created
                            successfully.
                        </>
                    )}
                />
                <Button onClick={() => navigate(`/udb/subscriptions/${createdSubscriptionUUID}`)}>Open subscription</Button>
                <Button variant="secondary" onClick={reloadPage}>Create another one</Button>
            </Stack>
        </SingleModalLayout>
    );
};

export default SuccessMessage;
