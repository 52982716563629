import { Accordion } from '@mui/material';
import styled from 'styled-components';

export const StyledAccordion = styled(Accordion)`
    && {
        width: 100%;
        box-shadow: none;
        background-color: transparent;

        &:before {
            display: none;
        }

        .MuiAccordionSummary-root {
            min-height: auto;
            margin-right: 8px;
            padding: 0;
        }

        .MuiAccordionSummary-content {
            margin: 0 4px 0 0;

            .MuiTypography-root {
                color: ${({ theme }) => theme.palette.lightBlack};
                font-family: ${({ theme }) => theme.fonts.normal};
                font-size: 14px;
            }
        }

        .MuiAccordionDetails-root {
            padding: 4px 0;
            
            p{
                color: ${({ theme }) => theme.palette.black};
                font-family: ${({ theme }) => theme.fonts.medium};
            }
        }
    }
`;
