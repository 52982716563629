import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import { StyledCheckboxLabel } from './CreateInitialPaymentRecordModal.styles';
import { PriceFormInput } from 'src/components/Inputs/PriceFormInput';
import { SubscriptionBillingType } from 'src/constants';
import { Checkbox } from 'src/components/Checkbox';
import { prepareBillingRequestData } from 'src/services/request-data-formatter';
import { createInitialBillingRecord } from 'src/services/subscription-service-api';
import { InitialBillingRecordRequest } from 'src/@types/subscription-service-api';
import {
    contractLinkValidator,
    numberOfPriceValidator,
    numberOfRequestedUserValidator,
    validateSchemaObject,
} from 'src/services/validators';
import { SignedText } from 'src/components/Modals/ContractDetailsModal/ContractDetailsModal.styles';
import { SwitchAdornment } from 'src/components/Inputs/Adornments/Switch';
import { LabeledInput } from 'src/components/Inputs';
import { useGeneralModal, useIsSubmitDisabled, useReloadPage } from 'src/hooks';
import { Button } from 'src/components/Buttons';
import { FormWrapper } from 'src/components/Forms/components';

export type CreateInitialPaymentRecordModalProps = {
    uuid: string;
    billingType: SubscriptionBillingType;
};

type BillingRecordFormValues = Partial<InitialBillingRecordRequest> & {
    priceInCents: string;
    requestedAmountOfUsers: string;
};

const CreateInitialPaymentRecordModal: FC<CreateInitialPaymentRecordModalProps> = ({ uuid, billingType }) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const isFree = billingType === SubscriptionBillingType.FREE;

    const initialValues = {
        priceInCents: '',
        requestedAmountOfUsers: '',
        contractLink: '',
        contractSigned: false,
    } as BillingRecordFormValues;

    const {
        onFormChangeHandler,
        isSubmitDisabled,
        setIsSubmitDisabled,
    } = useIsSubmitDisabled(initialValues, !isFree ? ['priceInCents'] : undefined);
    const { reloadPage } = useReloadPage();
    const { closeModal } = useGeneralModal();

    const handleSaveInitialRecord = async (
        values: BillingRecordFormValues,
    ) => {
        setIsSubmitting(true);
        setIsSubmitDisabled(true);

        const requestData = prepareBillingRequestData(values, initialValues);
        return createInitialBillingRecord(uuid, requestData)
            .then(() => {
                closeModal();
                reloadPage();
            })
            .finally(() => setIsSubmitting(false));
    };

    return (
        <FormWrapper
            onFormChange={onFormChangeHandler}
            testId="initial-billing-record-form"
            isSubmitting={isSubmitting}
            initialValues={{
                ...initialValues,
                priceInCents: isFree ? 0 : initialValues.priceInCents,
            } as BillingRecordFormValues}
            onSubmit={handleSaveInitialRecord}
            validationSchema={validateSchemaObject({
                priceInCents: numberOfPriceValidator,
                requestedAmountOfUsers: numberOfRequestedUserValidator,
                contractLink: contractLinkValidator,
            })}
        >
            <Stack>
                <PriceFormInput
                    testId="price-in-cents"
                    name="priceInCents"
                    label="Price"
                    placeholder=""
                    disabled={isFree}
                    fromForm={!isFree}
                    required={!isFree}
                />
                <LabeledInput
                    marginTop={{ xs: 2.5, md: 4 }}
                    type="number"
                    name="requestedAmountOfUsers"
                    testId="requested-amount-of-users"
                    label="Requested amount of users"
                    placeholder=""
                />
                <Stack marginTop={{ xs: 2.5, md: 4 }} position="relative">
                    <SignedText>Signed</SignedText>
                    <LabeledInput
                        disallowSpace
                        flexGrow={1}
                        placeholder="Add contract link"
                        name="contractLink"
                        label="Contract link"
                        testId="contract-link-input"
                        endAdornment={<SwitchAdornment />}
                    />
                </Stack>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    paddingY={3}
                    spacing={{ xs: 1, md: 1.5 }}
                >
                    <Checkbox disabled />
                    <StyledCheckboxLabel>
                        Create invoice
                    </StyledCheckboxLabel>
                </Stack>
                <Stack direction="row" spacing={{ xs: 2, sm: 2.5 }}>
                    <Button onClick={closeModal} minSize="small" variant="secondary" fullWidth>
                        Cancel
                    </Button>
                    <Button disabled={isSubmitting || (!isFree && isSubmitDisabled)} minSize="small" fullWidth type="submit">
                        Create
                    </Button>
                </Stack>
            </Stack>
        </FormWrapper>
    );
};

export default CreateInitialPaymentRecordModal;
