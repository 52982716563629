import React, { FC, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { getMinActivationDate } from './services/getters';
import { DatePicker, DatePickerProps } from 'src/components/DatePicker/DatePicker';
import { BillingCycleLengthInMonth } from 'src/constants';

type ActivationDatePickerProps = {
    billingCycleLength?: BillingCycleLengthInMonth;
    initialMinActivationDate?: string;
} & DatePickerProps;

const ActivateSubscriptionDatePicker: FC<ActivationDatePickerProps> = ({
    billingCycleLength,
    initialMinActivationDate,
    ...props
}) => {
    const [minDate, setMinDate] = useState<Dayjs>();

    useEffect(() => {
        if (initialMinActivationDate) {
            setMinDate(dayjs(initialMinActivationDate).add(1, 'hour'));
            return;
        }

        if (!billingCycleLength) {
            return;
        }

        getMinActivationDate?.(billingCycleLength, dayjs()).then((date) => {
            setMinDate(date);
        });
    }, [billingCycleLength]);

    return (
        <DatePicker
            {...props}
            minDate={minDate}
        />
    );
};

export default ActivateSubscriptionDatePicker;
