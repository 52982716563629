import { TABLE_SECTION_DEFAULT_COLUMNS } from 'src/constants';
import { GridColumnProps } from 'src/components/TableSection/TableSection';

export const gridColumnParams: GridColumnProps = {
    brand: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        column_title: 'Vendor',
        gap: { xs: 0, md: 1 },
    },
    last4: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        column_title: 'Card',
        gap: { xs: 0, md: 1 },
    },
    expiryDate: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        column_title: 'Expiry date',
        gap: { xs: 0, md: 1 },
    },
    fullName: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        column_title: 'Name on card',
        gap: { xs: 0, md: 1 },
    },
    type: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 1,
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        column_title: 'Type',
        gap: { xs: 0, md: 1 },
    },
};
