import React from 'react';
import { Box } from '@mui/material';
import { ActivateSubscriptionDatePicker } from 'src/components/DatePicker';
import { ActivationDate, additionalWideInputWidth, BillingCycleLengthInMonth } from 'src/constants';
import type { RadioGroupItem } from 'src/components/Radio/RadioGroup';

export const getActivationDateRadioItems = ({
    onChange,
    activationDate,
    selectedType,
    billingCycleLength,
    position,
    centeredDatePicker,
    initialMinActivationDate,
}: {
    onChange: (date: string | undefined) => void;
    activationDate?: string | null;
    selectedType?: ActivationDate;
    billingCycleLength?: BillingCycleLengthInMonth;
    position?: 'fixed' | 'absolute';
    centeredDatePicker?: 'center' | 'default';
    initialMinActivationDate?: string;
}): Array<RadioGroupItem<ActivationDate>> => [
    {
        value: ActivationDate.NOW,
        label: 'Now',
    },
    {
        value: ActivationDate.SPECIFIED,
        label: 'Specific date & time',
        ...(selectedType === ActivationDate.SPECIFIED && {
            additionalNodeProps: {
                direction: 'column',
                spacing: 2,
            },
            additionalNode: (
                <Box maxWidth={additionalWideInputWidth}>
                    <ActivateSubscriptionDatePicker
                        initialMinActivationDate={initialMinActivationDate}
                        billingCycleLength={billingCycleLength}
                        initialValue={activationDate}
                        handleDateChange={onChange}
                        centered={centeredDatePicker}
                        position={position}
                        dateTimeOnly
                        withSeconds
                    />
                </Box>
            ),
        }),
    },
    {
        value: ActivationDate.MANUALLY,
        label: 'Activate manually later',
    },
];

export const getActivationDate = (selectedActivationDateType: ActivationDate) => {
    if ([ActivationDate.MANUALLY, ActivationDate.NOW].includes(selectedActivationDateType)) {
        return null;
    }
};
