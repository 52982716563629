import React, { FC } from 'react';
import { Grid, GridProps } from '@mui/material';
import { StyledInfo } from './CredentialsDetailsSection.styles';
import { StyledHeaderName } from 'src/components/TableSection/HeaderSection/HeaderSection.styles';

type CredentialsDetailsItemProps = {
    title: string;
    value: string | React.ReactNode;
    gridProps: GridProps;
};

const CredentialsDetailsItem: FC<CredentialsDetailsItemProps> = ({ title, value, gridProps }) => {
    return (
        <Grid item {...gridProps}>
            <StyledHeaderName>{title}</StyledHeaderName>
            <StyledInfo>{value}</StyledInfo>
        </Grid>
    );
};

export default CredentialsDetailsItem;
