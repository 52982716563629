import React, { FC, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { StyledButtonTooltipText, StyledMessage, StyledStack } from './UpcomingRenewalModal.styles';
import { ContractLinkRow, FinalPriceRow, RenewalDataRow } from './components';
import { preparePulledData, prepareUpcomingRenewalModalData, PulledRenewalData } from './services/decorators';
import { BillingRecordModel } from 'src/@types/subscription-service-api';
import { DEFAULT_MODAL_MAX_WIDTH } from 'src/components/Modals/BaseModal';
import { LoaderOverlay } from 'src/components/LoaderOverlay';
import Tooltip from 'src/components/Tooltip';
import { useGeneralModal } from 'src/hooks';
import { formatDate, formatPrice } from 'src/services/formatters';
import { setUpcomingBillingRecord, subscriptionSelector, SubscriptionState } from 'src/redux/slices/subscription';
import {
    calculateRenewalIndex,
    createUpcomingBillingRecord,
    updateBillingRecord,
    updateRenewal,
} from 'src/services/subscription-service-api';
import { Button } from 'src/components/Buttons';
import type { RootState } from 'src/redux/root-reducer';
import { DATE_TIME_ISO_8601 } from 'src/constants';
import { ReactComponent as UpdateIcon } from 'src/assets/icons/update-icon.svg';

export type UpcomingRenewalModalProps = {
    subscriptionUUID: string;
};

const UpcomingRenewalModal: FC<UpcomingRenewalModalProps> = ({ subscriptionUUID }) => {
    const [pulledRenewalData, setPulledRenewalData] = useState<PulledRenewalData>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const {
        upcomingBillingRecord,
        currentBillingRecord,
    } = useSelector<RootState, SubscriptionState>(subscriptionSelector);

    const { closeModal } = useGeneralModal();
    const dispatch = useDispatch();

    const generateRenewalRecord = () => {
        setIsLoading(true);
        createUpcomingBillingRecord(subscriptionUUID)
            .then((nextBillingRecord) => {
                dispatch(setUpcomingBillingRecord(nextBillingRecord));
            })
            .finally(() => setIsLoading(false));
    };

    if (!upcomingBillingRecord || !upcomingBillingRecord.renewal) {
        return (
            <Stack spacing={3} maxWidth={DEFAULT_MODAL_MAX_WIDTH}>
                <StyledMessage textAlign="center">
                    There is no record to be shown. You can generate renewal record from the button below.
                </StyledMessage>
                <Button fullWidth onClick={generateRenewalRecord} testId="generate-button">
                    Generate renewal record
                </Button>
            </Stack>
        );
    }

    const upcomingRenewalModalData =
        prepareUpcomingRenewalModalData(upcomingBillingRecord.renewal, upcomingBillingRecord, currentBillingRecord);

    const shouldShowFinalPricePerUser =
        upcomingRenewalModalData.suggestedPriceInCents !== upcomingBillingRecord?.priceInCents;

    const handleSave = async (data: Partial<BillingRecordModel>) => {
        setIsLoading(true);
        return updateBillingRecord(subscriptionUUID, upcomingBillingRecord.id, data)
            .then((nextBillingRecord) => {
                dispatch(setUpcomingBillingRecord({
                    ...nextBillingRecord,
                    renewal: upcomingBillingRecord.renewal,
                }));
            })
            .finally(() => setIsLoading(false));
    };

    const handlePullCurrentData = () => {
        setIsLoading(true);
        calculateRenewalIndex(subscriptionUUID)
            .then(preparePulledData)
            .then(setPulledRenewalData)
            .finally(() => setIsLoading(false));
    };

    const updateRenewalData = () => {
        if (!pulledRenewalData || !upcomingBillingRecord.renewal) {
            return;
        }

        setIsLoading(true);

        Promise.all([
            updateBillingRecord(subscriptionUUID, upcomingBillingRecord.id, {
                requestedAmountOfUsers: pulledRenewalData.amountOfUsers,
            }),
            updateRenewal({
                uuid: subscriptionUUID,
                billingRecordId: upcomingBillingRecord.id,
                renewalId: upcomingBillingRecord.renewal.id,
            }, {
                calculatedOn: dayjs(pulledRenewalData.calculatedOn).utc().format(DATE_TIME_ISO_8601),
                renewalIndex: pulledRenewalData.renewalIndex,
            }),
        ])
            .then(([updatedBillingRecord, updatedRenewal]) => {
                setPulledRenewalData(undefined);
                dispatch(setUpcomingBillingRecord({
                    ...updatedBillingRecord,
                    renewal: updatedRenewal,
                }));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const renewalIndexFormatter = (renewalIndex: number | unknown) => {
        if (typeof renewalIndex !== 'number') {
            return 'N/A';
        }

        return renewalIndex.toString();
    };

    return (
        <Stack spacing={3}>
            {isLoading && <LoaderOverlay />}
            <StyledStack spacing={{ xs: 1.5, sm: 2.5 }}>
                <RenewalDataRow
                    title="Billing cycle:"
                    currentRenewalData={upcomingRenewalModalData.renewalPeriod}
                />
                <RenewalDataRow
                    title="Amount of users:"
                    currentRenewalData={upcomingRenewalModalData.amountOfUsers}
                    pulledRenewalData={pulledRenewalData?.amountOfUsers}
                />
                <RenewalDataRow
                    title="Renewal index:"
                    formatter={renewalIndexFormatter}
                    currentRenewalData={upcomingRenewalModalData.renewalIndex}
                    pulledRenewalData={pulledRenewalData?.renewalIndex}
                    currentDataTooltip={(
                        <>
                            <Stack direction={{ xs: 'column', sm: 'row' }}>
                                Calculated on {upcomingRenewalModalData.calculatedOn}
                                <Box marginLeft={{ xs: 0, sm: 0.5 }}>based on:</Box>
                            </Stack>
                            Active users: {upcomingRenewalModalData.activeUsers}<br />
                            Content views: {upcomingRenewalModalData.contentViews}<br />
                            Price: {upcomingRenewalModalData.currentPrice ?? 'N/A'}<br />
                        </>
                    )}
                    pulledDataTooltip={pulledRenewalData && (
                        <>
                            <Stack direction={{ xs: 'column', sm: 'row' }}>
                                Calculated on {formatDate(pulledRenewalData.calculatedOn)}
                                <Box marginLeft={{ xs: 0, sm: 0.5 }}>based on:</Box>
                            </Stack>
                            Active users: {pulledRenewalData.activeUsers}<br />
                            Content views: {pulledRenewalData.contentViews}<br />
                            Price: {pulledRenewalData.currentPrice ?? 'N/A'}<br />
                        </>
                    )}
                />
                <RenewalDataRow
                    title="Suggested price:"
                    currentRenewalData={upcomingRenewalModalData.suggestedPriceInCents}
                    pulledRenewalData={pulledRenewalData?.suggestedPriceInCents}
                    formatter={formatPrice}
                />
                <RenewalDataRow
                    title="Price per user:"
                    currentRenewalData={upcomingRenewalModalData.pricePerUserInCents}
                    pulledRenewalData={pulledRenewalData?.pricePerUserInCents}
                    formatter={formatPrice}
                />
                <FinalPriceRow
                    handleSave={handleSave}
                    finalPrice={upcomingRenewalModalData.finalPriceInCents}
                />
                {shouldShowFinalPricePerUser && (
                    <RenewalDataRow
                        title="Final price per user:"
                        currentRenewalData={upcomingRenewalModalData.finalPricePerUserInCents}
                        formatter={formatPrice}
                    />
                )}
                <ContractLinkRow
                    handleSave={handleSave}
                    contractLink={upcomingBillingRecord.contractLink}
                />
            </StyledStack>
            <Stack spacing={{ xs: 2, sm: 2.5 }}>
                {!pulledRenewalData ? (
                    <Button fullWidth variant="secondary" onClick={handlePullCurrentData} testId="pull-button">
                        Pull current data
                    </Button>
                ) : (
                    <Button fullWidth variant="secondary" onClick={updateRenewalData} testId="update-button">
                        <UpdateIcon />
                        Update stored data
                    </Button>
                )}
                <Tooltip
                    arrow
                    title={
                        upcomingBillingRecord.contractLink ? '' : (
                            <StyledButtonTooltipText>
                                Please add your contract link in order <br /> to be able to save
                            </StyledButtonTooltipText>
                        )}
                >
                    <Box>
                        <Button fullWidth onClick={closeModal} disabled={!upcomingBillingRecord.contractLink} testId="confirm-button">
                            Save and confirm
                        </Button>
                    </Box>
                </Tooltip>
            </Stack>
        </Stack>
    );
};

export default UpcomingRenewalModal;
