import React, { FC, Fragment } from 'react';
import { Grid } from '@mui/material';
import { getPaymentFields } from './services/getters';
import PaymentMethodItem from './PaymentMethodItem';
import { PaymentMethodDetails } from 'src/@types/sso-api';
import Line from 'src/components/Line';
import { TABLE_SECTION_DEFAULT_COLUMNS } from 'src/constants';
import { LoaderOverlay } from 'src/components/LoaderOverlay';
import { NoContentMessage } from 'src/components/TableSection/TableSection.styles';
import { PageSection } from 'src/components/PageSection';

type PaymentSectionProps = {
    isLoading: boolean;
    paymentMethod?: PaymentMethodDetails;
};

const PaymentMethodSection: FC<PaymentSectionProps> = ({
    paymentMethod,
    isLoading,
}) => {
    const cardDetails = paymentMethod?.card;
    const billingDetails = paymentMethod?.billingDetails;
    const paymentFields = getPaymentFields(cardDetails, billingDetails);

    return (
        <PageSection
            data-testid="payment-method-section"
            sectionPaddingBottom={3}
            header="Payment method"
        >
            {isLoading && <LoaderOverlay />}
            {!!cardDetails ? (
                <Grid container columns={TABLE_SECTION_DEFAULT_COLUMNS}>
                    {paymentFields.map((field, index) => (
                        <Fragment key={field.title}>
                            {index !== 0 && (
                                <Grid item display={{ xs: 'block', md: 'none' }} paddingY={2} xs={12}>
                                    <Line />
                                </Grid>
                            )}
                            <PaymentMethodItem
                                title={field.title}
                                value={field.value}
                                gridProps={field.gridProps}
                            />
                        </Fragment>
                    ))}
                </Grid>
            ) : (
                <NoContentMessage marginY={1.5}>
                    There are no payment method records to be shown
                </NoContentMessage>
            )}
        </PageSection>
    );
};

export default PaymentMethodSection;
