import React, { FC, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import type { FormikHelpers } from 'formik';
import { useDispatch } from 'react-redux';
import { logIn } from 'src/services/sso-api';
import { LogInFormValuesType } from 'src/@types/forms';
import { EMPTY_EMAIL_VALIDATION_ERROR, FAILED_LOG_IN_MESSAGE } from 'src/constants';
import { emailValidator, passwordValidator, validateSchemaObject } from 'src/services/validators';
import { ReactComponent as LinkIcon } from 'src/assets/icons/iconmonstr-link-1.svg';
import { Button } from 'src/components/Buttons';
import { LabeledInput } from 'src/components/Inputs';
import Line from 'src/components/Line';
import { SingleModalForm, StyledNavButton } from 'src/components/Forms/components';
import { setTFAAccessCode } from 'src/redux/slices';
import { PasswordInput } from 'src/components/Inputs/PasswordInput';

const LogInForm: FC = () => {
    const [isLogInFailed, setIsLogInFailed] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const initialValues: LogInFormValuesType = {
        email: '',
        password: '',
    };

    const validationSchema = validateSchemaObject({
        email: emailValidator.required(EMPTY_EMAIL_VALIDATION_ERROR),
        password: passwordValidator,
    });

    const handleLogIn = async (
        values: LogInFormValuesType,
        { setErrors }: FormikHelpers<LogInFormValuesType>,
    ): Promise<void> => {
        setIsLogInFailed(false);
        setIsSubmitting(true);
        await logIn(values)
            .then((data) => {
                if ('accessCode' in data) {
                    dispatch(setTFAAccessCode(data.accessCode));
                }
            })
            .catch(() => {
                setIsLogInFailed(true);
                setErrors({ email: ' ', password: ' ' });
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <SingleModalForm
            isSubmitting={isSubmitting}
            initialValues={initialValues}
            onSubmit={handleLogIn}
            validationSchema={validationSchema}
            testId="log-in-form"
            onFormChange={() => {
                setIsLogInFailed(false);
            }}
            fromError={isLogInFailed && FAILED_LOG_IN_MESSAGE}
            title="Log in"
            subtitle={(
                <>
                    Don&apos;t have an account yet?&nbsp;
                    <NavLink to="/create-account">Create account</NavLink>
                </>
            )}
            fieldsBlock={(
                <>
                    <LabeledInput
                        disallowSpace
                        disabled={isSubmitting}
                        name="email"
                        label="Email address"
                        placeholder="Enter your email address"
                        hasSubmittingError={isLogInFailed}
                    />
                    <Box>
                        <PasswordInput
                            disabled={isSubmitting}
                            name="password"
                            placeholder="Enter your password"
                            hasSubmittingError={isLogInFailed}
                        />
                        <Box position="absolute" right={0}>
                            <StyledNavButton
                                data-testid="reset-password"
                                onClick={() => navigate('/reset-password')}
                                disabled={isSubmitting}
                                type="button"
                            >
                                Forgot password?
                            </StyledNavButton>
                        </Box>
                    </Box>
                </>
            )}
            buttonsBlock={(
                <Stack paddingTop={{ xs: 2.5, md: 0 }} spacing={{ xs: 2, sm: 3 }}>
                    <Button type="submit" disabled={isSubmitting} testId="log-in-button">Log in</Button>
                    <Line>or</Line>
                    <Button
                        variant="secondary"
                        startIcon={<LinkIcon />}
                        testId="magic-link-button"
                        disabled={isSubmitting}
                        onClick={() => navigate('/log-in-link')}
                    >
                        Log in with magic link
                    </Button>
                </Stack>
            )}
        />
    );
};

export default LogInForm;
