import React, { FC, useState } from 'react';
import { Box, TypographyProps } from '@mui/material';
import { NoResultTitle } from './AddManagerModal.styles';
import StyledTitle from 'src/components/Title';
import { LoaderOverlay } from 'src/components/LoaderOverlay';
import Line from 'src/components/Line';
import {
    DEFAULT_MODAL_CONTENT_PADDING_TOP,
    DEFAULT_MODAL_PADDING_BOTTOM,
    DEFAULT_MODAL_PADDING_X,
} from 'src/components/Modals/BaseModal';
import type { User } from 'src/@types/unified-db-api';
import type { SubscriptionUserModel } from 'src/@types/subscription-service-api';
import { AddManagerForm, SearchManagerForm } from 'src/components/Forms/ManagerModalForm';

export type AddManagerModalProps = {
    subscriptionUUID: string;
    subscriptionManagers: SubscriptionUserModel[] | Omit<User, 'jobInfo'>[];
    onSuccess?: (user: User, isOwner: boolean) => void;
    paddingTop?: TypographyProps['paddingTop'];
};

const AddManagerModal: FC<AddManagerModalProps> = ({
    paddingTop = DEFAULT_MODAL_CONTENT_PADDING_TOP,
    subscriptionUUID,
    subscriptionManagers,
    onSuccess,
}) => {
    const [searchResult, setSearchResult] = useState<User | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    return (
        <Box data-testid="add-manager-modal" position="relative">
            {isLoading && <LoaderOverlay />}
            <StyledTitle
                data-font="medium-modal"
                marginBottom={{ xs: 2.5, sm: 3 }}
                paddingTop={paddingTop}
                paddingX={DEFAULT_MODAL_PADDING_X}
            >
                Please type the email address of the person you want to make a manager.
            </StyledTitle>
            <SearchManagerForm
                paddingX={DEFAULT_MODAL_PADDING_X}
                paddingBottom={{ xs: 3, sm: 4.5 }}
                setRequestResult={setSearchResult}
                setIsLoading={setIsLoading}
            />
            {searchResult !== null && (
                <>
                    <Line />
                    {searchResult ? (
                        <AddManagerForm
                            data={searchResult}
                            subscriptionUUID={subscriptionUUID}
                            subscriptionManagers={subscriptionManagers}
                            onSuccess={onSuccess}
                            setIsLoading={setIsLoading}
                        />
                    ) : (
                        <Box paddingY={DEFAULT_MODAL_PADDING_BOTTOM}>
                            <NoResultTitle>No results found. Please try again.</NoResultTitle>
                        </Box>
                    )}
                </>
            )}
        </Box>
    );
};

export default AddManagerModal;
