import React, { FC } from 'react';
import { Box, BoxProps, Stack } from '@mui/material';
import { StyledPaper, StyledPaperVariant } from './SingleModalLayout.styles';
import { Header, type HeaderProps } from './components';
import { GeneralModal } from 'src/components/Modals';
import { EnableTFABanner } from 'src/components/Banners';
import Line from 'src/components/Line';
import { CenteredFullScreenLayout } from 'src/layouts/CenteredFullScreenLayout';

type SingleModalPageProps = {
    children: BoxProps['children'];
    testId?: string;
    variant?: StyledPaperVariant;
    headerTitle?: string;
} & Pick<HeaderProps, 'goBackAction'>;

const SingleModalLayout: FC<SingleModalPageProps> = ({
    children,
    goBackAction,
    testId,
    variant = 'form',
    headerTitle,
}) => (
    <CenteredFullScreenLayout data-testid={testId}>
        <StyledPaper data-variant={variant}>
            <Stack>
                <Header title={headerTitle} goBackAction={goBackAction} marginY={{ sm: 3.5, xs: 3 }} />
                <Line />
                <Box
                    position="relative"
                    paddingX={{ sm: 4.5, xs: 2 }}
                    paddingTop={{ sm: 4, xs: 3 }}
                    paddingBottom={{ sm: 4.5, xs: 3 }}
                    data-testid="modal-page-content-container"
                >
                    {children}
                </Box>
            </Stack>
        </StyledPaper>
        <GeneralModal />
        <EnableTFABanner />
    </CenteredFullScreenLayout>
);

export default SingleModalLayout;
