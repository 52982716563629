import dayjs, { Dayjs } from 'dayjs';
import { convertToUTCDate } from './date-time-zone-converter';
import { SearchFilter, SearchFilters } from 'src/@types/search';
import { ToolBarModelType } from 'src/@types/tool-bar';
import {
    DATE_TIME_ISO_8601,
    SsoConditionValues,
    SubscriptionStatus,
    SubscriptionType,
    UdbConditions,
    UdbOperators,
} from 'src/constants';
import { DatePickerTypes, getDatePickerType } from 'src/services/date-picker-helpers';

export const getCurrentMonday = (): Dayjs => {
    const date = dayjs().hour(0).minute(0).second(0).millisecond(0);
    const monday = date.day(1).add(date.utcOffset(), 'minute');
    const isSunday = dayjs().day() === 0;

    if (isSunday) {
        return monday.subtract(7, 'day');
    }

    return monday;
};

const buildUdbCustomAttributesFilter = (
    name: string,
    value: string,
    comparison: UdbConditions,
): SearchFilters => ({
    filters: [
        {
            name: 'customAttributes.attributeName',
            value: name,
            comparison: UdbConditions.EQUAL,
        },
        {
            name: 'customAttributes.attributeValue',
            value,
            comparison,
        },
    ],
});

export const UDB_QUICK_FILTERS_MAPPING = {
    onlyActiveSubscriptions: () => ({
        name: 'status',
        comparison: UdbConditions.EQUAL,
        value: 'active',
    }),
    onlyExpiredSubscriptions: () => ({
        operator: UdbOperators.OR,
        filters: [{
            name: 'status',
            comparison: UdbConditions.EQUAL,
            value: 'serviceEnded',
        }, {
            name: 'status',
            comparison: UdbConditions.EQUAL,
            value: 'terminated',
        }],
    }),
    onlyDraftSubscriptions: () => ({
        filters: [{
            name: 'status',
            comparison: UdbConditions.EQUAL,
            value: 'draft',
        }],
    }),
    onGracePeriodSubscriptions: () => ({
        filters: [{
            name: 'expirationDate',
            comparison: UdbConditions.LESS_THAN,
            value: dayjs().toISOString(),
        }, {
            name: 'gracePeriodEndDate',
            comparison: UdbConditions.MORE_THAN,
            value: dayjs().toISOString(),
        }, {
            operator: UdbOperators.OR,
            filters: [{
                name: 'status',
                comparison: UdbConditions.EQUAL,
                value: SubscriptionStatus.ACTIVE,
            }, {
                name: 'status',
                comparison: UdbConditions.EQUAL,
                value: SubscriptionStatus.PAUSED,
            }],
        }],
    }),
    expiringNextWeekSubscriptions: () => ({
        filters: [{
            name: 'expirationDate',
            comparison: UdbConditions.LESS_THAN,
            value: getCurrentMonday().add(14, 'day').toISOString(),
        }, {
            name: 'expirationDate',
            comparison: UdbConditions.EQUAL_OR_MORE,
            value: getCurrentMonday().add(7, 'day').toISOString(),
        }],
    }),
    expiringThisWeekSubscriptions: () => ({
        filters: [{
            name: 'expirationDate',
            comparison: UdbConditions.LESS_THAN,
            value: getCurrentMonday().day(8).toISOString(),
        }, {
            name: 'expirationDate',
            comparison: UdbConditions.EQUAL_OR_MORE,
            value: dayjs().toISOString(),
        }],
    }),
    expiredThisWeekSubscriptions: () => ({
        filters: [{
            name: 'expirationDate',
            comparison: UdbConditions.EQUAL_OR_LESS,
            value: dayjs().toISOString(),
        }, {
            name: 'expirationDate',
            comparison: UdbConditions.EQUAL_OR_MORE,
            value: getCurrentMonday().toISOString(),
        }],
    }),
    expiredLastWeekSubscriptions: () => ({
        filters: [{
            name: 'expirationDate',
            comparison: UdbConditions.LESS_THAN,
            value: getCurrentMonday().toISOString(),
        }, {
            name: 'expirationDate',
            comparison: UdbConditions.EQUAL_OR_MORE,
            value: getCurrentMonday().subtract(7, 'day').toISOString(),
        }],
    }),
    onlyCanceledSubscriptions: () => ({
        filters: [{
            name: 'isCanceled',
            comparison: UdbConditions.EQUAL,
            value: true,
        }],
    }),
};

export const prepareUdbTimeParams = ({
    name,
    value,
    comparison,
}: {
    name: string;
    value: string;
    comparison: UdbConditions;
}): SearchFilter => {
    const filters = [];
    const dateObject = convertToUTCDate(value);
    const datePickerType = getDatePickerType(value);

    const hasTime = datePickerType === DatePickerTypes.DATE_AND_TIME;
    const shouldIncludeDay = [UdbConditions.EQUAL_OR_LESS, UdbConditions.MORE_THAN].includes(comparison);

    const definedHours = shouldIncludeDay ? 23 : 0;
    const definedMinutes = shouldIncludeDay ? 59 : 0;
    const definedSeconds = shouldIncludeDay ? 59 : 0;

    const filter = {
        name,
        comparison: comparison === UdbConditions.EQUAL ? UdbConditions.EQUAL_OR_MORE : comparison,
        value: dateObject
            .hour(hasTime ? dateObject.hour() : definedHours)
            .minute(hasTime ? dateObject.minute() : definedMinutes)
            .second(definedSeconds)
            .format(DATE_TIME_ISO_8601),
    };

    if (comparison === UdbConditions.EQUAL) {
        filters.push(
            filter,
            {
                name,
                comparison: UdbConditions.EQUAL_OR_LESS,
                value: dateObject
                    .hour(hasTime ? dateObject.hour() : 23)
                    .minute(hasTime ? dateObject.minute() : 59)
                    .second(59)
                    .format(DATE_TIME_ISO_8601),
            },
        );
    }

    return {
        ...(filters.length ? { operator: UdbOperators.AND, filters } : filter),
    };
};

export const getFiltersUdbApiParams = (
    filtersModel: ToolBarModelType,
    type?: SubscriptionType,
): SearchFilters => {
    const fields = Object.entries(filtersModel)
        .reduce<SearchFilters[]>((preparedFields, [name, conditionObject]) => {
        const preparedCondition = Object.entries(conditionObject)
            .map<SearchFilter>(([comparison, value]) => {
            const timeParams = ['activationDate', 'expirationDate', 'gracePeriodEndDate', 'createdAt'];
            const customAttributesParams = ['stripeSubscriptionId', 'stripeCustomerId'];

            if (customAttributesParams.includes(name)) {
                return buildUdbCustomAttributesFilter(name, value as string, comparison as UdbConditions);
            }
            if (name === 'quickAction') {
                return UDB_QUICK_FILTERS_MAPPING[value as keyof typeof UDB_QUICK_FILTERS_MAPPING]?.();
            }
            if (timeParams.includes(name)) {
                return prepareUdbTimeParams({
                    name,
                    value: `${value}`,
                    comparison: comparison as UdbConditions,
                });
            }
            return {
                name,
                value,
                comparison,
            } as SearchFilter;
        });
        return [...preparedFields, ...preparedCondition] as SearchFilters[];
    }, []);

    return {
        operator: UdbOperators.AND,
        filters: type ? [{
            name: 'type',
            value: type,
            comparison: UdbConditions.EQUAL,
        },
        ...fields] : fields,
    };
};

export const getFiltersSsoApiParams = (filtersModel: ToolBarModelType) => {
    const fields = Object.entries(filtersModel)
        .reduce<Record<string, string | object>>((preparedFields, [key, value]) => {
        const [[condition, preparedValue]] = Object.entries(value);
        const isAllowedCondition = Object.values(SsoConditionValues).includes(condition as SsoConditionValues);

        if (value.from || value.to) {
            preparedFields[key] = value;
        } else if (isAllowedCondition) {
            preparedFields[key] = preparedValue;
        }

        return preparedFields;
    }, {});

    return Object.entries(fields).length ? { fields } : {};
};
