import React, { FC } from 'react';
import { Grid, GridProps } from '@mui/material';
import { StyledInfo } from './PaymentMethodSection.styles';
import { StyledHeaderName } from 'src/components/TableSection/HeaderSection/HeaderSection.styles';

type PaymentMethodItemProps = {
    title?: string;
    value: string | React.ReactNode;
    gridProps: GridProps;
};

const PaymentMethodItem: FC<PaymentMethodItemProps> = ({ title, value, gridProps }) => {
    return (
        <Grid item {...gridProps}>
            <StyledHeaderName>{title}</StyledHeaderName>
            <StyledInfo>{value}</StyledInfo>
        </Grid>
    );
};

export default PaymentMethodItem;
