import dayjs from 'dayjs';
import { MenuOption } from 'src/components/Menu/Menu';
import { BillingRecordModel, BillingRecordWithRenewal, SubscriptionModel } from 'src/@types/subscription-service-api';
import { RoundedBadgeProps } from 'src/components/Badge/RoundedBadge/RoundedBadge';
import { RoundedBadgeVariant } from 'src/components/Badge/RoundedBadge/RoundedBadge.styles';
import { BillingStatus, GeneralModalKey, SubscriptionStatus } from 'src/constants';

export const getPaymentStatusBadgeProps = (
    { isPaid, cycleStart }: BillingRecordModel,
    { status, billingType }: SubscriptionModel,
): RoundedBadgeProps => {
    if (isPaid) {
        return {
            label: BillingStatus.PAID,
            variant: RoundedBadgeVariant.GREEN,
        };
    }

    if (billingType === 'free') {
        return {
            label: BillingStatus.FREE,
        };
    }

    if ([SubscriptionStatus.SERVICE_ENDED, SubscriptionStatus.TERMINATED].includes(status)) {
        return {
            label: BillingStatus.VOID,
            variant: RoundedBadgeVariant.GREY,
        };
    }

    if (dayjs(cycleStart).isAfter(dayjs())) {
        return {
            label: BillingStatus.UPCOMING,
            variant: RoundedBadgeVariant.BLUE,
        };
    }

    return {
        label: BillingStatus.PAST_DUE,
        variant: RoundedBadgeVariant.RED,
    };
};

export const getUdbPaymentActions = (
    { renewal }: BillingRecordWithRenewal,
    paymentStatus: string,
    canManage: boolean,
): MenuOption[] => {
    const actions: MenuOption[] = [
        {
            label: 'View details',
            value: GeneralModalKey.billingDetailsModal,
        },
    ];

    if (canManage) {
        actions.push({
            label: 'Contract details',
            value: GeneralModalKey.contractDetailsModal,
        });
    }

    if (renewal && paymentStatus === BillingStatus.UPCOMING) {
        actions.push({
            label: `${renewal.disableRenewalNotifications ? 'Enable' : 'Disable'} renewal notifications`,
            value: GeneralModalKey.editBillingRenewalNotification,
        });
    }

    return actions;
};
