import React, { FC, useEffect, useState } from 'react';
import { BoxProps } from '@mui/material';
import { userRoleRenderMapping, UserRoles } from 'src/constants';
import { LabeledSelect } from 'src/components/Select/LabeledSelect';
import { OptionsType } from 'src/components/Select/Select';

type RoleSelectProps = {
    onChangeHandler: (event: React.ChangeEvent<HTMLFormElement | HTMLInputElement>) => void;
} & BoxProps;

const RoleSelect: FC<RoleSelectProps> = ({ onChangeHandler, ...boxProps }) => {
    const [rolesList, setRolesList] = useState<OptionsType[]>([]);

    useEffect(() => {
        const rolesOptions = Object.values(UserRoles).map((role) => ({
            value: role,
            label: userRoleRenderMapping[role],
        }));

        setRolesList([...rolesOptions]);
    }, []);

    return (
        <LabeledSelect
            testId="role"
            name="role"
            label="Select role"
            onFormChange={onChangeHandler}
            options={rolesList}
            {...boxProps}
        />
    );
};

export default RoleSelect;
