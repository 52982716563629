import React from 'react';

export const getSuccessMessage = (isActive: boolean) =>
    `Account has been successfully ${isActive ? 'disabled' : 'enabled'}`;

export const getErrorMessage = (isActive: boolean) =>
    `Failed to ${isActive ? 'disable' : 'enable'} account`;

export const getModalSubtitle = (isActive: boolean) =>
    isActive
        ? (
            <>
                Are you sure you want to disable this account?<br />
                This user will no longer be able to log in and access Endpoints News.
            </>
        )
        : (
            <>
               Are you sure you want to enable this account?<br />
               This user will now be able to log in and access Endpoints News.
            </>
        );

export const getModalTitle = (isActive: boolean) =>
    `${isActive ? 'Disable' : 'Enable'} account`;
