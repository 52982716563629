import React, { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import {
    ActiveSubscriptionHeaderWrapper,
    DropdownButton,
    DropdownContainer,
    DropdownContent,
    DropdownNavButton,
    DropdownWrapper,
} from './DesktopMenu.styles';
import { MenuItemDivider } from 'src/layouts/BasicLayout/components/Header/components/MenuItemDivider';
import { DesktopSubMenu } from 'src/layouts/BasicLayout/components/Header/components/DesktopSubMenu';
import { HeaderMenuItem } from 'src/layouts/BasicLayout/components/Header/components/HeaderMenuItem';
import { userSelector } from 'src/redux/slices';
import {
    getMenuLinks,
    isDivider,
    isDropdownItem,
    isNotDivider,
} from 'src/services/header-menu-helper';
import { CurrentUserState } from 'src/@types/redux';
import type { RootState } from 'src/redux/root-reducer';
import { ReactComponent as DropDownIcon } from 'src/assets/icons/expand-icon.svg';
import { ReactComponent as ProfileIcon } from 'src/assets/icons/profile-icon.svg';
import { ActiveSubscriptionHeader } from 'src/components/ActiveSubscriptionHeader';
import { makeSlug } from 'src/services/formatters';

const DesktopMenu: FC = () => {
    const currentUserState = useSelector<RootState, CurrentUserState>(userSelector);

    const allowedMenuLinks = getMenuLinks(currentUserState);

    const headerLinks: ReactElement[] = allowedMenuLinks
        .filter(isNotDivider)
        .map((menuItem) => {
            const itemTestId = `menu-item-${makeSlug(menuItem.label)}`;

            if (!isDropdownItem(menuItem)) {
                return (
                    <DropdownNavButton data-testid={itemTestId} key={itemTestId} to={menuItem.link}>
                        {menuItem.label}
                    </DropdownNavButton>
                );
            }

            const childrenItems = menuItem.children
                .map((subItem, subIndex) => {
                    if (isDivider(subItem)) {
                        return (
                            <MenuItemDivider
                                key={`${itemTestId}-d${subIndex}`}
                                testId={`${itemTestId}-d${subIndex}`}
                            />
                        );
                    }

                    const subItemTestId = `${itemTestId}--${makeSlug(subItem.label)}`;

                    if (isDropdownItem(subItem)) {
                        return (
                            <DesktopSubMenu
                                key={subItemTestId}
                                menuItem={subItem}
                                testId={subItemTestId}
                            />
                        );
                    }

                    return <HeaderMenuItem key={subItemTestId} item={subItem} testId={subItemTestId} />;
                });

            const isProfileItem = menuItem.icon === 'profile';

            return (
                <DropdownContainer key={`${itemTestId}-container`} data-testid={`${itemTestId}-container`}>
                    <Box display="flex" alignItems="center" height="100%" paddingX={2.5} data-testid={itemTestId}>
                        {isProfileItem ? (
                            <ProfileIcon tabIndex={0} width={40} height={40} />
                        ) : (
                            <DropdownButton tabIndex={0}>
                                {menuItem.label}
                                <DropDownIcon width={12} height={12} />
                            </DropdownButton>
                        )}
                    </Box>
                    <DropdownWrapper data-testid={`${itemTestId}-dropdown`} pb={menuItem.label ? 0 : 0.5}>
                        {isProfileItem && (
                            <ActiveSubscriptionHeaderWrapper>
                                <ActiveSubscriptionHeader />
                            </ActiveSubscriptionHeaderWrapper>
                        )}
                        <DropdownContent minWidth={menuItem.label ? 180 : 250} pb={0.5} pt={0.5}>
                            {childrenItems}
                        </DropdownContent>
                    </DropdownWrapper>
                </DropdownContainer>
            );
        });

    return (
        <>
            {headerLinks}
        </>
    );
};

export default DesktopMenu;
