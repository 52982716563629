import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import { StyledDescription } from './ChangeRoleModal.styles';
import { StyledModalMessage } from 'src/components/Modals/BaseModal/BaseModal.styles';
import { DEFAULT_MODAL_MAX_WIDTH } from 'src/components/Modals/BaseModal';
import { FormWrapper } from 'src/components/Forms/components';
import RoleSelect from 'src/components/Select/RoleSelect/RoleSelect';
import { updateCredentials } from 'src/services/sso-api';
import { useGeneralModal, useIsSubmitDisabled, useReloadPage, useSnackbarMessage } from 'src/hooks';
import { SnackbarMessageVariants } from 'src/constants';
import { Credentials } from 'src/@types/credentials';
import { Button } from 'src/components/Buttons';

export type ChangeRoleModalProps = {
    credentials: Credentials;
};

const ChangeRoleModal: FC<ChangeRoleModalProps> = ({ credentials }) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const { addMessage } = useSnackbarMessage();
    const { reloadPage } = useReloadPage();
    const { closeModal } = useGeneralModal();
    const { role } = credentials;

    const { onFormChangeHandler, isSubmitDisabled } = useIsSubmitDisabled<Partial<Credentials>>({ role: role });

    const handleSubmit = async (data: Partial<Credentials>) => {
        const selectedRole = data.role;

        setIsSubmitting(true);
        return updateCredentials(credentials.id, { role: selectedRole })
            .then(() => {
                addMessage(`The role ${role} has been successfully changed to ${selectedRole}`, SnackbarMessageVariants.SUCCESS);
                reloadPage();
                closeModal();
            })
            .catch(() => {
                addMessage(`Failed to change the role of ${role}`, SnackbarMessageVariants.ERROR);
            })
            .finally(() => setIsSubmitting(false));
    };

    return (
        <FormWrapper
            onFormChange={onFormChangeHandler}
            isSubmitting={isSubmitting}
            onSubmit={handleSubmit}
            initialValues={{ role: role }}
        >
            <Stack maxWidth={DEFAULT_MODAL_MAX_WIDTH}>
                <StyledModalMessage paddingBottom={{ xs: 2.5, md: 3 }}>
                    To change the current role please select a new role from the
                    drop-down list.
                </StyledModalMessage>
                <RoleSelect
                    onChangeHandler={onFormChangeHandler}
                />
                <StyledDescription marginTop={{ xs: 2, md: 3 }} marginBottom={3}>
                    Short breakdown of what this role can do will be shown here.
                </StyledDescription>
                <Button
                    fullWidth
                    data-testid="role-submit-button"
                    disabled={isSubmitDisabled || isSubmitting}
                    type="submit"
                >
                    Change role
                </Button>
            </Stack>
        </FormWrapper>
    );
};

export default ChangeRoleModal;
