import React, { FC } from 'react';
import { Stack, ListItemText, ListItem } from '@mui/material';
import {
    ManageSubscriptionNotificationWrapper,
    ManageSubscriptionNotificationList,
    ManageSubscriptionNotificationTitle,
} from './ManageSubscriptionNotification.styles';
import { ReactComponent as ListCircle } from 'src/assets/icons/list-circle.svg';
import { formatDate } from 'src/services/formatters';

type ManageSubscriptionNotificationProps = {
    activationDate: string;
    allowed: boolean;
    errors: string[] | null;
};

const ManageSubscriptionNotification: FC<ManageSubscriptionNotificationProps> = ({
    allowed,
    activationDate,
    errors,
}) => {
    const titleText = () => {
        if (!allowed) {
            return 'This subscription is not ready to be activated just yet';
        }

        if (activationDate) {
            return `This subscription is scheduled to be activated on ${formatDate(activationDate)}`;
        }

        return 'This subscription is ready to be activated';
    };

    return (
        <ManageSubscriptionNotificationWrapper
            paddingX={{ xs: 2, md: 4.5 }}
            paddingY={{ xs: 2.5, md: 2.5 }}
            data-testid="manage-subscription-notification"
            position="relative"
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            data-allowed={allowed}
            marginBottom={{ sm: 2.5, xs: 2 }}
        >
            <ManageSubscriptionNotificationTitle variant="h4" data-testid="manage-subscription-notification-title">
                {titleText()}
            </ManageSubscriptionNotificationTitle>
            {!allowed && errors && errors.length > 0 && (
                <ManageSubscriptionNotificationList data-testid="manage-subscription-notification-list">
                    {errors.map((error) => (
                        <ListItem key={error}>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <ListCircle height={5} width={5} />
                                <ListItemText primary={error} />
                            </Stack>
                        </ListItem>
                    ))}
                </ManageSubscriptionNotificationList>
            )}
        </ManageSubscriptionNotificationWrapper>
    );
};

export default ManageSubscriptionNotification;
