import styled from 'styled-components';

export const StyledHeaderMenuItem = styled.button`
    margin: 0;
    cursor: pointer;
    background: none;
    border: none;
    font-size: inherit;
    text-align: left;
    width: 100%;
    color: ${({ theme }) => theme.palette.black};
`;
