import React, { FC } from 'react';
import { gridColumnParams } from './schemas';
import { SubscriptionItem } from './components';
import { AccessibleSubscription } from 'src/@types/subscription-service-api';
import { TableSection } from 'src/components/TableSection';

type SubscriptionsSectionProps = {
    subscriptions: AccessibleSubscription[];
};

const SubscriptionsSection: FC<SubscriptionsSectionProps> = ({ subscriptions }) => {
    const sortedSubscriptions = [...subscriptions]
        .sort((a, b) => new Date(b.activationDate).getTime() - new Date(a.activationDate).getTime());

    return (
        <TableSection
            testId="subscriptions-section"
            isLoading={false}
            headerPaddingBottom={{ xs: 0, md: 1 }}
            items={sortedSubscriptions}
            gridColumnParams={gridColumnParams}
            sectionHeader="Paid subscriptions"
            noContentMessage="User is not attached to any paid subscription"
            renderItem={(subscription: AccessibleSubscription, extraProps) => (
                <SubscriptionItem
                    gridColumnParams={extraProps.gridColumnParams}
                    subscription={subscription}
                />
            )}
        />
    );
};

export default SubscriptionsSection;
