import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button } from 'src/components/Buttons';
import Line from 'src/components/Line';
import * as intercomService from 'src/services/intercom';
import { configSelector } from 'src/redux/slices';
import PageTitle from 'src/components/PageTitle';
import type { ConfigState } from 'src/@types/redux';
import type { RootState } from 'src/redux/root-reducer';

type LinkExpiredContentProps = {
    mainButtonLink: string;
    mainButtonText: string;
    testId?: string;
    titleText?: string;
    subTitleText?: string;
};

const LinkExpiredContent: FC<LinkExpiredContentProps> = ({
    mainButtonText,
    mainButtonLink,
    testId,
    titleText = 'This link has expired',
    subTitleText = 'To ensure the security of your user account, magic links may only be used once and are valid for 24 hours.',
}) => {
    const navigate = useNavigate();
    const { mainSiteUrl } = useSelector<RootState, ConfigState>(configSelector);

    const handleGetHelp = () => {
        intercomService.showIntercom();
    };

    return (
        <Stack spacing={3} data-testid={testId}>
            <PageTitle variant="form" title={titleText}>
                {subTitleText}
            </PageTitle>
            <Stack spacing={{ xs: 2, sm: 3 }}>
                <Button type="button" testId="main-link-button" onClick={() => navigate(mainButtonLink)}>
                    {mainButtonText}
                </Button>
                <Line>or</Line>
                <Stack spacing={{ sm: 2.5, xs: 2 }} direction="row">
                    <Button
                        isBold={false}
                        fullWidth
                        href={mainSiteUrl}
                        variant="secondary"
                        type="button"
                        testId="homepage-button"
                    >
                        View Homepage
                    </Button>
                    <Button
                        isBold={false}
                        fullWidth
                        variant="secondary"
                        type="button"
                        testId="help-button"
                        onClick={handleGetHelp}
                    >
                        Get Help
                    </Button>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default LinkExpiredContent;
