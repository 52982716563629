import { ManageActionType } from 'src/components/ManageActions/ManageActions';
import { ManageUserActions, Status } from 'src/constants';
import type { Credentials } from 'src/@types/credentials';

export const getManageCredentialsActionsList = (
    canEditUser: boolean,
    user: Credentials,
    isAdmin: boolean,
): Partial<Record<ManageUserActions, ManageActionType>> => {
    const is2TfaEnabled = user.tfaEnabled;
    const isActive = user.status === Status.ACTIVE;

    return {
        ...(canEditUser && {
            ...(isAdmin && { [ManageUserActions.changeRole]: { label: 'Change role' } }),
            [ManageUserActions.disable2FA]: { label: 'Disable 2FA', disabled: !is2TfaEnabled },
            [ManageUserActions.changeAccountStatus]: { label: `${isActive ? 'Disable' : 'Enable'} account` },
            [ManageUserActions.logInLink]: { label: 'Generate login link' },
            [ManageUserActions.passwordLink]: { label: 'Generate password change link' },
        }),
    };
};
