import { createTheme } from '@mui/material';
import { createGlobalStyle } from 'styled-components';

const headerHeight = { xs: '72px', md: '100px' };
const footerHeight = { xs: '72px', md: '58px' };
const contentHeight = {
    xs: `calc(100vh - ${headerHeight.xs} - ${footerHeight.xs})`,
    md: `calc(100vh - ${headerHeight.md} - ${footerHeight.md})`,
};

export const theme = {
    palette: {
        shadow: 'rgba(136, 149, 172, 0.15)',
        white: '#ffffff',
        hoveredInputBackground: '#FCFCFC',
        secondaryButton: '#E8EFF8',
        transparentButtonBorder: '#DEDEE3',
        transparentButtonActiveBorder: '#BBBBBB',
        transparentButtonClickBackground: '#F0F1F3',
        secondaryHoverButton: '#D6E4F5',
        buttonGradient: 'linear-gradient(90deg, #4c7dcc 0%, #0f4998 50%);',
        dataGridHoverBackground: '#FBFCFF',
        dataGridSelectedBackground: '#F4F6FD',
        manageSubscriptionNotificationLightBlue: '#F0F2F7',
        manageSubscriptionNotificationBlue: '#E8EFF8',
        lightBlue: '#4c7dcc',
        blue: '#2C6ABF',
        darkBlue: '#0f4998',
        dirtyWhite: '#E6E6E6',
        disabledInput: '#F4F4F4',
        grey: '#7D808D',
        lightGrey: '#A8A9AC',
        lightBlack: '#555865',
        black: '#262831',
        red: '#DE5247',
        darkRed: '#BE3126',
        orange: '#DB7309',
        green: '#11883E',
        lightRed: '#FAEDEC',
        mainBackgroundColor: '#F7F8FA',
        mainBorderColor: '#EBEBEB',
        borderGrey: '#F0F0F0',
        gold: '#BB9451',
        roundedBadgeBackground: {
            green: 'rgba(17, 136, 62, 0.10)',
            greenHover: 'rgba(17, 136, 62, 0.2)',
            blue: '#F3F8FF',
            blueHover: '#DDEAFB',
            red: 'rgba(222, 82, 71, 0.10)',
            redHover: 'rgba(222, 82, 71, 0.2)',
            grey: '#EBEEF3',
            greyHover: '#DBDEE3',
            orange: 'rgba(219, 115, 9, 0.10)',
            orangeHover: 'rgba(219, 115, 9, 0.2)',
            defaultHover: '#E7EAEF',
            outlined: '#FFFFFF',
            outlinedHover: '#F9F9F9',
        },
        snackbar: {
            infoBackground: '#E8EFF8',
            infoFont: '#2C6ABF',
            errorBackground: '#F8E1E1',
            errorFont: '#BE3126',
            warningBackground: '#F8EEE1',
            warningFont: '#B26313',
            successBackground: '#E2F8E1',
            successFont: '#11883E',
        },
    },
    sizes: {
        headerDefaultMaxWidth: '1232px',
        headerHeight,
        footerHeight,
        contentHeight,
        tooltipArrowSize: '6px',
        inputHeight: {
            xs: '44px',
            default: '50px',
        },
    },
    brakePoints: {
        xs: '0px',
        sm: '600px',
        md: '900px',
        lg: '1200px',
        xl: '1536px',
    },
    fonts: {
        bold: 'TTNormsPro-Bold',
        demiBold: 'TTNormsPro-DemiBold',
        medium: 'TTNormsPro-Medium',
        regular: 'TTNormsPro-Regular',
        normal: 'TTNormsPro-Normal',
    },
};

export const muiTheme = createTheme({
    typography: {
        fontFamily: theme.fonts.normal,
    },
    palette: {
        text: {
            primary: theme.palette.black,
        },
        secondary: {
            main: theme.palette.lightBlack,
        },
    },
});

export const GlobalStyles = createGlobalStyle`
    body {
        margin: 0;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-family: ${theme.fonts.normal};
        background-color: ${theme.palette.mainBackgroundColor};
    }

    *:focus {
        outline-color: ${theme.palette.blue};
    }
`;
