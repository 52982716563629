import React, { FC, useState } from 'react';
import { Grid } from '@mui/material';
import { RenewalDataRow } from './';
import type { BillingRecordModel } from 'src/@types/subscription-service-api';
import { PriceInput } from 'src/components/Inputs';
import { StyledTextButton } from 'src/components/Modals/UpcomingRenewalModal/UpcomingRenewalModal.styles';
import { formatPrice } from 'src/services/formatters';
import { Button } from 'src/components/Buttons';

type FinalPriceRowProps = {
    handleSave: (renewal: Partial<BillingRecordModel>) => Promise<void>;
    finalPrice: number | null;
};

const FinalPriceRow: FC<FinalPriceRowProps> = ({ finalPrice, handleSave }) => {
    const [showOverridePriceInput, setShowOverridePriceInput] = useState<boolean>(false);
    const [price, setPrice] = useState<number | null>(finalPrice);

    const isSaveOverridePriceDisabled = () => {
        const preparedPrice = price;
        return preparedPrice === finalPrice || Number.isNaN(preparedPrice);
    };

    return (
        <RenewalDataRow
            title="Final price:"
            formatter={formatPrice}
            paddingTop={{ xs: showOverridePriceInput ? 0 : 0.5, sm: 0 }}
            paddingBottom={{ xs: 0.5, sm: 0 }}
            currentRenewalData={finalPrice}
            overrideButton={(
                <>
                    {!showOverridePriceInput && (
                        <StyledTextButton onClick={() => setShowOverridePriceInput(true)}>
                                Override price
                        </StyledTextButton>
                    )}
                </>
            )}
        >
            {showOverridePriceInput && (
                <Grid container spacing={1} marginTop={0.5} marginBottom={{ xs: 0.5, sm: 2 }}>
                    <Grid item xs={9} sm={8}>
                        <PriceInput
                            initialPrice={price}
                            placeholder="Override price"
                            handleChange={setPrice}
                        />
                    </Grid>
                    <Grid item xs={3} sm={4}>
                        <Button
                            fullWidth
                            disabled={isSaveOverridePriceDisabled()}
                            minSize="unset"
                            onClick={() => {
                                handleSave({ priceInCents: price })
                                    .then(() => setShowOverridePriceInput(false));
                            }}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            )}
        </RenewalDataRow>
    );
};

export default FinalPriceRow;
