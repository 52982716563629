import React from 'react';
import { Stack } from '@mui/material';
import { UsageDataTableTitle } from './UsageDataTable.styles';
import Table from 'src/components/Table';
import { PaidSubscriptionUsageData } from 'src/@types/sso-api';
import { DATA_GRID_NO_ROWS_MESSAGE } from 'src/constants';
import { Button } from 'src/components/Buttons';
import { DEFAULT_MODAL_PADDING_X } from 'src/components/Modals/BaseModal';

type UsageDataTableProps = {
    paidSubscriptionUsage: PaidSubscriptionUsageData;
};

export const UsageDataTable = ({ paidSubscriptionUsage }: UsageDataTableProps) => {
    const columns = [
        { field: 'users', headerName: 'Users', flex: 1 },
        { field: 'articles', headerName: 'Articles', flex: 1 },
        { field: 'views', headerName: 'Views', flex: 1 },
    ];

    const rows = [
        { id: 1, ...paidSubscriptionUsage },
    ];

    return (
        <Stack
            data-testid="usage-data-table"
            spacing={3}
            direction="column"
            marginTop={3}
            paddingX={DEFAULT_MODAL_PADDING_X}
        >
            <UsageDataTableTitle paddingBottom={{ xs: 1, sm: 2 }}>Usage data</UsageDataTableTitle>
            <Table
                margin="0"
                minDataRows={1}
                rows={rows}
                rowCount={1}
                columns={columns}
                hideFooter
                disableColumnMenu
                noRowsOverlayMessage={DATA_GRID_NO_ROWS_MESSAGE}
            />
            <Button fullWidth disabled>Generate usage report (HTML)</Button>
        </Stack>
    );
};
