import { canUserAccessPage } from './access-handler';
import { EnterpriseCalculatorPage } from 'src/pages/udb/EnterpriseCalculator';
import { useGeneralModal } from 'src/hooks';
import { CredentialsPage } from 'src/pages/Credentials';
import type { CurrentUserState } from 'src/@types/redux';
import { SubscriptionsPage } from 'src/pages/udb/Subscriptions';
import { ApplicationsPage } from 'src/pages/Applications';
import { UsersPage } from 'src/pages/udb/Users';
import { GeneralModalKey, PAGE_TITLES } from 'src/constants';
import { CreateSubscriptionPage } from 'src/pages/udb/CreateSubscription';

export type DividerType = {
    divider: boolean;
    allowed: boolean;
};

export type LinkItemType = {
    label: string;
    link: string;
    onClick?: () => void;
    allowed: boolean;
};

export type DropdownItemType = {
    label: string;
    icon?: 'profile';
    children: HeaderMenuItemType[];
};

export type HeaderMenuItemType = LinkItemType | DropdownItemType | DividerType;

export const isDropdownItem = (item: HeaderMenuItemType): item is DropdownItemType => {
    return 'children' in item;
};

export const isDivider = (item: HeaderMenuItemType): item is DividerType => {
    return 'divider' in item;
};

export const isLinkItemType = (item: HeaderMenuItemType): item is LinkItemType => (
    !isDivider(item) && !isDropdownItem(item)
);

export const isNotDivider = (item: HeaderMenuItemType): item is DropdownItemType | LinkItemType => !isDivider(item);

const allowedMenuLinks = (links: HeaderMenuItemType[]): HeaderMenuItemType[] => {
    return links.map((link) => {
        return isDropdownItem(link) ? {
            ...link,
            children: allowedMenuLinks(link.children),
        } : link;
    }).filter((item) => isDropdownItem(item) ? item.children.length : item.allowed);
};

export const getMenuLinks = (userState: CurrentUserState, isMobile = false): HeaderMenuItemType[] => {
    const { currentUser } = userState;
    const { openModal } = useGeneralModal();

    const menuLinks: HeaderMenuItemType[] = [
        {
            label: 'Profile',
            link: '/',
            allowed: isMobile && !!currentUser,
        }, {
            label: 'Settings',
            link: '',
            onClick: () => {
                openModal({ key: GeneralModalKey.settingsModal });
            },
            allowed: isMobile && !!currentUser,
        }, {
            label: PAGE_TITLES.APPLICATIONS,
            link: '/applications',
            allowed: canUserAccessPage(userState, ApplicationsPage.name),
        }, {
            label: PAGE_TITLES.CREDENTIALS,
            link: '/credentials',
            allowed: canUserAccessPage(userState, CredentialsPage.name),
        }, {
            label: 'Unified DB',
            children: [
                {
                    label: PAGE_TITLES.SUBSCRIPTIONS,
                    children: [
                        {
                            label: 'List',
                            link: '/udb/subscriptions',
                            allowed: canUserAccessPage(userState, SubscriptionsPage.name),
                        },
                        {
                            label: 'Create subscription',
                            link: '/udb/subscriptions/create',
                            allowed: canUserAccessPage(userState, CreateSubscriptionPage.name),
                        },
                        {
                            label: 'Enterprise calculator',
                            link: '/udb/subscriptions/calculator/enterprise',
                            allowed: canUserAccessPage(userState, EnterpriseCalculatorPage.name),
                        },
                    ],
                },
                {
                    label: PAGE_TITLES.USERS,
                    children: [
                        {
                            label: 'List',
                            link: '/udb/users',
                            allowed: canUserAccessPage(userState, UsersPage.name),
                        },
                    ],
                },
            ],
        },
        {
            label: 'User',
            icon: 'profile',
            children: [
                {
                    allowed: !isMobile && !!currentUser,
                    divider: true,
                },
                {
                    label: 'Profile',
                    link: '/',
                    allowed: !isMobile && !!currentUser,
                },
                {
                    label: 'Settings',
                    link: '',
                    onClick: () => {
                        openModal({ key: GeneralModalKey.settingsModal });
                    },
                    allowed: !isMobile && !!currentUser,
                },
                {
                    allowed: !isMobile && !!currentUser,
                    divider: true,
                },
                {
                    label: PAGE_TITLES.LOG_OUT,
                    link: '/log-out',
                    allowed: !isMobile && !!currentUser,
                },
            ],
        },
    ];

    return allowedMenuLinks(menuLinks);
};
