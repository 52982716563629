import React from 'react';
import { ActivationDate } from 'src/constants';
import { formatDate } from 'src/services/formatters';

export const getActivationConfirmationMessage = (
    selectedActivationDateType: ActivationDate,
    activationDate: string,
    expirationDate?: string,
) => {
    switch (selectedActivationDateType) {
        case ActivationDate.NOW:
            return expirationDate && (
                <>
                    You are about to activate this subscription. The expiration date will be&nbsp;
                    <span>{formatDate(expirationDate)}.</span>
                </>
            );
        case ActivationDate.SPECIFIED:
            return expirationDate && (
                <>
                    You have chosen to activate this subscription on&nbsp;
                    <span>{formatDate(activationDate)}. </span>
                    Once activated, it will expire on&nbsp;
                    <span>{formatDate(expirationDate)}.</span>
                </>
            );
        case ActivationDate.MANUALLY:
            return (
                <>
                    This subscription will remain inactive until you activate it manually.
                </>
            );
        default:
            return null;
    }
};

export const getActivationButtonLabel = (
    selectedActivationDateType: ActivationDate,
): string => {
    switch (selectedActivationDateType) {
        case ActivationDate.NOW:
            return 'Activate now';
        case ActivationDate.SPECIFIED:
            return 'Schedule activation';
        default:
            return 'Confirm';
    }
};
