import dayjs, { Dayjs } from 'dayjs';
import { BillingCycleLengthInMonth } from 'src/constants';
import { fetchMinActivationDate } from 'src/services/subscription-service-api';

const minDayCache: Record<BillingCycleLengthInMonth, { timestamp: Dayjs, value: Dayjs } | undefined> = {
    [BillingCycleLengthInMonth.MONTH]: undefined,
    [BillingCycleLengthInMonth.YEAR]: undefined,
};

export const getMinActivationDate = async (
    billCycleLength: BillingCycleLengthInMonth,
    timestamp: Dayjs,
): Promise<Dayjs> => {
    if (minDayCache[billCycleLength] && !minDayCache[billCycleLength]!.timestamp.isBefore(timestamp.subtract(30, 'minutes'))) {
        return minDayCache[billCycleLength]!.value;
    }

    const { activationDate } = await fetchMinActivationDate(billCycleLength);
    const fetchedMinDay = dayjs(activationDate);
    minDayCache[billCycleLength] = {
        timestamp,
        value: fetchedMinDay.add(1, 'hour'),
    };

    return minDayCache[billCycleLength]!.value;
};
