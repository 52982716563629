import { Box } from '@mui/material';
import React from 'react';
import { PricingModel, pricingModelRenderLabels } from './constants';
import { areAllObjectFieldsDefined } from 'src/services/checks';
import { PricingTermsData } from 'src/pages/udb/CreateSubscription/components/PricingTermsStep/PricingTermsStep';
import { additionalWideInputWidth, DiscountType } from 'src/constants';
import { formatPrice } from 'src/services/formatters';
import {
    RenewalPriceAdditionalInfoProps,
} from 'src/pages/udb/CreateSubscription/components/RenewablePriceAdditionalInfo/RenewablePriceAdditionalInfo';
import {
    calculatePriceWithAmountDiscount,
    calculatePriceWithPercentageDiscount,
} from 'src/services/discount-calculator';
import { RenewablePriceAdditionalInfo } from 'src/pages/udb/CreateSubscription/components/RenewablePriceAdditionalInfo';
import { RadioGroupItem } from 'src/components/Radio/RadioGroup';
import PriceInput from 'src/components/Inputs/PriceInput';

export type PricingModelRadioDependencies = {
    setPrice: (price: number) => void;
    selectedPricingModel?: PricingModel;
} & RenewalPriceAdditionalInfoProps;

export const discountTypeRadioButtons = [
    {
        value: DiscountType.PERCENTAGE,
        label: 'Percentage off',
    },
    {
        value: DiscountType.FIXED,
        label: 'Amount off',
    },
];

export const shouldShowNextButton = ({ insider, enterprise }: PricingTermsData) => {
    return !!enterprise?.domains.length
        || insider?.selectedPricingModel === PricingModel.FIXED_PRICE
        || (insider?.selectedPricingModel === PricingModel.RENEWABLE_PRICE && (
            !insider?.hasDiscount || areAllObjectFieldsDefined(insider)
        ));
};

export const getPricingModelRadioButtons = ({
    selectedPricingModel,
    setPrice,
    ...props
}: PricingModelRadioDependencies): Array<RadioGroupItem<PricingModel>> => [
    {
        value: PricingModel.FIXED_PRICE,
        label: pricingModelRenderLabels[PricingModel.FIXED_PRICE],
        ...(selectedPricingModel === PricingModel.FIXED_PRICE && {
            additionalNode: (
                <Box maxWidth={{ xs: '150px', sm: additionalWideInputWidth }}>
                    <PriceInput
                        initialPrice={props.finalPriceInCents}
                        handleChange={setPrice}
                        placeholder="Price"
                        currency="$"
                    />
                </Box>
            ),
            additionalNodeProps: {
                direction: 'column',
                spacing: 2,
            },
        }),
    },
    {
        value: PricingModel.RENEWABLE_PRICE,
        label: pricingModelRenderLabels[PricingModel.RENEWABLE_PRICE],
        ...(selectedPricingModel === PricingModel.RENEWABLE_PRICE && {
            additionalNode: (
                <RenewablePriceAdditionalInfo {...props} />
            ),
            additionalNodeProps: {
                direction: 'column',
                spacing: 2,
            },
        }),
    },
];

export const calculateFinalPrice = ({
    priceInCents,
    hasDiscount,
    discountAmount,
    selectedDiscountType,
}: {
    priceInCents: number;
    hasDiscount: boolean;
    discountAmount: number;
    selectedDiscountType?: DiscountType;
}): number => {
    if (!hasDiscount || Number.isNaN(discountAmount) || Number.isNaN(priceInCents) || discountAmount === 0) {
        return priceInCents;
    }

    if (selectedDiscountType === DiscountType.PERCENTAGE) {
        return calculatePriceWithPercentageDiscount(priceInCents, discountAmount);
    }

    return calculatePriceWithAmountDiscount(priceInCents, discountAmount);
};

export const getDiscountContentToRender = (discountAmount: number, discountType: DiscountType) => {
    let value: string = `${discountAmount}%`;

    if (discountType === DiscountType.FIXED) {
        value = `${formatPrice(discountAmount, false)}$`;
    }

    return `${value} discount applied`;
};

export const getDiscountAmount = (amount: number, newType: DiscountType, previousType?: DiscountType) => (
    newType === DiscountType.FIXED && previousType === DiscountType.PERCENTAGE ? amount * 100 : amount / 100
);
