import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { DiscountType } from 'src/constants';
import {
    StyledFinalPrice,
    StyledPrice,
} from 'src/pages/udb/CreateSubscription/components/PricingTermsStep/PricingTermsStep.styles';
import { formatPrice } from 'src/services/formatters';
import { calculateFinalPrice } from 'src/pages/udb/CreateSubscription/services/pricing-terms-step-getters';

type FinalPriceRowValueProps = {
    priceInCents: number;
    hasDiscount: boolean;
    discountAmount: number;
    selectedDiscountType?: DiscountType;
};

const FinalPriceRowValue: FC<FinalPriceRowValueProps> = ({
    priceInCents,
    hasDiscount,
    discountAmount,
    selectedDiscountType,
}) => {
    const shouldRenderDiscount = hasDiscount
        && selectedDiscountType
        && discountAmount !== 0
        && !Number.isNaN(discountAmount);

    return (
        <Stack
            direction={{ xs: 'column-reverse', sm: 'row' }}
            spacing={{ xs: 0, sm: 2 }}
            justifyContent="flex-end"
        >
            {shouldRenderDiscount && (
                <StyledPrice>
                    {formatPrice(priceInCents)}
                </StyledPrice>
            )}
            <StyledFinalPrice>
                {formatPrice(calculateFinalPrice(
                    { priceInCents, hasDiscount, discountAmount, selectedDiscountType },
                ))}
            </StyledFinalPrice>
        </Stack>
    );
};

export default FinalPriceRowValue;
