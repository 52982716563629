import React, { FC } from 'react';
import { Box, Grid, GridProps, Stack } from '@mui/material';
import { TextButton } from 'src/components/Buttons';
import { RoundedBadgeVariant } from 'src/components/Badge/RoundedBadge/RoundedBadge.styles';
import { EmailStatus, GeneralModalKey, TABLE_SECTION_DEFAULT_COLUMNS } from 'src/constants';
import { useGeneralModal, useLookupNamesHook } from 'src/hooks';
import { getCountriesList } from 'src/services/user-profile-api';
import { Email, PhoneNumber } from 'src/@types/unified-db-api';
import StyledTooltip from 'src/components/Tooltip';
import { RoundedBadge } from 'src/components/Badge';
import { StyledInfo } from 'src/pages/udb/ManageUser/ManageUser.styles';
import { PageSection } from 'src/components/PageSection';
import { StyledHeaderName } from 'src/components/TableSection/HeaderSection/HeaderSection.styles';
import Line from 'src/components/Line';
import { ReactComponent as EditIcon } from 'src/assets/icons/edit-icon.svg';
import { ChangeEmailCheckData } from 'src/@types/sso-api';

export type FormattedContactInfoType = {
    email: string;
    status: EmailStatus;
    phoneNumber?: PhoneNumber | null;
    country?: string;
    secondaryEmails?: Email[];
};

type ContactInformationProps = {
    contactsInformation?: FormattedContactInfoType;
    changeEmailStatus?: ChangeEmailCheckData;
    isEditable: boolean;
    uuid: string;
};

export type GridColumnProps = Record<'email' | 'phoneNumber' | 'country', GridProps>;

const gridColumnParams: GridColumnProps = {
    email: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: 0, md: 1 },
    },
    phoneNumber: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: 0, md: 1 },
    },
    country: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: 0, md: 1 },
    },
};

const gridColumnTitles = {
    email: 'Email address',
    phoneNumber: 'Phone number',
    country: 'Country',
};

const ContactInformationSection: FC<ContactInformationProps> = ({ contactsInformation,
    isEditable,
    uuid,
    changeEmailStatus = {
        newEmail: null,
        expiration: null,
    } }) => {
    if (!contactsInformation) {
        return null;
    }

    const hasSecondaryEmails = contactsInformation.secondaryEmails && contactsInformation.secondaryEmails.length > 0;
    const otherEmails = contactsInformation.secondaryEmails?.length;

    const countryName = useLookupNamesHook(getCountriesList, contactsInformation.country);
    const { openModal } = useGeneralModal();

    const handleAdditionalEmailModal = () => {
        openModal({
            key: GeneralModalKey.additionalEmailModal,
            extraProps: {
                secondaryEmails: contactsInformation.secondaryEmails?.map((e) => e.email),
            },
        });
    };

    const handleEditContactInformationModal = () => {
        openModal({
            key: GeneralModalKey.editContactInformation,
            extraProps: {
                contactsInformation,
                changeEmailStatus,
                uuid,
            },
        });
    };

    const badgeVariantMapping = {
        [EmailStatus.BOUNCED]: RoundedBadgeVariant.RED,
        [EmailStatus.UNSUBSCRIBED]: RoundedBadgeVariant.RED,
        [EmailStatus.SUBSCRIBED]: RoundedBadgeVariant.BLUE,
        [EmailStatus.DELETED]: RoundedBadgeVariant.GREY,
        [EmailStatus.UNDEFINED]: RoundedBadgeVariant.DEFAULT,
    };

    return (
        <PageSection
            data-testid="contact-information-section"
            sectionPaddingBottom={3}
            header="Contact information"
            headerChildren={
                isEditable ? (
                    <TextButton data-testid="edit-btn" onClick={handleEditContactInformationModal}>
                        <EditIcon />
                    </TextButton>
                ) : null
            }
        >
            <Grid container columns={TABLE_SECTION_DEFAULT_COLUMNS}>
                <Grid item {...gridColumnParams.email}>
                    <StyledHeaderName>{gridColumnTitles.email}</StyledHeaderName>
                    <Stack flexDirection="row" alignItems="center" gap={1}>
                        <StyledInfo>{contactsInformation.email}</StyledInfo>
                        <StyledTooltip title="Email status in Campaign Monitor" arrow>
                            <Box>
                                <RoundedBadge
                                    label={contactsInformation.status}
                                    variant={badgeVariantMapping[contactsInformation.status]}
                                />
                            </Box>
                        </StyledTooltip>
                        {hasSecondaryEmails && (
                            <StyledTooltip
                                title={`Click to see ${otherEmails} additional ${otherEmails === 1 ? 'email' : 'emails'}`}
                                arrow
                            >
                                <TextButton data-testid="email-chip" onClick={handleAdditionalEmailModal}>
                                    <RoundedBadge label={`+${otherEmails}`} variant={RoundedBadgeVariant.DEFAULT} />
                                </TextButton>
                            </StyledTooltip>
                        )}
                    </Stack>
                </Grid>
                <Grid item display={{ xs: 'block', md: 'none' }} paddingY={2} xs={12}>
                    <Line />
                </Grid>
                <Grid item {...gridColumnParams.phoneNumber}>
                    <StyledHeaderName>{gridColumnTitles.phoneNumber}</StyledHeaderName>
                    <StyledInfo>{contactsInformation.phoneNumber?.phoneNumber}</StyledInfo>
                </Grid>
                <Grid item display={{ xs: 'block', md: 'none' }} paddingY={2} xs={12}>
                    <Line />
                </Grid>
                <Grid item {...gridColumnParams.country}>
                    <StyledHeaderName>{gridColumnTitles.country}</StyledHeaderName>
                    <StyledInfo>{countryName}</StyledInfo>
                </Grid>
            </Grid>
        </PageSection>
    );
};

export default ContactInformationSection;
