import dayjs from 'dayjs';
import { ProfileAccessibleSubscription } from 'src/@types/sso-api';
import { SubscriptionStatus, UDBNewsletterChannel } from 'src/constants';

export const byNumericId = <T extends { id: number }>({ id: id1 }: T, { id: id2 }: T) => (
    id1 < id2 ? -1 : (id1 > id2 ? 1 : 0)
);

export const sortByNumericIdWithPriorityChanel = <T extends { id: number, chanel: UDBNewsletterChannel }>
(priorityChanel: UDBNewsletterChannel) =>
    ({ id: id1, chanel: chanel1 }: T, { id: id2 }: T) => {
        if (id1 === id2) {
            return chanel1 === priorityChanel ? -1 : 1;
        }

        return id1 - id2;
    };

export const sortSubscriptions = (subscriptions: ProfileAccessibleSubscription[]) => {
    return subscriptions.sort((a, b) => {
        if (a.status === SubscriptionStatus.ACTIVE && b.status !== SubscriptionStatus.ACTIVE) {
            return -1;
        }
        if (a.status !== SubscriptionStatus.ACTIVE && b.status === SubscriptionStatus.ACTIVE) {
            return 1;
        }
        return dayjs(b.activationDate).diff(dayjs(a.activationDate));
    });
};
