import { Stack } from '@mui/material';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PageTitle from 'src/components/PageTitle';
import { Button, TextButton } from 'src/components/Buttons';
import type { RootState } from 'src/redux/root-reducer';
import { ConfigState } from 'src/@types/redux';
import { configSelector } from 'src/redux/slices';

const ThanksForSubscribingInsiderFlowContent: FC = () => {
    const navigate = useNavigate();
    const { mainSiteUrl } = useSelector<RootState, ConfigState>(configSelector);

    return (
        <Stack spacing={3} data-testid="thanks-for-subscribing">
            <PageTitle
                variant="form"
                testId="thank-for-subscribe-title"
                title="We’ve saved your newsletter choices"
            >
                <>
                    You can update your newsletter preferences anytime in your&nbsp;
                    <TextButton onClick={() => navigate('/')}>reader profile</TextButton>.
                </>
            </PageTitle>
            <Button href={mainSiteUrl} fullWidth>
                Read the latest news
            </Button>
        </Stack>
    );
};

export default ThanksForSubscribingInsiderFlowContent;
