import styled, { css } from 'styled-components';
import { Typography } from '@mui/material';

const removedStyles = css`
  text-decoration: line-through;
  color: ${({ theme }) => theme.palette.lightGrey};
`;

const fontVariant = (props: unknown & { 'data-font'?: string, 'data-is-removed'?: boolean }) => {
    switch (props['data-font']) {
        case 'medium-modal':
            return css`
              line-height: 140%;
              font-family: ${({ theme }) => theme.fonts.medium};
            `;
        case 'medium':
            return css`
              font-family: ${({ theme }) => theme.fonts.medium};
            `;
        case 'normal':
            return css`
              font-family: ${({ theme }) => theme.fonts.normal};
              ${props['data-is-removed'] && removedStyles}
            `;
        default:
            return css`
              font-family: ${({ theme }) => theme.fonts.bold};
            `;
    }
};

const StyledTitle = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.black};
    font-size: 16px;
    font-style: normal;
    line-height: normal;
      
    ${fontVariant};
    
    @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
      font-size: 14px;
    }
  }
`;

export const StyledTitleWithIcon = styled(StyledTitle)`
  && {
    display: flex;
    align-items: center;

    & svg {
      margin-left: 4px;
    }
  }
`;

export default StyledTitle;
