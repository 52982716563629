import styled from 'styled-components';
import { PageVariant } from './BasicLayout';
import { theme as mainTheme } from 'src/theme';

export const maxWidthByPageVariant = (hasHeaderAlignment: boolean) => (props: {
    'data-page-variant'?: PageVariant;
    theme: typeof mainTheme;
}) => {
    const propValue = props['data-page-variant'];
    switch (true) {
        case propValue === PageVariant.PAGE:
        case propValue === PageVariant.FORM && hasHeaderAlignment:
            return `max-width: ${props.theme.sizes.headerDefaultMaxWidth}; width: 100%;`;
        case propValue === PageVariant.FORM && !hasHeaderAlignment:
            return 'max-width: 920px; width: 100%;';
        case propValue === PageVariant.DATA_GRID:
        default:
            return 'width: 100%;';
    }
};

export const StyledBasicLayout = styled.div `
  background-color: ${({ theme }) => theme.palette.mainBackgroundColor};
`;
