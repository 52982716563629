import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Box, Stack } from '@mui/material';
import { InfoBox, StyledGracePeriod } from './UpdateGracePeriodModal.styles';
import { StyledModalMessage } from 'src/components/Modals/BaseModal/BaseModal.styles';
import { ReactComponent as WarningIcon } from 'src/assets/icons/blue-warn-icon.svg';
import { SubscriptionModel } from 'src/@types/subscription-service-api';
import { formatDate } from 'src/services/formatters';
import { useReloadPage } from 'src/hooks/useReloadPage';
import { BaseModal, DEFAULT_MODAL_MAX_WIDTH, DEFAULT_MODAL_PADDING_X } from 'src/components/Modals/BaseModal';
import { FormWrapper } from 'src/components/Forms/components';
import { updateSubscription } from 'src/services/subscription-service-api';
import { Label } from 'src/components/Label';
import { Button } from 'src/components/Buttons';
import { useGeneralModal, useIsSubmitDisabled, useSnackbarMessage } from 'src/hooks';
import { LabeledDateInput } from 'src/components/Inputs/LabeledDateInput/LabeledDateInput';
import { DATE_ISO_8601, DATE_TIME_ISO_8601, DateFormats, SnackbarMessageVariants } from 'src/constants';

export type UpgradeGracePeriodModalProps = {
    subscriptionUUID: string;
    gracePeriodEndDate: string;
    expirationDate: string;
};

const UpdateGracePeriodModal = ({
    subscriptionUUID,
    gracePeriodEndDate,
    expirationDate,
}: UpgradeGracePeriodModalProps) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [endDate, setEndDate] = useState<string>('');
    const [isConfirming, setIsConfirming] = useState<boolean>(false);

    const { addMessage } = useSnackbarMessage();
    const { reloadPage } = useReloadPage();
    const { closeModal } = useGeneralModal();

    const today = dayjs().startOf('day');
    const expirationDateTime = dayjs(expirationDate);
    let minEndDate = expirationDateTime.isAfter(today) ? expirationDateTime : today;
    if ((today.isSame(expirationDateTime, 'day') || today.isAfter(expirationDateTime, 'day')) && today.hour() >= expirationDateTime.hour()) {
        minEndDate = today.add(1, 'day');
    }

    const showInfoBox = dayjs(endDate).isAfter(dayjs(expirationDate).add(90, 'day'));
    const showTodayInfoBox = dayjs(endDate).isSame(today, 'day');
    const isExtending = dayjs(endDate).isAfter(dayjs(gracePeriodEndDate));
    const confirmationMessage = isExtending
        ? `Are you sure you want to extend the grace period until ${formatDate(endDate)}?`
        : `Are you sure you want to reduce the grace period to until ${formatDate(endDate)}?`;

    const initialValues = {
        gracePeriodEndDate: '',
    };

    const handleEndDateChange = (newDate?: string) => {
        const currentEndDate = dayjs(gracePeriodEndDate);
        const newEndDate = dayjs(newDate);

        const updatedEndDate = newEndDate
            .set('hour', currentEndDate.hour())
            .set('minute', currentEndDate.minute())
            .set('second', currentEndDate.second());

        const formattedEndDate = updatedEndDate.format(DATE_TIME_ISO_8601);

        setEndDate(formattedEndDate);
    };

    const shouldDisableDate = (date: dayjs.Dayjs) => {
        return date.isSame(dayjs(gracePeriodEndDate), 'day');
    };

    const handleSubmit = () => {
        setIsConfirming(false);
        setIsSubmitting(true);
        updateSubscription(subscriptionUUID, { gracePeriodEndDate: dayjs(endDate).format(DATE_ISO_8601) })
            .then(() => {
                addMessage('Grace period successfully updated', SnackbarMessageVariants.SUCCESS);
                closeModal();
                reloadPage();
            })
            .catch(() => {
                addMessage('Failed to update grace period', SnackbarMessageVariants.ERROR);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const { onFormChangeHandler } = useIsSubmitDisabled<Partial<SubscriptionModel>>(initialValues);

    if (isConfirming) {
        return (
            <BaseModal
                open={true}
                handleClose={() => setIsConfirming(false)}
                title="Confirm grace period"
            >
                <StyledModalMessage>
                    {confirmationMessage}
                </StyledModalMessage>
                {showTodayInfoBox && (
                    <InfoBox gap={1} marginY={3}>
                        <Stack><WarningIcon /></Stack>
                        Please note, this subscription will expire later today.
                    </InfoBox>
                )}
                <Stack direction="row" spacing={{ xs: 2, sm: 2.5 }} marginTop={showTodayInfoBox ? 0 : 3}>
                    <Button
                        minSize="small"
                        data-testid="grace-period-modal-cancel-button"
                        onClick={() => setIsConfirming(false)}
                        fullWidth
                        variant="secondary"
                    >
                        Cancel
                    </Button>
                    <Button
                        minSize="small"
                        fullWidth
                        data-testid="grace-period-modal-submit-button"
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                    >
                        Confirm
                    </Button>
                </Stack>
            </BaseModal>
        );
    }

    return (
        <FormWrapper
            testId="grace-period-modal"
            onFormChange={onFormChangeHandler}
            initialValues={initialValues}
            onSubmit={() => setIsConfirming(true)}
            isSubmitting={isSubmitting}
        >
            <Stack maxWidth={DEFAULT_MODAL_MAX_WIDTH} paddingX={DEFAULT_MODAL_PADDING_X}>
                <Box marginBottom={{ xs: 2.5, sm: 4 }}>
                    <Label
                        htmlFor="EndOfGracePeriodLabel"
                    >
                        Current end of grace period
                    </Label>
                    <StyledGracePeriod data-testid="grace-period-input" display="flex">
                        {formatDate(gracePeriodEndDate)}
                    </StyledGracePeriod>
                </Box>
                <LabeledDateInput
                    testId="end-date-picker"
                    onChange={handleEndDateChange}
                    name="toDate"
                    label="New end of grace period"
                    placeholder="Select end date"
                    minDate={minEndDate}
                    value={formatDate(endDate, DateFormats.DATE)}
                    dateOnly
                    position="fixed"
                    shouldDisableDate={shouldDisableDate}
                />
                {showInfoBox && (
                    <InfoBox gap={1} marginY={3}>
                        <Stack><WarningIcon /></Stack>
                        You&apos;re about to extend the grace period beyond 90 days of the expiration date
                    </InfoBox>
                )}
                <Stack direction="row" spacing={{ xs: 2, sm: 2.5 }} marginTop={ showInfoBox ? 0 : { xs: 3, sm: 6 }}>
                    <Button minSize="small" onClick={closeModal} fullWidth variant="secondary">Cancel</Button>
                    <Button
                        minSize="small"
                        fullWidth
                        data-testid="grace-period-modal-button"
                        type="submit"
                        disabled={isSubmitting || !endDate}
                    >
                        Confirm
                    </Button>
                </Stack>
            </Stack>
        </FormWrapper>
    );
};

export default UpdateGracePeriodModal;
