import React, { FC } from 'react';
import { Grid, GridProps, Stack } from '@mui/material';
import { useGeneralModal, useLookupNamesHook } from 'src/hooks';
import { getIndustriesList, getJobFunctionsList } from 'src/services/unified-db-api';
import { PageSection } from 'src/components/PageSection';
import { StyledHeaderName } from 'src/components/TableSection/HeaderSection/HeaderSection.styles';
import { StyledInfo } from 'src/pages/udb/ManageUser/ManageUser.styles';
import Line from 'src/components/Line';
import { JobInfo } from 'src/@types/unified-db-api';
import { TextButton } from 'src/components/Buttons';
import { ReactComponent as EditIcon } from 'src/assets/icons/edit-icon.svg';
import { GeneralModalKey, TABLE_SECTION_DEFAULT_COLUMNS } from 'src/constants';

export type GridColumnProps = Record<'company' | 'jobTitle' | 'industry' | 'jobFunction', GridProps>;

const gridColumnParams: GridColumnProps = {
    company: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 2,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap-reverse',
        gap: { xs: 0, md: 1 },
    },
    jobTitle: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: 0, md: 1 },
    },
    industry: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: 0, md: 1 },
    },
    jobFunction: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: 0, md: 1 },
    },
};

const gridColumnTitles = {
    company: 'Company',
    industry: 'Industry',
    jobFunction: 'Job function',
    jobTitle: 'Job title',
};

const JobInformationSection: FC<{ jobInformation?: JobInfo, isEditable: boolean, uuid: string }> = ({
    jobInformation,
    isEditable,
    uuid,
}) => {
    const industryName = useLookupNamesHook(getIndustriesList, jobInformation?.industryId);
    const jobFunctionName = useLookupNamesHook(getJobFunctionsList, jobInformation?.jobFunctionId);

    const { openModal } = useGeneralModal();

    const openJobInformationModal = () => {
        openModal({
            key: GeneralModalKey.editJobInformation,
            extraProps: {
                jobInformation,
                uuid,
            },
        });
    };

    return (
        <PageSection
            data-testid="job-information-section"
            sectionPaddingBottom={3}
            header="Job information"
            headerChildren={
                isEditable ? (
                    <TextButton onClick={openJobInformationModal} data-testid="edit-btn">
                        <EditIcon />
                    </TextButton>
                ) : null
            }
        >
            <Grid container columns={TABLE_SECTION_DEFAULT_COLUMNS}>
                <Grid item {...gridColumnParams.company}>
                    <StyledHeaderName>{gridColumnTitles.company}</StyledHeaderName>
                    <Stack flexDirection="row" alignItems="center" gap={1}>
                        <StyledInfo>{jobInformation?.companyName}</StyledInfo>
                    </Stack>
                </Grid>
                <Grid item display={{ xs: 'block', md: 'none' }} paddingY={2} xs={12}>
                    <Line />
                </Grid>
                <Grid item {...gridColumnParams.jobTitle}>
                    <StyledHeaderName>{gridColumnTitles.jobTitle}</StyledHeaderName>
                    <StyledInfo>{jobInformation?.jobTitle}</StyledInfo>
                </Grid>
                <Grid item display={{ xs: 'block', md: 'none' }} paddingY={2} xs={12}>
                    <Line />
                </Grid>
                <Grid item {...gridColumnParams.industry}>
                    <StyledHeaderName>{gridColumnTitles.industry}</StyledHeaderName>
                    <StyledInfo>{industryName}</StyledInfo>
                </Grid>
                <Grid item display={{ xs: 'block', md: 'none' }} paddingY={2} xs={12}>
                    <Line />
                </Grid>
                <Grid item {...gridColumnParams.jobFunction}>
                    <StyledHeaderName>{gridColumnTitles.jobFunction}</StyledHeaderName>
                    <StyledInfo>{jobFunctionName}</StyledInfo>
                </Grid>
            </Grid>
        </PageSection>
    );
};

export default JobInformationSection;
