export enum ActiveStep {
    TYPE_AND_BILLING = 'typeAndBilling',
    PRICING_TERMS = 'pricingTerms',
    ACCOUNT_OWNER = 'accountOwner',
    CONFIRMATION = 'confirmation',
}

export enum PricingModel {
    FIXED_PRICE = 'fixedPrice',
    RENEWABLE_PRICE = 'renewablePrice',
}

export const pricingModelRenderLabels: Record<PricingModel, string> = {
    [PricingModel.FIXED_PRICE]: 'Fixed price forever',
    [PricingModel.RENEWABLE_PRICE]: 'Based on the price at a renewal',
};

export enum DiscountDuration {
    FIRST_CYCLE = 'firstCycle',
    MULTIPLE_CYCLE = 'multipleCycle',
    FOREVER = 'forever',
}
