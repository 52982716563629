import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { BillingRecordModel, BillingRecordWithRenewal } from 'src/@types/subscription-service-api';
import type { RootState } from 'src/redux/root-reducer';

export type SubscriptionState = {
    upcomingBillingRecord?: BillingRecordWithRenewal;
    currentBillingRecord?: BillingRecordModel;
};

const initialState: SubscriptionState = {
    upcomingBillingRecord: undefined,
    currentBillingRecord: undefined,
};

export { initialState as subscriptionInitialState };

export const subscriptionSlice = createSlice<SubscriptionState, {
    setUpcomingBillingRecord: CaseReducer<SubscriptionState, PayloadAction<BillingRecordWithRenewal | undefined>>;
    setCurrentBillingRecord: CaseReducer<SubscriptionState, PayloadAction<BillingRecordModel | undefined>>;
    clearUpcomingBillingRecord: CaseReducer<SubscriptionState>;
    clearCurrentBillingRecord: CaseReducer<SubscriptionState>;
}>({
    name: 'subscription',
    initialState,
    reducers: {
        setUpcomingBillingRecord: (state, { payload }) => ({
            ...state,
            upcomingBillingRecord: payload,
        }),
        setCurrentBillingRecord: (state, { payload }) => ({
            ...state,
            currentBillingRecord: payload,
        }),
        clearUpcomingBillingRecord: (state) => ({
            ...state,
            upcomingBillingRecord: initialState.upcomingBillingRecord,
        }),
        clearCurrentBillingRecord: (state) => ({
            ...state,
            currentBillingRecord: initialState.currentBillingRecord,
        }),
    },
});

export const {
    setUpcomingBillingRecord,
    setCurrentBillingRecord,
    clearUpcomingBillingRecord,
    clearCurrentBillingRecord,
} = subscriptionSlice.actions;

export const subscriptionSelector = (state: RootState) => state[subscriptionSlice.name];
