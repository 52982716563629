import React, { FC, useCallback, useState } from 'react';
import { Box } from '@mui/material';
import { DropdownIcon, MenuItemWrapper, StyledButton, StyledMenu, StyledMenuItem } from './Menu.styles';
import { ActionButton, ActionButtonVariant } from 'src/components/Buttons/ActionButton';
import { TextButton } from 'src/components/Buttons';
import StyledTooltip from 'src/components/Tooltip';
import { ReactComponent as InfoIcon } from 'src/assets/icons/info.svg';
import { ReactComponent as ThreeDotsActionIcon } from 'src/assets/icons/three-dots-action-icon.svg';
import { ReactComponent as ThreeDotsIcon } from 'src/assets/icons/three-dots-icon.svg';

export enum MenuVariant {
    THREE_DOTS_ACTION_BUTTON = 'three-dots-action-button',
    THREE_DOTS_TEXT_BUTTON = 'text-button',
    TEXT = 'text',
}

export type MenuOption = {
    value: string;
    label: string;
    disabled?: boolean;
    tooltipTitle?: string;
};

type MenuProps = {
    id?: string;
    menuItems: MenuOption[];
    buttonVariant: MenuVariant;
    buttonText?: string;
    onClick: (value: string) => void;
    tooltipMaxWidth?: 'unset';
};

const Menu: FC<MenuProps> = ({
    id = 'menu-button',
    menuItems,
    buttonText,
    onClick,
    buttonVariant,
    tooltipMaxWidth,
}) => {
    if (menuItems.length === 0) {
        return null;
    }

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleMenuItemClick = useCallback((label: string) => {
        handleClose();
        onClick(label);
    }, [onClick]);

    const iconMapping = {
        [MenuVariant.THREE_DOTS_ACTION_BUTTON]: (
            <ActionButton
                data-variant={ActionButtonVariant.GREY}
                data-attribute-to-change="fill"
                data-testid="open-icon"
                onClick={handleClick}
            >
                <ThreeDotsActionIcon />
            </ActionButton>
        ),
        [MenuVariant.THREE_DOTS_TEXT_BUTTON]: (
            <TextButton onClick={handleClick} data-testid="open-icon">
                <ThreeDotsIcon />
            </TextButton>
        ),
    };

    return (
        <Box>
            {buttonVariant === MenuVariant.TEXT ? (
                <StyledButton
                    id={id}
                    data-testid={id}
                    aria-controls={open ? `${id}-menu` : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="contained"
                    disableElevation
                    disableRipple
                    onClick={handleClick}
                    endIcon={<DropdownIcon width={16} height={16} rotate={open ? '180deg' : 0} />}
                >
                    {buttonText}
                </StyledButton>
            ) : (
                iconMapping[buttonVariant]
            )}
            <StyledMenu
                id={`${id}-menu`}
                data-testid={`${id}-menu`}
                MenuListProps={{
                    'aria-labelledby': id,
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {menuItems.map((item) => (
                    <MenuItemWrapper
                        key={item.value}
                        direction="row"
                        gap={2}
                        tabIndex={0}
                        data-testid={`menu-item-${item.value}`}
                        onKeyDown={(e) => {
                            if (e.key === ' ') {
                                handleMenuItemClick(item.value);
                            }
                        }}

                    >
                        <StyledMenuItem
                            disableRipple
                            disabled={item.disabled}
                            onClick={() => handleMenuItemClick(item.value)}
                        >
                            {item.label}
                        </StyledMenuItem>{item.tooltipTitle && (
                            <StyledTooltip arrow title={item?.tooltipTitle} data-max-width={tooltipMaxWidth}>
                                <InfoIcon data-testid="info-icon" />
                            </StyledTooltip>
                        )}
                    </MenuItemWrapper>
                ))}
            </StyledMenu>
        </Box>
    );
};

export default Menu;
