import React, { FC, useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import {
    type GetContentMaxWidthProps,
    IconContainer,
    StyledCloseIcon,
    StyledEnable2FABannerContainer,
    StyledEnable2FABannerContent,
    StyledSubTitle,
    StyledTitle,
} from './EnableTFABanner.styles';
import { getStorage } from 'src/services/storage-provider';
import { formatDate } from 'src/services/formatters';
import { GeneralModalKey, INTERCOM_ICON_Z_INDEX } from 'src/constants';
import { useGeneralModal } from 'src/hooks';
import { Button, TextButton } from 'src/components/Buttons';
import { PageVariant } from 'src/layouts/BasicLayout/BasicLayout';
import { ReactComponent as WarningIcon } from 'src/assets/icons/warning-icon.svg';
import type { RootState } from 'src/redux/root-reducer';
import type { CurrentUserState } from 'src/@types/redux';
import { userSelector } from 'src/redux/slices';

type Enable2FABannerProps = {
    pageVariant?: PageVariant;
    calculateMaxWidthFn?: (props: GetContentMaxWidthProps) => string;
};

export const CLOSED_AT_BANNER_KEY = 'enable2FABannerClosedAt';

const EnableTFABanner: FC<Enable2FABannerProps> = ({ pageVariant = PageVariant.PAGE, calculateMaxWidthFn }) => {
    const { currentUser } = useSelector<RootState, CurrentUserState>(userSelector);
    const [hidden, setHidden] = useState<boolean>(true);
    const { openModal } = useGeneralModal();
    const storage = getStorage();

    const closedAt = storage.getItem(CLOSED_AT_BANNER_KEY);

    const showBanner = () => {
        storage.removeItem(CLOSED_AT_BANNER_KEY);
        setHidden(false);
    };

    const enable2FA = () => {
        openModal({
            key: GeneralModalKey.enableTFAModal,
        });
    };

    useEffect(() => {
        if (!closedAt) {
            setHidden(false);
            return;
        }

        const now = dayjs();
        const openAt = dayjs(closedAt).add(1, 'hour');

        if (now.isAfter(openAt)) {
            showBanner();
            return;
        }

        const timeLeft = openAt.valueOf() - now.valueOf();
        const timeOut = setTimeout(() => {
            showBanner();
        }, timeLeft);

        return () => {
            clearTimeout(timeOut);
        };
    }, [closedAt]);

    const handleClose = () => {
        storage.setItem(CLOSED_AT_BANNER_KEY, dayjs().toISOString());
        setHidden(true);
    };

    if (hidden || !currentUser?.enableTFADueDate) {
        return null;
    }

    return (
        <StyledEnable2FABannerContainer
            data-testid="enable-2fa-banner"
            paddingX={{ xs: 2, md: 3.5 }}
            paddingY={{ xs: 2, md: 2.5 }}
            boxSizing="border-box"
            zIndex={INTERCOM_ICON_Z_INDEX + 1}
            alignItems="center"
            position="fixed"
            width="100%"
            bottom={0}
            left={0}
        >
            <StyledEnable2FABannerContent
                direction={{ xs: 'column', md: 'row' }}
                spacing={2}
                data-page-variant={pageVariant}
                calculateMaxWidthFn={calculateMaxWidthFn}
                justifyContent="space-between"
                alignItems="center"
            >
                <Stack spacing={{ xs: 2, md: 3 }} direction="row" alignItems="center">
                    <IconContainer
                        width={40}
                        height={40}
                        minWidth={40}
                        minHeight={40}
                        borderRadius={20}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <WarningIcon />
                    </IconContainer>
                    <Stack paddingRight={{ xs: 4, md: 0 }}>
                        <StyledTitle>
                            Enable 2FA or you will be locked out
                        </StyledTitle>
                        <StyledSubTitle>
                            You will need to enable two-factor authentication by&nbsp;
                            <b>{formatDate(currentUser.enableTFADueDate)}</b> to avoid being locked out.
                        </StyledSubTitle>
                    </Stack>
                </Stack>
                <Stack
                    gap={4.5}
                    direction="row"
                    alignItems="center"
                    width={{ xs: '100%', md: 'unset' }}
                    display={{ xs: 'block', md: 'flex' }}
                >
                    <Button fullWidth onClick={enable2FA}>
                        Enable 2FA
                    </Button>
                    <Box position={{ xs: 'absolute', md: 'static' }} right={16} top={16}>
                        <TextButton onClick={handleClose} data-testid="hide-banner">
                            <StyledCloseIcon />
                        </TextButton>
                    </Box>
                </Stack>
            </StyledEnable2FABannerContent>
        </StyledEnable2FABannerContainer>
    );
};

export default EnableTFABanner;
