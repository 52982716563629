import React, { FC, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { RenewalDataRow } from './';
import type { BillingRecordModel } from 'src/@types/subscription-service-api';
import { INVALID_SALESFORCE_CONTRACT_LINK } from 'src/constants';
import { Link } from 'src/components/Link';
import { StyledErrorMessage } from 'src/components/Inputs/StyledInput.styles';
import { contractLinkValidator } from 'src/services/validators';
import { TextInput } from 'src/components/Inputs/TextInput';
import { StyledTextButton } from 'src/components/Modals/UpcomingRenewalModal/UpcomingRenewalModal.styles';
import { Button } from 'src/components/Buttons';

type ContractLinkRowProps = {
    handleSave: (renewal: Partial<BillingRecordModel>) => Promise<void>;
    contractLink: string | null;
};

const ContractLinkRow: FC<ContractLinkRowProps> = ({ handleSave, contractLink }) => {
    const [showEditInput, setShowEditInput] = useState<boolean>(false);
    const [contractLinkValue, setContractLinkValue] = useState<string>(contractLink ?? '');
    const [isValid, setIsValid] = useState<boolean>(true);

    const handleBlur = () => {
        contractLinkValidator.validate(contractLinkValue)
            .then(() => setIsValid(true))
            .catch(() => setIsValid(false));
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsValid(true);
        setContractLinkValue(event.target.value);
    };

    const isSaveButtonDisabled = () => {
        return contractLinkValue === contractLink || !contractLinkValue || !isValid;
    };

    return (
        <RenewalDataRow
            title="Contract:"
            skipLine
            height={{ xs: 'unset', sm: '20px' }}
            paddingBottom={{ xs: 0, sm: 1 }}
            currentRenewalData={contractLink ? (
                <Link target="_blank" href={contractLink}>Open contract</Link>
            ) : (
                'No contract added'
            )}
            overrideButton={(
                <Box>
                    {!showEditInput && (
                        <StyledTextButton onClick={() => setShowEditInput(true)} data-testid="add-contract-link">
                            {contractLink ? 'Update' : 'Add'} contract link
                        </StyledTextButton>
                    )}
                </Box>
            )}
        >
            {showEditInput && (
                <Grid
                    container
                    spacing={1}
                    marginTop={{ xs: 0, sm: 0.5 }}
                    marginBottom={{ xs: 0, sm: 0.5 }}
                >
                    <Grid item xs={9} sm={8}>
                        <TextInput
                            placeholder={`${contractLink ? 'Update' : 'Add'} contract link`}
                            value={contractLinkValue}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputProps={{
                                inputProps: {
                                    'data-testid': 'contract-link-input',
                                    autoComplete: 'off',
                                },
                            }}
                        />
                        {!isValid && (<StyledErrorMessage>{INVALID_SALESFORCE_CONTRACT_LINK}</StyledErrorMessage>)}
                    </Grid>
                    <Grid item xs={3} sm={4}>
                        <Button
                            fullWidth
                            minSize="unset"
                            disabled={isSaveButtonDisabled()}
                            testId="save-contract-link"
                            onClick={() => {
                                handleSave({ contractLink: contractLinkValue })
                                    .then(() => setShowEditInput(false));
                            }}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            )}
        </RenewalDataRow>
    );
};

export default ContractLinkRow;
