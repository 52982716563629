import { createListenerMiddleware } from '@reduxjs/toolkit';
import { getStorage } from 'src/services/storage-provider';
import { setTimeZone } from 'src/services/date-time-zone-converter';
import {
    clearActiveSubscriptions,
    clearCurrentUser,
    configSelector,
    setActiveSubscriptions,
    setCurrentUser,
    setIsUserDataLoaded,
} from 'src/redux/slices';
import { bootIntercom, prepareIntercomSettings, shutdownIntercom } from 'src/services/intercom';
import type { RootState } from 'src/redux/root-reducer';
import { getProfileActiveSubscriptions } from 'src/services/sso-api';

export const setCurrentUserListener = createListenerMiddleware();
setCurrentUserListener.startListening({
    actionCreator: setCurrentUser,
    effect: async ({ payload }, listenerApi) => {
        const configStore = configSelector(listenerApi.getState() as RootState);
        bootIntercom(prepareIntercomSettings(configStore, payload));
        setTimeZone(payload.settings.timeZone);

        await getProfileActiveSubscriptions()
            .then((data) => {
                listenerApi.dispatch(setActiveSubscriptions(data));
            })
            .catch(() => {
                listenerApi.dispatch(clearActiveSubscriptions());
            });

        listenerApi.dispatch(setIsUserDataLoaded(true));
    },
});

export const clearCurrentUserListener = createListenerMiddleware();
clearCurrentUserListener.startListening({
    actionCreator: clearCurrentUser,
    effect: async (action, listenerApi) => {
        const configStore = configSelector(listenerApi.getState() as RootState);
        shutdownIntercom();
        getStorage().clear();
        bootIntercom(prepareIntercomSettings(configStore));

        listenerApi.dispatch(clearActiveSubscriptions());
    },
});
