import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'src/redux/root-reducer';
import type { ConfigState } from 'src/@types/redux';

const initialState: ConfigState = {
    appName: '',
    ssoApiUrl: '',
    mainSiteUrl: '',
    intercom: {
        appId: '',
        apiUrl: '',
        widgetUrl: '',
        cookieDomain: '',
    },
    features: {
        accountUpgrade: false,
        accountCreate: false,
    },
};

export { initialState as configInitialState };

export const configSlice = createSlice<ConfigState, {
    setConfig: CaseReducer<ConfigState, PayloadAction<ConfigState>>;
}>({
    name: 'config',
    initialState,
    reducers: {
        setConfig: (state, { payload }) => ({
            ...state,
            ...payload,
        }),
    },
});

export const {
    setConfig,
} = configSlice.actions;

export const configSelector = (state: RootState): ConfigState => state[configSlice.name];
