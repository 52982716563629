import styled from 'styled-components';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import { INTERCOM_ICON_Z_INDEX } from 'src/constants';

const maxHeight = '95vh';
const headerHeight = {
    xs: '68px',
    sm: '80px',
};
const contentMaxHeight = {
    xs: `calc(${maxHeight} - ${headerHeight.xs})`,
    sm: `calc(${maxHeight} - ${headerHeight.sm})`,
};

export const StyledModal = styled(Modal)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledModalContainer = styled(Paper)`
    background-color: ${({ theme }) => theme.palette.white};
    overflow: hidden;
    max-height: ${maxHeight};
    min-width: 550px;
    z-index: ${INTERCOM_ICON_Z_INDEX + 2};

    @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
        min-width: unset;
        width: 90vw;
    }
`;

export const StyledArrowBackIcon = styled(ArrowBackIcon)`
    && {
        color: ${({ theme }) => theme.palette.lightBlack};
        height: 100%;
        
        &:hover {
            color: ${({ theme }) => theme.palette.black};
        }
    }
`;

export const StyledButton = styled.button`
    position: relative;
    height: 24px;
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
    z-index: 100;

    @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
        height: 18px;
    }
`;

export const StyledCloseIcon = styled(CloseIcon)`
    && {
        height: 100%;
        color: ${({ theme }) => theme.palette.lightBlack};

        &:hover {
            color: ${({ theme }) => theme.palette.black};
        }
    }
`;

export const StyledHeaderContainer = styled(Grid)`
    z-index: 100;
    color: ${({ theme }) => theme.palette.black};
    font-family: ${({ theme }) => theme.fonts.bold};
    font-size: 18px;
    height: ${headerHeight.sm};
    
    @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
        font-size: 16px;
        height: ${headerHeight.xs};
    }

    & svg {
        display: inline-block;
        vertical-align: middle;
        max-height: 23px;
    }
`;

export const StyledContentContainer = styled(Box)`
    overflow: auto;
    max-height: ${contentMaxHeight.sm};

    @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
        max-height: ${contentMaxHeight.xs};
    }
`;

export const StyledModalMessage = styled(Box)`
    && {
        font-size: 16px;
        max-width: 478px;
        font-family: ${({ theme }) => theme.fonts.medium};
        color: ${({ theme }) => theme.palette.black};
        line-height: 140%;

        span{
            font-family: ${({ theme }) => theme.fonts.bold};
        }
        
        @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
            font-size: 14px;
        }
    } 
`;
