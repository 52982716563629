import React from 'react';
import { StackProps } from '@mui/material';
import { maxWidthByPageVariant, StyledBasicLayout } from './BasicLayout.styles';
import { Header } from './components/Header';
import { Content } from './components/Content';
import { Footer } from './components/Footer';
import { EnableTFABanner } from 'src/components/Banners';
import { GeneralModal } from 'src/components/Modals/GeneralModal';

export enum PageVariant {
    PAGE = 'page',
    DATA_GRID = 'dataGrid',
    FORM = 'form',
}

type BasicLayoutProps = {
    logoLinkTo?: string;
    children?: React.ReactNode;
    pageVariant?: PageVariant;
    testId?: string;
} & Pick<StackProps, 'spacing'>;

export const BasicLayout = ({
    logoLinkTo,
    children,
    pageVariant = PageVariant.PAGE,
    spacing,
    testId,
}: BasicLayoutProps) => {
    return (
        <StyledBasicLayout data-testid={testId}>
            <Header
                logoLinkTo={logoLinkTo}
                paddingX={{ md: 3.5, xs: 3 }}
                pageVariant={pageVariant}
            />
            <Content paddingX={{ sm: 3.5, xs: 2 }} pageVariant={pageVariant} spacing={spacing}>
                {children}
            </Content>
            <Footer />
            <GeneralModal />
            <EnableTFABanner calculateMaxWidthFn={maxWidthByPageVariant(true)} pageVariant={pageVariant} />
        </StyledBasicLayout>
    );
};
