import React, { FC, ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/material';
import {
    ModalContentWrapper,
    ModalFooter,
    ModalHeader,
    ModalWrapper,
    StyledCloseIcon,
    StyledMenuIcon,
} from './MobileMenu.styles';
import { HeaderMenuItem } from 'src/layouts/BasicLayout/components/Header/components/HeaderMenuItem';
import { userSelector } from 'src/redux/slices';
import { getMenuLinks, isDropdownItem, isNotDivider } from 'src/services/header-menu-helper';
import { NavButton } from 'src/components/NavButton';
import { PAGE_TITLES } from 'src/constants';
import { MenuDropdown } from 'src/layouts/BasicLayout/components/Header/components/MenuDropdown';
import { CurrentUserState } from 'src/@types/redux';
import type { RootState } from 'src/redux/root-reducer';
import { ActiveSubscriptionHeader } from 'src/components/ActiveSubscriptionHeader';
import { makeSlug } from 'src/services/formatters';

const MobileMenu: FC = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const currentUserState = useSelector<RootState, CurrentUserState>(userSelector);

    const allowedModalMenuLinks = getMenuLinks(currentUserState, true);

    const closeMenuHandler = () => {
        setIsOpen(false);
    };

    const testIdPrefix = 'header-mobile-menu';

    const menuLinks: ReactElement[] = allowedModalMenuLinks
        .filter(isNotDivider)
        .map((menuItem) => {
            const subItemTestId = `${testIdPrefix}--${makeSlug(menuItem.label)}`;
            return isDropdownItem(menuItem) ? (
                <MenuDropdown
                    key={subItemTestId}
                    dropdownItem={menuItem}
                    testId={subItemTestId}
                    onItemClick={closeMenuHandler}
                />
            ) : (
                <HeaderMenuItem key={subItemTestId} item={menuItem} onClick={closeMenuHandler} testId={subItemTestId} />
            );
        });

    return (
        <>
            <Box data-testid={`${testIdPrefix}-open-button`} onClick={() => setIsOpen(true)}>
                <StyledMenuIcon />
            </Box>
            <Modal
                data-testid="header-menu-modal"
                open={isOpen}
                onClose={() => setIsOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalWrapper>
                    <ModalHeader
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        padding={3}
                        pb={2.5}
                    >
                        <ActiveSubscriptionHeader />
                        <Box
                            display="flex"
                            onClick={() => setIsOpen(false)}
                            data-testid={`${testIdPrefix}-close-button`}
                        >
                            <StyledCloseIcon width={24} height={24} />
                        </Box>
                    </ModalHeader>
                    <ModalContentWrapper display="flex" flexDirection="column">
                        {menuLinks}
                    </ModalContentWrapper>
                    <ModalFooter position="absolute" left={0} right={0} bottom={0} display="flex">
                        <NavButton to="/log-out" data-testid={`${testIdPrefix}--log-out`}>
                            {PAGE_TITLES.LOG_OUT}
                        </NavButton>
                    </ModalFooter>
                </ModalWrapper>
            </Modal>
        </>
    );
};

export default MobileMenu;
