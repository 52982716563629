import React, { FC, useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { StyledDate, StyledRole, StyledText, StyledType } from './ProfileSubscriptions.styles';
import { sortSubscriptions } from 'src/services/sorter';
import { Spinner } from 'src/components/Spinner';
import {
    getPersonLabelForSubscription,
    getSubscriptionPaymentStatus,
    isOnGracePeriod,
} from 'src/services/subscription-service-getters';
import { ActionButton, ActionButtonVariant } from 'src/components/Buttons/ActionButton';
import { RoundedBadge } from 'src/components/Badge';
import { useSnackbarMessage } from 'src/hooks';
import { getProfileAccessibleSubscriptions } from 'src/services/sso-api';
import { formatDate } from 'src/services/formatters';
import { roundedBadgePropsMapping, SnackbarMessageVariants, SubscriptionPaymentStatus } from 'src/constants';
import { PageSection } from 'src/components/PageSection';
import { ProfileAccessibleSubscription } from 'src/@types/sso-api';
import { ReactComponent as DetailsIcon } from 'src/assets/icons/payment-details-icon.svg';
import { ReactComponent as InfoIcon } from 'src/assets/icons/grace-period-info.svg';
import StyledTooltip from 'src/components/Tooltip';

const ProfileSubscriptions: FC = () => {
    const [subscriptions, setSubscriptions] = useState<ProfileAccessibleSubscription[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const { addMessage } = useSnackbarMessage();

    useEffect(() => {
        getProfileAccessibleSubscriptions()
            .then(sortSubscriptions)
            .then(setSubscriptions)
            .catch(() => {
                addMessage('Failed to fetch subscriptions', SnackbarMessageVariants.ERROR);
            })
            .finally(() => setIsLoading(false));
    }, []);

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <>
            {subscriptions.map((subscription) => {
                const roleText = getPersonLabelForSubscription(subscription);
                const paymentStatus = getSubscriptionPaymentStatus(subscription);

                return (
                    <PageSection
                        key={subscription.subscriptionUUID}
                        marginTop={{ xs: 2, md: 2.5 }}
                        sectionPaddingBottom={{ xs: 2, md: 3 }}
                    >
                        <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                            <Box>
                                <StyledType marginBottom={roleText ? 0 : { xs: 0.5, md: 1.5 }} gap={1}>
                                    {subscription.type}
                                    <RoundedBadge {...roundedBadgePropsMapping[subscription.status]} />
                                    {paymentStatus === SubscriptionPaymentStatus.WILL_NOT_RENEW && (
                                        <RoundedBadge
                                            label={SubscriptionPaymentStatus.WILL_NOT_RENEW}
                                        />
                                    )}
                                    {isOnGracePeriod(subscription) && (
                                        <StyledTooltip arrow title="On grace period">
                                            <InfoIcon data-testid="on-grace-period-info-icon" />
                                        </StyledTooltip>
                                    )}
                                </StyledType>
                                {roleText && (<StyledRole>{roleText}</StyledRole>)}
                                <StyledDate>Activated on:
                                    <StyledText> {formatDate(subscription.activationDate)}</StyledText>
                                </StyledDate>
                                <StyledDate>Expires on:
                                    <StyledText> {formatDate(subscription.expirationDate)}</StyledText>
                                </StyledDate>
                            </Box>
                            {(subscription.isOwner || subscription.isManager) && (
                                <StyledTooltip arrow title="Manage subscription">
                                    <ActionButton
                                        href={`/subscriptions/${subscription.subscriptionUUID}`}
                                        data-testid="details-button"
                                        data-attribute-to-change="stroke"
                                        data-variant={ActionButtonVariant.BLUE}
                                    >
                                        <DetailsIcon />
                                    </ActionButton>
                                </StyledTooltip>
                            )}
                        </Stack>
                    </PageSection>
                );
            })}
        </>
    );
};

export default ProfileSubscriptions;
