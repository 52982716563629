import { TABLE_SECTION_DEFAULT_COLUMNS } from 'src/constants';
import { GridColumnProps } from 'src/components/TableSection/TableSection';

export const gridColumnParams: GridColumnProps = {
    id: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        column_title: 'Id',
        gap: { xs: 0, md: 1 },
    },
    status: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        column_title: 'Status',
        gap: { xs: 0, md: 1 },
    },
    tfaEnabled: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        column_title: '2FA Enabled',
        gap: { xs: 0, md: 1 },
    },
    createdAt: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        column_title: 'Created At',
        gap: { xs: 0, md: 1 },
    },
    updatedAt: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        column_title: 'Updated At',
        display: 'flex',
        gap: { xs: 0, md: 1 },
    },
};
