import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { TechnicalProblemMessage } from 'src/components/TechnicalProblemMessage';
import { fetchEmailDelivery, getNewsletterByKey, updateEmailDelivery } from 'src/services/sso-api';
import { Spinner } from 'src/components/Spinner';
import { NewsletterModel, ProfileEmailDeliveryPreferences } from 'src/@types/sso-api';
import { ReactComponent as NewsletterIcon } from 'src/assets/icons/newsletter.svg';
import { PAGE_TITLES, UDBNewsletterChannel } from 'src/constants';
import { SingleModalLayout } from 'src/layouts';
import { Button } from 'src/components/Buttons';
import PageTitle from 'src/components/PageTitle';
import { configSelector, userSelector } from 'src/redux/slices';
import { OneClickSubscribeWrapper } from 'src/pages/OneClickSubscribe/OneClickSubscribel.styles';
import 'dayjs/locale/en-gb';
import { getFirstIssueDayContent } from 'src/services/one-click-subscribe-helper';
import { setPageTitle } from 'src/services/page-title';
import { ConfigState, CurrentUserState } from 'src/@types/redux';
import type { RootState } from 'src/redux/root-reducer';

const OneClickSubscribePage: FC = () => {
    const navigate = useNavigate();
    const { mainSiteUrl } = useSelector<RootState, ConfigState>(configSelector);
    const { currentUser } = useSelector<RootState, CurrentUserState>(userSelector);

    const params = useParams<Record<'uuid' | 'newsletterKey', string>>();
    const { uuid, newsletterKey } = params as Required<typeof params>;

    const [hasError, setHasError] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isAlreadySubscribed, setIsAlreadySubscribed] = useState<boolean>(false);
    const [newsletter, setNewsletter] = useState<NewsletterModel>();

    const subscribe = ([fetchedNewsletter, { verticals }]: [NewsletterModel, ProfileEmailDeliveryPreferences]) => {
        const subscribedKeys = verticals.reduce<string[]>((newslettersKeys, { newsletters }) => {
            newsletters.forEach(({ selectedChannels, key }) => {
                if (selectedChannels.includes(UDBNewsletterChannel.EMAIL)) {
                    newslettersKeys.push(key);
                }
            });

            return newslettersKeys;
        }, []);

        const isSubscribed = subscribedKeys.includes(fetchedNewsletter.key);

        setNewsletter(fetchedNewsletter);
        setIsAlreadySubscribed(isSubscribed);

        if (!isSubscribed) {
            return updateEmailDelivery(uuid, { newsletters: [{ id: fetchedNewsletter.id, isSelected: true }] });
        }
    };

    const setTitle = (
        data: [NewsletterModel, ProfileEmailDeliveryPreferences],
    ): [NewsletterModel, ProfileEmailDeliveryPreferences] => {
        if (data[0].name) {
            setPageTitle(`${PAGE_TITLES.ONE_CLICK_SUBSCRIBE}: ${data[0].name}`);
        }

        return data;
    };

    useEffect(() => {
        Promise.all([
            getNewsletterByKey(newsletterKey),
            fetchEmailDelivery(uuid),
        ])
            .then(setTitle)
            .then(subscribe)
            .catch(() => setHasError(true))
            .finally(() => setIsLoading(false));
    }, []);

    if (isLoading) {
        return (
            <Spinner />
        );
    }

    if (hasError) {
        return (
            <TechnicalProblemMessage />
        );
    }

    const title = isAlreadySubscribed ? `You are already signed up for ${newsletter?.name}` : (
        <>
            <Typography display={{ xs: 'block', sm: 'inline' }} fontSize="inherit" fontFamily="inherit">
                Success!&nbsp;
            </Typography>
            Welcome to {newsletter?.name}
        </>
    );

    return (
        <SingleModalLayout variant="message">
            <OneClickSubscribeWrapper textAlign="center">
                {!isAlreadySubscribed && <NewsletterIcon />}
                <PageTitle textAlign="center" title={title} variant="form">
                    {!isAlreadySubscribed && (
                        <>
                            {newsletter && getFirstIssueDayContent(newsletter)}
                            <br />
                            <br />
                        </>
                    )}
                    Want to change which emails you get from us? Please {!currentUser && 'log in and '}visit your reader profile.
                </PageTitle>
                <Stack direction="row" spacing={2.5} marginTop={3}>
                    <Button href={mainSiteUrl} fullWidth variant="secondary">
                        Latest news
                    </Button>
                    <Button onClick={() => navigate(currentUser ? '/' : '/log-in')} fullWidth variant="secondary">
                        {currentUser ? 'Reader profile' : 'Log in'}
                    </Button>
                </Stack>
            </OneClickSubscribeWrapper>
        </SingleModalLayout>
    );
};

export default OneClickSubscribePage;
