import React, { FC, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import dayjs from 'dayjs';
import {
    buildActivationParams,
    buildCreateBillingRecordParams,
    buildCreateSubscriptionParams,
    buildUpdateSubscriptionParams,
    shouldUpdateActivationDate,
    shouldUpdateCustomPricingTerms,
} from './services/param-builders';
import { convertDateTimeZone } from 'src/services/date-time-zone-converter';
import { makePlural } from 'src/services/text-modifier';
import { useSnackbarMessage } from 'src/hooks';
import { SuccessMessage } from 'src/pages/udb/CreateSubscription/components/SuccessMessage';
import {
    activateSubscription,
    addManager,
    attachDomains,
    calculateDates,
    createInitialBillingRecord,
    createSubscription,
    setOwner,
    updateSubscription,
} from 'src/services/subscription-service-api';
import {
    getPricingTermsData,
    getTypeAndBillingData,
} from 'src/pages/udb/CreateSubscription/services/confirmation-step-getters';
import { DomainInfoBox } from 'src/components/DomainInfoBox';
import { UserInfoBox } from 'src/components/UserInfoBox';
import {
    ActivationDate,
    DATE_TIME_ISO_8601,
    SnackbarMessageVariants,
    SubscriptionBillingType,
    SubscriptionType,
} from 'src/constants';
import { InfoBox } from 'src/components/InfoBox';
import { PricingTermsData } from 'src/pages/udb/CreateSubscription/components/PricingTermsStep/PricingTermsStep';
import type {
    TypeAndBillingData,
} from 'src/pages/udb/CreateSubscription/components/TypeAndBillingStep/TypeAndBillingStep';
import { PageSection } from 'src/components/PageSection';
import { StepContainer } from 'src/pages/udb/CreateSubscription/components/StepContainer';
import { User } from 'src/@types/unified-db-api';

type ConfirmationStepProps = {
    handleGoBack: () => void;
    typeAndBillingData: Required<TypeAndBillingData>;
    pricingTermsData: PricingTermsData;
    ownerData: User;
    reloadPage: () => void;
};

const ConfirmationStep: FC<ConfirmationStepProps> = ({
    handleGoBack,
    typeAndBillingData,
    pricingTermsData,
    ownerData,
    reloadPage,
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [createdSubscriptionUUID, setCreatedSubscriptionUUID] = useState<string>();
    const [expirationDate, setExpirationDate] = useState<string>();

    const { addMessage } = useSnackbarMessage();

    const isFreeInsider = typeAndBillingData.selectedBillingType === SubscriptionBillingType.FREE
        && typeAndBillingData.selectedSubscriptionType === SubscriptionType.INSIDER;

    useEffect(() => {
        if (
            typeAndBillingData.selectedActivationDateType === ActivationDate.SPECIFIED
            && typeAndBillingData.activationDate
        ) {
            setIsLoading(true);
            calculateDates({
                cycleCount: typeAndBillingData.numberOfCycles,
                cycleLengthInMonths: typeAndBillingData.selectedBillingCycleLength,
                activationDate: dayjs(typeAndBillingData.activationDate).format(DATE_TIME_ISO_8601),
            })
                .then(({ expirationDate: calculatedExpirationDate }) => {
                    setExpirationDate(calculatedExpirationDate);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, []);

    if (createdSubscriptionUUID) {
        return (
            <SuccessMessage
                createdSubscriptionUUID={createdSubscriptionUUID}
                subscriptionType={typeAndBillingData.selectedSubscriptionType}
                pricingTermsData={pricingTermsData}
                reloadPage={reloadPage}
                ownerData={ownerData}
            />
        );
    }

    const activateSubscriptionRequest = async (subscriptionUUID: string) => {
        const isSpecifiedDate = typeAndBillingData.selectedActivationDateType === ActivationDate.SPECIFIED;
        const isNowDate = typeAndBillingData.selectedActivationDateType === ActivationDate.NOW;
        const isActivationDateInPast = dayjs(typeAndBillingData.activationDate).isBefore(convertDateTimeZone(dayjs()));

        if (!isNowDate && (!isSpecifiedDate || !isActivationDateInPast || !typeAndBillingData.activationDate)) {
            return;
        }

        return activateSubscription(subscriptionUUID, buildActivationParams(typeAndBillingData))
            .then(() => {
                addMessage('Subscription has been activated', SnackbarMessageVariants.SUCCESS);
            })
            .catch(() => {
                addMessage('Failed to activate subscription', SnackbarMessageVariants.ERROR);
            });
    };

    const attachDomainsRequest = async (subscriptionUUID: string) => {
        if (typeAndBillingData.selectedSubscriptionType === SubscriptionType.INSIDER) {
            return true;
        }

        const { domains } = pricingTermsData.enterprise!;

        return attachDomains(subscriptionUUID, { domains: domains.map(({ domain }) => domain) })
            .then(() => {
                addMessage(`${domains.length} ${makePlural('domain', domains.length)} added successfully`, SnackbarMessageVariants.SUCCESS);
                return true;
            })
            .catch(() => {
                addMessage(`Failed to add ${domains.length} ${makePlural('domain', domains.length)}`, SnackbarMessageVariants.ERROR);
                return false;
            });
    };

    const setOwnerRequest = async (subscriptionUUID: string) => {
        const { fullName, uuid: ownerUUID } = ownerData;

        return setOwner(subscriptionUUID, { userUUID: ownerUUID })
            .then(() => {
                addMessage(`${fullName} has been successfully set as an owner`, SnackbarMessageVariants.SUCCESS);
                return true;
            })
            .catch(() => {
                addMessage(`Failed to set ${fullName} as an owner`, SnackbarMessageVariants.ERROR);
                return false;
            });
    };

    const addManagerRequest = async (subscriptionUUID: string) => {
        const { fullName, uuid: ownerUUID } = ownerData;

        return addManager(subscriptionUUID, { managers: [ownerUUID] })
            .then(() => {
                addMessage(`${fullName} has been successfully added as a manager`, SnackbarMessageVariants.SUCCESS);
                return true;
            })
            .catch(() => {
                addMessage(`Failed to set ${fullName} as an manager`, SnackbarMessageVariants.ERROR);
                return false;
            });
    };

    const createInitialBillingRecordRequest = async (subscriptionUUID: string) => {
        return createInitialBillingRecord(
            subscriptionUUID,
            buildCreateBillingRecordParams(typeAndBillingData, pricingTermsData),
        )
            .then(async () => {
                addMessage('Billing record has been created', SnackbarMessageVariants.SUCCESS);
                return true;
            }).catch(() => {
                addMessage('Failed to create billing record', SnackbarMessageVariants.ERROR);
                return false;
            });
    };

    const updateSubscriptionRequest = async (subscriptionUUID: string) => {
        const shouldUpdateSubscription = shouldUpdateActivationDate(typeAndBillingData)
            || shouldUpdateCustomPricingTerms(typeAndBillingData, pricingTermsData);

        if (!shouldUpdateSubscription) {
            return;
        }

        return updateSubscription(subscriptionUUID, buildUpdateSubscriptionParams(typeAndBillingData, pricingTermsData))
            .catch(() => {
                addMessage('Failed to set activation date', SnackbarMessageVariants.ERROR);
            });
    };

    const handleCreateSubscription = () => {
        setIsLoading(true);
        createSubscription(buildCreateSubscriptionParams(typeAndBillingData))
            .then(async ({ uuid }) => {
                addMessage('Draft subscription has been successfully created', SnackbarMessageVariants.SUCCESS);

                return Promise.all<boolean>([
                    createInitialBillingRecordRequest(uuid),
                    attachDomainsRequest(uuid),
                    addManagerRequest(uuid).then((added) => added && setOwnerRequest(uuid)),
                ]).then(async (results) => {
                    await updateSubscriptionRequest(uuid);

                    const allRequestsSucceeded = results.every((value) => value);
                    if (allRequestsSucceeded) {
                        await activateSubscriptionRequest(uuid);
                    }
                }).then(() => {
                    setCreatedSubscriptionUUID(uuid);
                });
            })
            .catch((error) => {
                addMessage(error.message, SnackbarMessageVariants.ERROR);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <StepContainer
            isLoading={isLoading}
            handleGoBack={handleGoBack}
            handleNextButtonClick={handleCreateSubscription}
            showNextButton
            showGoBackButton
            title="Confirmation"
            nextButtonTitle="Create"
            testId="confirmation-step"
        >
            <PageSection variant="headless" header="Subscription type and billing terms">
                <InfoBox items={getTypeAndBillingData(typeAndBillingData, expirationDate)} />
            </PageSection>
            {!isFreeInsider && (
                <PageSection variant="headless" header="Pricing terms">
                    <InfoBox
                        items={getPricingTermsData(pricingTermsData, typeAndBillingData)}
                    />
                </PageSection>
            )}
            {typeAndBillingData.selectedSubscriptionType === SubscriptionType.ENTERPRISE && (
                <PageSection variant="headless" header="Domains">
                    <Stack spacing={1.5}>
                        {pricingTermsData.enterprise!.domains.map((domain) => (
                            <DomainInfoBox key={domain.domain} domain={domain} />
                        ))}
                    </Stack>
                </PageSection>
            )}
            <PageSection variant="headless" header="Owner account">
                <UserInfoBox data={ownerData} isManager={false} />
            </PageSection>
        </StepContainer>
    );
};

export default ConfirmationStep;
