import React, { FC, useState } from 'react';
import {
    DropdownButton,
    DropdownContent,
    DropdownIcon,
    DropdownText,
    DropdownWrapper,
} from './MenuDropdown.styles';
import { HeaderMenuItem } from 'src/layouts/BasicLayout/components/Header/components/HeaderMenuItem';
import { DropdownItemType, isDropdownItem, isNotDivider } from 'src/services/header-menu-helper';
import { makeSlug } from 'src/services/formatters';

type MenuDropdownProps = {
    dropdownItem: DropdownItemType;
    testId: string;
    onItemClick: () => void;
};

const MenuDropdown: FC<MenuDropdownProps> = ({ dropdownItem, testId, onItemClick }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const childrenItems = dropdownItem.children
        ?.filter(isNotDivider)
        ?.map((subItem) => {
            const subTestId = `${testId}--${makeSlug(subItem.label)}`;
            return isDropdownItem(subItem) ? (
                <MenuDropdown key={subTestId} testId={subTestId} dropdownItem={subItem} onItemClick={onItemClick} />
            ) : (
                <HeaderMenuItem key={subTestId} testId={subTestId} item={subItem} onClick={onItemClick} />
            );
        });

    return (
        <DropdownWrapper width="100%" paddingBottom={0.5}>
            <DropdownButton
                data-testid={testId}
                paddingX={3}
                onClick={() => setIsOpen((prev) => !prev)}
            >
                <DropdownText>
                    {dropdownItem.label}
                </DropdownText>
                <DropdownIcon
                    width={12}
                    height={12}
                    rotate={isOpen ? '-90deg' : '90deg'}
                />
            </DropdownButton>
            {isOpen && (
                <DropdownContent
                    data-testid={`${testId}-dropdown`}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                >
                    {childrenItems}
                </DropdownContent>
            )}
        </DropdownWrapper>
    );
};

export default MenuDropdown;
