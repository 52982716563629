import React, { useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import PageTitle, { type PageTitleProps } from 'src/components/PageTitle';
import { REDIRECT_TIMEOUT } from 'src/constants';

type RedirectionMessageProps = {
    link?: string;
    linkNameTo?: string;
    redirectInMs?: number;
} & Omit<PageTitleProps, 'subtitle'>;

const RedirectionMessage = (props: RedirectionMessageProps) => {
    const {
        title,
        link = '/',
        linkNameTo = '',
        variant = 'form',
        redirectInMs = REDIRECT_TIMEOUT,
        textAlign = 'center',
        testId = 'redirection-message',
    } = props;
    const navigate = useNavigate();

    useEffect(() => {
        const navigateTimeout = setTimeout(() => {
            navigate(link);
        }, redirectInMs);
        return () => navigateTimeout && clearTimeout(navigateTimeout);
    }, [navigate, link, redirectInMs]);

    return (
        <PageTitle title={title} variant={variant} textAlign={textAlign} testId={testId}>
            You will be redirected {linkNameTo ? `to ${linkNameTo}` : ''} within a few seconds.<br />
            If nothing happens or you simply do not wish to wait, then <NavLink to={link}>click here</NavLink>.
        </PageTitle>
    );
};

export default RedirectionMessage;
