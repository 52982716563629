import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProfileHeaderLine } from './Profile.styles';
import { ProfileSubscriptions } from 'src/components/ProfileSubscriptions';
import { useReloadPage, useSnackbarMessage } from 'src/hooks';
import { changeEmailCheck, changeEmailSend } from 'src/services/sso-api';
import { ProfilePersonal } from 'src/components/ProfilePersonal';
import { BasicLayout } from 'src/layouts';
import { TabBar } from 'src/components/TabBar';
import { ProfileSecurity } from 'src/components/ProfileSecurity';
import ChangeEmailNotification from 'src/components/ChangeEmailNotification';
import { ProfileDelivery } from 'src/components/ProfileDelivery';
import { ActiveSubscriptionHeader } from 'src/components/ActiveSubscriptionHeader';
import { ChangeEmailCheckData } from 'src/@types/sso-api';
import { SnackbarMessageVariants } from 'src/constants';

type ProfileTab = 'personal' | 'delivery' | 'security' | 'subscriptions';

const getTabByPath = (path: string): ProfileTab => {
    switch (path) {
        case '/delivery':
            return 'delivery';
        case '/security':
            return 'security';
        case '/subscriptions':
            return 'subscriptions';
        default:
            return 'personal';
    }
};

const ProfilePage: FC = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState<ProfileTab>(getTabByPath(pathname));
    const [changeEmailCheckData, setChangeEmailCheckData] = useState<ChangeEmailCheckData>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { pageReloadCount } = useReloadPage();
    const { addMessage } = useSnackbarMessage();

    const fetchChangeEmailStatus = () => {
        changeEmailCheck().then(setChangeEmailCheckData).catch(() => null);
    };

    useEffect(() => {
        fetchChangeEmailStatus();
    }, [pageReloadCount]);

    useEffect(() => {
        const tabByPath = getTabByPath(pathname);
        if (tabByPath !== activeTab) {
            setActiveTab(tabByPath);
        }
    }, [pathname]);

    const handleResendEmail = async () => {
        if (!changeEmailCheckData?.newEmail) {
            return;
        }

        setIsLoading(true);
        await changeEmailSend({ newEmail: changeEmailCheckData.newEmail })
            .then(() => fetchChangeEmailStatus())
            .then(() => {
                addMessage('Confirmation email has been re-sent', SnackbarMessageVariants.SUCCESS);
            })
            .catch(() => {
                addMessage('Failed to re-send confirmation email', SnackbarMessageVariants.ERROR);
            })
            .finally(() => setIsLoading(false));
    };

    const tabs: Record<string, ReactElement> = {
        personal: <ProfilePersonal />,
        delivery: <ProfileDelivery />,
        security: <ProfileSecurity />,
        subscriptions: <ProfileSubscriptions />,
    };

    return (
        <BasicLayout testId="profile-page">
            <ActiveSubscriptionHeader testId="profile-active-subscription-header" profileHeader />
            <ProfileHeaderLine />
            <TabBar
                mobileProfileTab
                initialValue={activeTab}
                onChange={(value, item) => {
                    navigate(`${item?.extra?.path}`);
                }}
                items={[
                    { value: 'personal', label: 'Personal', extra: { path: '/' } },
                    { value: 'delivery', label: 'Delivery', extra: { path: '/delivery' } },
                    { value: 'security', label: 'Security', extra: { path: '/security' } },
                    { value: 'subscriptions', label: 'Subscriptions', extra: { path: '/subscriptions' } },
                ]}
                testId="profile-nav-bar"
            />
            <ChangeEmailNotification
                handleResend={handleResendEmail}
                changeEmailStatus={changeEmailCheckData}
                marginTop={{ xs: 2, sm: 2.5 }}
                isPersonalEmail
                isLoading={isLoading}
            />
            {tabs[activeTab]}
        </BasicLayout>
    );
};

export default ProfilePage;
