import React, { type ReactElement, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ForceEnableTFAPage } from 'src/pages/ForceEnableTFAPage';
import { SignUp } from 'src/pages/SignUp';
import { ForceChangePasswordPage } from 'src/pages/ForceChangePassword';
import { pageLoaded } from 'src/redux/actions/page';
import { canUserAccessPage, getAccessDeniedRedirectionLink } from 'src/services/access-handler';
import {
    clearInitiallyRequestedPage,
    configSelector,
    pageSelector,
    setInitiallyRequestedPage,
    userSelector,
} from 'src/redux/slices';
import { setPageTitle } from 'src/services/page-title';
import { ConfigState, CurrentUserState, type PageState } from 'src/@types/redux';
import type { RootState } from 'src/redux/root-reducer';

type PageHandlerProps = {
    page: ReactElement;
    title: string;
};

const PageHandler = ({
    page,
    title,
}: PageHandlerProps): ReactElement | null => {
    const { initiallyRequestedPage } = useSelector<RootState, PageState>(pageSelector);
    const currentUserState = useSelector<RootState, CurrentUserState>(userSelector);
    const { features, mainSiteUrl } = useSelector<RootState, ConfigState>(configSelector);
    const { currentUser } = currentUserState;

    const pageName = typeof page.type === 'string' ? page.type : page.type.name;
    const params = useParams<Record<string, string>>();
    const userCanAccessPage = canUserAccessPage(currentUserState, pageName, features);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!features?.accountCreate && SignUp.name === pageName) {
            window.location.href = `${mainSiteUrl}/subscribe/`;
            return;
        }

        if (userCanAccessPage) {
            dispatch(pageLoaded({ page: pageName, params, location }));

            if (title.length > 0) {
                setPageTitle(title);
            }

            return;
        }

        if (currentUser && initiallyRequestedPage) {
            navigate(initiallyRequestedPage);
            dispatch(clearInitiallyRequestedPage());

            return;
        }

        navigate(getAccessDeniedRedirectionLink(pageName));
    }, [userCanAccessPage, pageName, JSON.stringify(params), location, dispatch, navigate]);

    useEffect(() => {
        if (!currentUser && !userCanAccessPage) {
            dispatch(setInitiallyRequestedPage({
                pathname: location.pathname,
                hash: location.hash,
                search: location.search,
            }));
        }
    }, []);

    if (currentUser?.forceChangePassword) {
        return (
            <ForceChangePasswordPage />
        );
    }

    if (currentUser?.forceEnableTFA) {
        return (
            <ForceEnableTFAPage />
        );
    }

    return userCanAccessPage ? page : null;
};

export const wrapPageWithHandler = (page: ReactElement, title: string = ''): ReactElement => {
    return (
        <PageHandler
            page={page}
            title={title}
        />
    );
};

export default PageHandler;
