import React from 'react';
import { gridColumnParams } from './schemas';
import { BillingDetails, CardDetails } from 'src/@types/sso-api';
import CardType from 'src/components/CreditCard/CardType';

export const getPaymentFields = (cardDetails?: CardDetails | null, billingDetails?: BillingDetails | null) => {
    return [
        {
            title: gridColumnParams.brand.column_title,
            value: <CardType brand={cardDetails?.brand} wallet={cardDetails?.walletType} />,
            gridProps: gridColumnParams.brand,
        },
        {
            title: gridColumnParams.last4.column_title,
            value: cardDetails?.last4 ? `•••• ${cardDetails.last4}` : '',
            gridProps: gridColumnParams.last4,
        },
        {
            title: gridColumnParams.expiryDate.column_title,
            value: `${cardDetails?.expMonth?.toString().padStart(2, '0')}/${cardDetails?.expYear}`,
            gridProps: gridColumnParams.expiryDate,
        },
        {
            title: gridColumnParams.fullName.column_title,
            value: billingDetails?.name || 'Unavailable',
            gridProps: gridColumnParams.fullName,
        },
        {
            title: gridColumnParams.type.column_title,
            value: cardDetails?.funding,
            gridProps: gridColumnParams.type,
        },
    ];
};
