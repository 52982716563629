import styled from 'styled-components';
import { Box, List, Typography } from '@mui/material';

export const TabsContainer = styled(Box)`
  background-color: ${({ theme }) => theme.palette.mainBackgroundColor};
    
  & .MuiTabs-root {
    min-height: unset;
    height: 40px;
    align-items: center;
      
    & .MuiTabs-scroller {
      & .MuiTabs-flexContainer {
        justify-content: space-between;
              
        & .MuiTab-root {
          font-size: 16px;
          min-width: 50%;
        }
      }
    }
  }
    
  @media(max-width: ${({ theme }) => theme.brakePoints.sm}){
    max-width: unset;
    border-radius: 4px;
      
    & .MuiTabs-root {
      height: 32px;
      min-height: unset;
        
      & .MuiTabs-scroller {
        & .MuiTabs-flexContainer {
          & .MuiTab-root {
            padding: 7px 16px;
            min-height: unset;
            font-size: 14px;
            width: 50%;
            min-width: unset;
          }
        }
      }
    }
  }
`;

export const StyledTitle = styled(Typography)`
  && {
    max-width: 460px;
    padding-bottom: 4px;
    font-family: ${({ theme }) => theme.fonts.bold};
    color: ${({ theme }) => theme.palette.black};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
      
    @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
      font-size: 14px;
      padding-bottom: 0;
    }
  }
`;

export const StyledList = styled(List)`
  && {
    padding:0;
        
    .MuiListItem-root{
      padding: 16px 0 0 0;
            
      .MuiTypography-root{
        font-family: ${({ theme }) => theme.fonts.medium};
        color: ${({ theme }) => theme.palette.lightBlack};
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
          
        @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
          font-size: 14px;
        }
      }

      @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
        padding: 20px 0 0 0;
      }
    }
  }
`;
