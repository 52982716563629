import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Stack, StackProps } from '@mui/material';
import { StyledSubscriptionType } from './ActiveSubscriptionHeader.styles';
import { type NavButtonVariant } from 'src/components/NavButton/NavButton';
import { type PageTitleVariant, StyledPageTitle } from 'src/components/PageTitle/styles';
import { NavButton } from 'src/components/NavButton';
import { CurrentUserState, SubscriberSelectorModel } from 'src/@types/redux';
import type { RootState } from 'src/redux/root-reducer';
import { subscriberSelector, userSelector } from 'src/redux/slices';

type ActiveSubscriptionHeaderProps = {
    profileHeader?: boolean;
    testId?: string;
};

type ActiveSubscriptionHeaderConfig = {
    wrapperProps: Partial<StackProps>;
    titleDataVariant: PageTitleVariant;
    titleAdditionalText: string;
    stackProps: Partial<StackProps>;
    navButtonDataVariant: NavButtonVariant;
};

const defaultConfig: ActiveSubscriptionHeaderConfig = {
    wrapperProps: {
        direction: { md: 'column', xs: 'column' },
        alignItems: 'flex-start',
    },
    titleDataVariant: 'mobile-menu',
    titleAdditionalText: '',
    stackProps: {
        mb: 0,
        mt: { xs: 0.5 },
        justifyContent: 'flex-start',
    },
    navButtonDataVariant: 'mobileMenuHeader',
};

const profileHeaderConfig: ActiveSubscriptionHeaderConfig = {
    wrapperProps: {
        direction: { md: 'row', xs: 'column' },
        alignItems: { md: 'center', xs: 'flex-start' },
        mb: { md: 3, xs: 2 },
    },
    titleDataVariant: 'page',
    titleAdditionalText: 'Hello ',
    stackProps: {
        mt: { xs: 1 },
        justifyContent: { md: 'space-between', xs: 'flex-start' },
    },
    navButtonDataVariant: 'desktopMenuHeader',
};

const ActiveSubscriptionHeader: FC<ActiveSubscriptionHeaderProps> = ({ profileHeader, testId = 'active-subscription-header' }) => {
    const { currentUser } = useSelector<RootState, CurrentUserState>(userSelector);
    const { subscription } = useSelector<RootState, SubscriberSelectorModel>(subscriberSelector);
    const config = profileHeader ? profileHeaderConfig : defaultConfig;

    if (!currentUser) {
        return <></>;
    }

    return (
        <Stack
            justifyContent="space-between"
            data-testid={testId}
            { ...config.wrapperProps }
        >
            <StyledPageTitle
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
                data-variant={config.titleDataVariant}
            >
                {`${config.titleAdditionalText}${currentUser.fullName}`}
            </StyledPageTitle>

            { subscription?.type && (
                <Stack
                    direction="row"
                    justifyContent={{ md: 'space-between', xs: 'flex-start' }}
                    alignItems="center"
                    gap={2}
                    { ...config.stackProps }
                >
                    <StyledSubscriptionType
                        data-testid={`${testId}-type`}
                    >
                        {subscription.type.toUpperCase()}
                    </StyledSubscriptionType>
                    {subscription.isManager && (
                        <NavButton
                            to={`/subscriptions/${subscription.subscriptionUUID}`}
                            data-testid={`${testId}-button`}
                            data-variant={config.navButtonDataVariant}
                        >
                                Manage
                        </NavButton>
                    )}
                </Stack>
            )}
        </Stack>
    );
};

export default ActiveSubscriptionHeader;
