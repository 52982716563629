import styled from 'styled-components';
import { Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { PageVariant } from 'src/layouts/BasicLayout/BasicLayout';
import { theme as mainTheme } from 'src/theme';

export type GetContentMaxWidthProps = { 'data-page-variant': PageVariant, theme: typeof mainTheme };
type ContentProps = {
    'data-page-variant': PageVariant;
    theme: typeof mainTheme;
    calculateMaxWidthFn?: (props: GetContentMaxWidthProps) => string;
};

const getContentMaxWidth = ({ calculateMaxWidthFn, ...props }: ContentProps) => (
    calculateMaxWidthFn?.(props) ?? `max-width: ${props.theme.sizes.headerDefaultMaxWidth}; width: 100%;`
);

export const StyledEnable2FABannerContainer = styled(Stack)`
    background: ${({ theme }) => theme.palette.white};
`;

export const StyledEnable2FABannerContent = styled(Stack)<ContentProps>`
    ${getContentMaxWidth};
`;

export const IconContainer = styled(Stack)`
    background-color: rgba(222, 82, 71, 0.1);
`;

export const StyledTitle = styled(Typography)`
    && {
        font-family: ${({ theme }) => theme.fonts.bold};
        color: ${({ theme }) => theme.palette.black};
        font-size: 14px;

        @media (min-width: ${({ theme }) => theme.brakePoints.sm}) {
            font-size: 16px;
        }
    }
`;

export const StyledSubTitle = styled(Typography)`
    && {
        color: ${({ theme }) => theme.palette.lightBlack};
        font-size: 14px;

        @media (min-width: ${({ theme }) => theme.brakePoints.sm}) {
            font-size: 16px;
        }

        b {
            font-family: ${({ theme }) => theme.fonts.bold};
            color: ${({ theme }) => theme.palette.black};
        }
    }
`;

export const StyledCloseIcon = styled(CloseIcon)`
    && {
        height: 100%;
        color: ${({ theme }) => theme.palette.lightBlack};

        &:hover {
            color: ${({ theme }) => theme.palette.black};
        }
    }
`;
