import styled from 'styled-components';
import { Box, Typography } from '@mui/material';

export const CurrentTitle = styled(Typography)`
    && {
        line-height: normal;
        color: ${({ theme }) => theme.palette.grey};
        font-family: ${({ theme }) => theme.fonts.medium};
        font-size: 14px;
    }
`;

export const CurrentWrapper = styled(Box)`
    border: 1px solid ${({ theme }) => theme.palette.borderGrey};
    border-radius: 4px;
`;

export const CurrentName = styled(Typography)`
    && {
        line-height: normal;
        color: ${({ theme }) => theme.palette.black};
        font-family: ${({ theme }) => theme.fonts.demiBold};
        font-size: 16px;
    }
`;

export const CurrentEmail = styled(Typography)`
    && {
        line-height: normal;
        color: ${({ theme }) => theme.palette.lightBlack};
        font-family: ${({ theme }) => theme.fonts.normal};
        font-size: 14px;
    }
`;
