import type { ManageActionType } from 'src/components/ManageActions/ManageActions';
import { ManageProfileSubscriptionActions, SubscriptionAction } from 'src/constants';

export const getManageSubscriptionActionsList = (
    isActionAllowed: (actionKey: SubscriptionAction) => boolean,
): Partial<Record<ManageProfileSubscriptionActions, ManageActionType>> => {

    return {
        ...(isActionAllowed(SubscriptionAction.CANCEL) && { [ManageProfileSubscriptionActions.cancelSubscription]: { label: 'Cancel subscription' } }),
        ...(isActionAllowed(SubscriptionAction.RESTORE) && { [ManageProfileSubscriptionActions.restoreSubscription]: { label: 'Restore subscription' } }),
    };
};
