import React, { FC } from 'react';
import { makeManageEntityURL } from 'src/services/url-maker';
import { ActionButton, ActionButtonVariant } from 'src/components/Buttons/ActionButton';
import { LinkedCell } from 'src/components/DataGrid/components/LinkedCell';
import { EMPTY_CELL_VALUE } from 'src/services/grid-formatters';
import { ReactComponent as LinkIcon } from 'src/assets/icons/payment-details-icon.svg';

export type ManageEntityLinkProps = {
    id: string;
    type: 'subscription' | 'user' | 'credentials';
};

export const ManageEntityLink: FC<ManageEntityLinkProps> = ({ id, type }) => {
    if (id === EMPTY_CELL_VALUE) {
        return <span>{EMPTY_CELL_VALUE}</span>;
    }

    const href = makeManageEntityURL(type, id);

    return (
        <LinkedCell
            href={href}
            paddingRight={0}
            paddingLeft={0}
            tooltipText={`Open ${type} page`}
            iconWidth="24px"
            justifyContent="center"
            icon={
                <ActionButton
                    tabIndex={-1}
                    data-testid="details-button"
                    data-attribute-to-change="stroke"
                    data-variant={ActionButtonVariant.BLUE}
                >
                    <LinkIcon />
                </ActionButton>
            }
        />
    );
};
