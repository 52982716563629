import styled from 'styled-components';
import { Box } from '@mui/material';
import { StyledIcon } from 'src/components/CopyIcon/CopyIcon.styles';

export const StyledBox = styled(Box)`
    display: flex;
    align-items: center;
    width: 100%;
    
    &:hover ${StyledIcon} {
        opacity: 1;
    }
`;
