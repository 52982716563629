import { number, object, ObjectShape, string } from 'yup';
import {
    EMPTY_PASSWORD_VALIDATION_ERROR,
    FORBIDDEN_TLD_ERROR_MESSAGE,
    INPUT_MAX_LENGTH,
    INVALID_COMPANY_NAME_MESSAGE,
    INVALID_DOMAIN_NAME_MESSAGE,
    INVALID_EMAIL_VALIDATION_ERROR,
    INVALID_FULL_NAME_MESSAGE,
    INVALID_JOB_TITLE_MESSAGE,
    INVALID_NUMBER_OF_EMPLOYEES_MESSAGE,
    INVALID_NUMBER_OF_REQUESTED_USER_MESSAGE,
    INVALID_NUMBER_TYPE_MESSAGE,
    INVALID_PHONE_NUMBER_VALIDATION_ERROR,
    INVALID_SALESFORCE_CONTRACT_LINK,
    MAX_FULL_NAME_LENGTH,
    MAX_LENGTH_IS_REACHED_MESSAGE,
    MAX_NUMBER_OF_REQUESTED_USER_MESSAGE,
    MAX_PRICE_IN_CENTS_MESSAGE,
    MIN_PASSWORD_LENGTH,
    NO_LOVER_CASE_CHARACTER_PASSWORD_MESSAGE,
    NO_NUMBER_CHARACTER_PASSWORD_MESSAGE,
    NO_SYMBOL_CHARACTER_PASSWORD_MESSAGE,
    NO_UPPER_CASE_CHARACTER_PASSWORD_MESSAGE,
    NOT_LINKEDIN_URL_MESSAGE,
    NOT_TWITTER_URL_MESSAGE,
    NOT_VALID_URL_MESSAGE,
    TFA_CODE_LENGTH,
    TO_SHORT_PASSWORD_MESSAGE,
    URL_MUST_START_WITH_MESSAGE,
} from 'src/constants';

export const validateSchemaObject = (schema: ObjectShape) => object().shape(schema);

export const oneLoverCaseCharacterRegExp = /[a-z]/;
export const oneUpperCaseCharacterRegExp = /[A-Z]/;
export const oneNumberCharacterRegExp = /\d/;
export const oneSymbolCharacterRegExp = /\W|_/;
export const emailRegExp = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z]{2,}$/i;
export const numberDividerRegExp = /\D(?!\d*$)|,(?=\d{3}$)/g;
export const phoneNumberRegExp = /^\+[1-9]\d{9,14}$/;
const domainRegExp = /^(?=.{1,253}$)(?:(?!\d+$)[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z]{2,}$/i;
const websiteDomainRegExp = /^http(s)?:\/\/(www\.)?(?=.{1,253}$)(?:(?!\d+$)[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z]{2,}.*(?<!\.)$/i;
const contractLinkRegExp = /^https:\/\/ftcommercial\.my\.salesforce-sites\.com\/OrderConfirmation\/contract_acceptance_page\?((id=.+&key=.+)|(key=.+&id=.+))$/i;
const minLengthRegExp = new RegExp(`.{${MIN_PASSWORD_LENGTH},}`);
const forbiddenTLDRegExp = /(\.ru|\.xn--p1ai)$/i;
const fullNameRegExp = /^[a-zA-Z'"\-., ]+$/;
const jobTitleRegExp = /^[a-zA-Z0-9 '"\-.,()&:;#/]+$/;
const companyNameRegExp = /^[a-zA-Z0-9 '"\-.,()&:;#_\/+*!]+$/;

export const numberOfEmployeesValidator = number()
    .typeError(INVALID_NUMBER_TYPE_MESSAGE)
    .positive(INVALID_NUMBER_OF_EMPLOYEES_MESSAGE)
    .integer(INVALID_NUMBER_OF_EMPLOYEES_MESSAGE)
    .min(1, INVALID_NUMBER_OF_EMPLOYEES_MESSAGE);
export const numberOfRequestedUserValidator = number()
    .typeError(INVALID_NUMBER_TYPE_MESSAGE)
    .positive(INVALID_NUMBER_OF_REQUESTED_USER_MESSAGE)
    .integer(INVALID_NUMBER_OF_REQUESTED_USER_MESSAGE)
    .min(1, INVALID_NUMBER_OF_REQUESTED_USER_MESSAGE)
    .max(4294967295, MAX_NUMBER_OF_REQUESTED_USER_MESSAGE);
export const numberOfPriceValidator = number()
    .typeError(INVALID_NUMBER_TYPE_MESSAGE)
    .max(4294967295, MAX_PRICE_IN_CENTS_MESSAGE);

export const emailValidator = string().trim().matches(emailRegExp, INVALID_EMAIL_VALIDATION_ERROR)
    .test('is-forbidden-tld', FORBIDDEN_TLD_ERROR_MESSAGE, (value) => {
        return value ? !forbiddenTLDRegExp.test(value) : true;
    });
export const passwordValidator = string().required(EMPTY_PASSWORD_VALIDATION_ERROR);
export const strictPasswordValidator = passwordValidator
    .min(MIN_PASSWORD_LENGTH, TO_SHORT_PASSWORD_MESSAGE)
    .matches(oneLoverCaseCharacterRegExp, NO_LOVER_CASE_CHARACTER_PASSWORD_MESSAGE)
    .matches(oneUpperCaseCharacterRegExp, NO_UPPER_CASE_CHARACTER_PASSWORD_MESSAGE)
    .matches(oneNumberCharacterRegExp, NO_NUMBER_CHARACTER_PASSWORD_MESSAGE)
    .matches(oneSymbolCharacterRegExp, NO_SYMBOL_CHARACTER_PASSWORD_MESSAGE);

export const ACTIVE_VALIDATORS = [{
    label: 'Lower case',
    regExp: oneLoverCaseCharacterRegExp,
    key: 'loverCase',
}, {
    label: 'Upper case',
    regExp: oneUpperCaseCharacterRegExp,
    key: 'upperCase',

}, {
    label: 'Number',
    regExp: oneNumberCharacterRegExp,
    key: 'number',
}, {
    label: 'Min. 10 characters',
    regExp: minLengthRegExp,
    key: 'minLength',

}, {
    label: 'Symbol',
    regExp: oneSymbolCharacterRegExp,
    key: 'symbol',
}];

export const textInputValidator = (length = INPUT_MAX_LENGTH) =>
    string().trim().max(length, MAX_LENGTH_IS_REACHED_MESSAGE);

export const industryIdValidator = number().positive();
export const jobFunctionIdValidator = number().positive();
export const fullNameValidator = string()
    .trim()
    .matches(fullNameRegExp, INVALID_FULL_NAME_MESSAGE)
    .max(MAX_FULL_NAME_LENGTH, MAX_LENGTH_IS_REACHED_MESSAGE);

export const countryValidator = textInputValidator();
export const jobTitleValidator = string()
    .trim()
    .matches(jobTitleRegExp, INVALID_JOB_TITLE_MESSAGE)
    .max(INPUT_MAX_LENGTH, MAX_LENGTH_IS_REACHED_MESSAGE);
export const companyNameValidator = string()
    .trim()
    .matches(companyNameRegExp, INVALID_COMPANY_NAME_MESSAGE)
    .max(INPUT_MAX_LENGTH, MAX_LENGTH_IS_REACHED_MESSAGE);

export const socialXValidator = textInputValidator()
    .matches(/https/, `${URL_MUST_START_WITH_MESSAGE} https://`)
    .matches(/^https:\/\/(twitter|x)\.com\/.+/, NOT_TWITTER_URL_MESSAGE);
export const socialLinkedInValidator = textInputValidator()
    .matches(/https/, `${URL_MUST_START_WITH_MESSAGE} https://`)
    .matches(/^https:\/\/www.linkedin.com\/in\/.+/, NOT_LINKEDIN_URL_MESSAGE);
export const websiteValidator = textInputValidator()
    .matches(/https?/, `${URL_MUST_START_WITH_MESSAGE} http:// or https://`)
    .matches(websiteDomainRegExp, NOT_VALID_URL_MESSAGE)
    .test('is-forbidden-tld', FORBIDDEN_TLD_ERROR_MESSAGE, (value) => {
        return value ? !forbiddenTLDRegExp.test(value) : true;
    });

export const domainValidator = textInputValidator().matches(domainRegExp, INVALID_DOMAIN_NAME_MESSAGE);
export const phoneNumberValidator = string().trim().matches(phoneNumberRegExp, INVALID_PHONE_NUMBER_VALIDATION_ERROR);
export const phoneNumberConfirmCodeValidator = string().trim().min(4).max(10);
export const contractLinkValidator = string()
    .trim()
    .url(INVALID_SALESFORCE_CONTRACT_LINK)
    .matches(contractLinkRegExp, INVALID_SALESFORCE_CONTRACT_LINK);

export const tfaCodeValidate = (code: string): boolean => (
    new RegExp(`^\\d{${TFA_CODE_LENGTH}}$`).test(code)
);
