import React, { FC, useLayoutEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import {
    DropdownContent,
    DropdownWrapper,
    NestedDropdownContainer,
    NestedDropdownNavButton,
} from 'src/layouts/BasicLayout/components/Header/components/DesktopMenu/DesktopMenu.styles';
import { HeaderMenuItem } from 'src/layouts/BasicLayout/components/Header/components/HeaderMenuItem';
import { DropdownItemType, isLinkItemType } from 'src/services/header-menu-helper';
import { ReactComponent as DropDownIcon } from 'src/assets/icons/expand-icon.svg';
import { PageVariant } from 'src/layouts/BasicLayout/BasicLayout';
import { makeSlug } from 'src/services/formatters';

type DesktopSubMenuProps = {
    menuItem: DropdownItemType;
    testId: string;
    pageVariant?: PageVariant;
};

const DesktopSubMenu: FC<DesktopSubMenuProps> = ({ menuItem, testId }) => {
    const [position, setPosition] = useState<'left' | 'right'>('left');
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const containerRef = useRef<HTMLDivElement>(null);

    const childrenItems = menuItem.children
        .filter(isLinkItemType)
        .map((subItem) => {
            const subItemTestId = `${testId}--${makeSlug(subItem.label)}`;
            return <HeaderMenuItem key={subItemTestId} testId={subItemTestId} item={subItem} />;
        });

    const makeDropdownPosition = () => {
        if (containerRef.current) {
            const { right, left } = containerRef.current.getBoundingClientRect();

            setPosition(window.innerWidth - right < (right - left) + 40 ? 'left' : 'right');
        }
    };

    useLayoutEffect(() => {
        window.addEventListener('resize', makeDropdownPosition);
        makeDropdownPosition();
        return () => window.removeEventListener('resize', makeDropdownPosition);
    }, []);

    return (
        <NestedDropdownContainer
            key={testId}
            data-testid={`${testId}-container`}
            data-position={position}
            ref={containerRef}
            onMouseEnter={() => {
                makeDropdownPosition();
                setIsOpen(true);
            }}
            onMouseLeave={() => {
                setIsOpen(false);
            }}
            onFocus={() => {
                setIsOpen(true);
            }}
        >
            <Box display="flex" alignItems="center" height="100%" paddingX={2.5} data-testid={testId}>
                <NestedDropdownNavButton tabIndex={0} as="div">
                    {menuItem.label}
                    <DropDownIcon height={12} width={12} />
                </NestedDropdownNavButton>
            </Box>
            {isOpen && (
                <DropdownWrapper data-testid={`${testId}-dropdown`}>
                    <DropdownContent minWidth={menuItem.label ? 200 : 250} pb={0.5} pt={0.5}>
                        {childrenItems}
                    </DropdownContent>
                </DropdownWrapper>
            )}
        </NestedDropdownContainer>
    );
};

export default DesktopSubMenu;
