import React from 'react';
import { useSelector } from 'react-redux';
import { Stack } from '@mui/material';
import { Feature, useIsFeatureEnabled } from 'src/hooks/useIsFeatureEnabled';
import { getSubscriberType } from 'src/services/subscription-service-getters';
import { configSelector, userSelector } from 'src/redux/slices';
import { SubscriberType } from 'src/constants';
import { SingleModalLayout } from 'src/layouts';
import PageTitle from 'src/components/PageTitle';
import { Button, TextButton } from 'src/components/Buttons';
import { ConfigState, CurrentUserState } from 'src/@types/redux';
import type { RootState } from 'src/redux/root-reducer';
import { SignUpSuccessSubTitle } from 'src/pages/SignUpSuccess/SignUpSuccess.styles';

const SignUpSuccessPage = () => {
    const { mainSiteUrl } = useSelector<RootState, ConfigState>(configSelector);
    const { signUpData } = useSelector<RootState, CurrentUserState>(userSelector);

    const featureAccountUpgrade = useIsFeatureEnabled(Feature.accountUpgrade);

    const type = signUpData?.subscriberTypes && getSubscriberType(signUpData.subscriberTypes);
    const isEnterpriseUser = type === SubscriberType.enterprise;

    return (
        <SingleModalLayout>
            <>
                <PageTitle title="Thanks for subscribing." variant="form" testId="create-account-succes-page" />
                <SignUpSuccessSubTitle marginTop={{ xs: 0.5, sm: 1 }}>
                    {isEnterpriseUser ? (
                        'Great news! You now have unlimited access to Endpoints News through your company\'s Enterprise subscription.'
                    ) : (
                        'As a Basic subscriber you\'ll get our newsletters and can read several free articles a month.'
                    )}
                </SignUpSuccessSubTitle>
                {!isEnterpriseUser && featureAccountUpgrade && (
                    <SignUpSuccessSubTitle marginTop={2}>
                        To get unlimited access to Endpoints journalism you can upgrade&nbsp;
                        <TextButton href="/upgrade">here</TextButton>.
                    </SignUpSuccessSubTitle>
                )}
                <SignUpSuccessSubTitle marginTop={2}>
                    You can update your preferences anytime by visiting your&nbsp;
                    <TextButton href="/">reader profile</TextButton>.
                </SignUpSuccessSubTitle>
                <Stack spacing={{ xs: 2, sm: 2.5 }} marginTop={3}>
                    <Button fullWidth href={mainSiteUrl}>Latest news</Button>
                    {!isEnterpriseUser && featureAccountUpgrade && (
                        <Button fullWidth variant="secondary" href="/upgrade">
                            Upgrade to get unlimited access
                        </Button>
                    )}
                </Stack>
            </>
        </SingleModalLayout>
    );
};

export default SignUpSuccessPage;
