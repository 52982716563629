import React from 'react';
import { gridColumnParams } from './schemas';
import { RoundedBadge } from 'src/components/Badge';
import { Status } from 'src/constants';
import { RoundedBadgeProps } from 'src/components/Badge/RoundedBadge/RoundedBadge';
import { formatDate } from 'src/services/formatters';
import type { Credentials } from 'src/@types/credentials';
import { RoundedBadgeVariant } from 'src/components/Badge/RoundedBadge/RoundedBadge.styles';

const roundedBadgePropsMapping: Record<Status, RoundedBadgeProps> = {
    [Status.ACTIVE]: {
        variant: RoundedBadgeVariant.GREEN,
        label: 'Active',
    },
    [Status.INACTIVE]: {
        label: 'Inactive',
    },
};

export const getCredentialsDetailsFields = (credentials: Credentials) => {
    return [
        {
            title: gridColumnParams.id.column_title,
            value: credentials.id,
            gridProps: gridColumnParams.id,
        },
        {
            title: gridColumnParams.status.column_title,
            value: <RoundedBadge {...roundedBadgePropsMapping[credentials.status]} />,
            gridProps: gridColumnParams.status,
        },
        {
            title: gridColumnParams.tfaEnabled.column_title,
            value: credentials.tfaEnabled ? 'Yes' : 'No',
            gridProps: gridColumnParams.tfaEnabled,
        },
        {
            title: gridColumnParams.createdAt.column_title,
            value: formatDate(credentials.createdAt),
            gridProps: gridColumnParams.createdAt,
        },
        {
            title: gridColumnParams.updatedAt.column_title,
            value: formatDate(credentials.updatedAt),
            gridProps: gridColumnParams.updatedAt,
        },
    ];
};
