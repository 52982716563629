import React, { FC } from 'react';
import { TFA_CODE_LENGTH } from 'src/constants';
import { TextInput } from 'src/components/Inputs/TextInput';

type TFACodeInputProps = {
    disabled: boolean;
    codeInput: string;
    setCodeInput: (code: string) => void;
    isValidCode: boolean;
    enableTwoFactorAuth: () => void;
};

const TFACodeInput: FC<TFACodeInputProps> = ({
    disabled,
    enableTwoFactorAuth,
    isValidCode,
    codeInput,
    setCodeInput,
}) => {
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const filteredValue = e.target.value.replace(/[^\d]/g, '');
        setCodeInput(filteredValue);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && isValidCode && !disabled) {
            enableTwoFactorAuth();
        }
    };

    return (
        <TextInput
            placeholder="Enter app generated code"
            value={codeInput}
            onChange={handleInputChange}
            disabled={disabled}
            onKeyDown={handleKeyDown}
            inputProps={{
                'data-testid': 'code-input',
                maxLength: TFA_CODE_LENGTH,
            }}
        />
    );
};

export default TFACodeInput;
