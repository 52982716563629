import React from 'react';
import { ReactComponent as VisaLogo } from 'src/assets/icons/cards/visa.svg';
import { ReactComponent as AmexLogo } from 'src/assets/icons/cards/amex.svg';
import { ReactComponent as DinersLogo } from 'src/assets/icons/cards/diners.svg';
import { ReactComponent as DiscoverLogo } from 'src/assets/icons/cards/discover.svg';
import { ReactComponent as EftposLogo } from 'src/assets/icons/cards/eftpos.svg';
import { ReactComponent as JcbLogo } from 'src/assets/icons/cards/jcb.svg';
import { ReactComponent as MastercardLogo } from 'src/assets/icons/cards/mastercard.svg';
import { ReactComponent as UnionPayLogo } from 'src/assets/icons/cards/union-pay.svg';

const CardType = {
    Visa: 'visa',
    Amex: 'amex',
    Diners: 'diners',
    Discover: 'discover',
    Eftpos: 'eftpos_au',
    Jcb: 'jcb',
    Mastercard: 'mastercard',
    UnionPay: 'unionpay',
};

interface CardIconProps {
    brand?: string;
}

const CardIcon: React.FC<CardIconProps> = ({ brand }) => {
    switch (brand) {
        case CardType.Visa:
            return <VisaLogo />;
        case CardType.Amex:
            return <AmexLogo />;
        case CardType.Diners:
            return <DinersLogo />;
        case CardType.Discover:
            return <DiscoverLogo />;
        case CardType.Eftpos:
            return <EftposLogo />;
        case CardType.Jcb:
            return <JcbLogo />;
        case CardType.Mastercard:
            return <MastercardLogo />;
        case CardType.UnionPay:
            return <UnionPayLogo />;
        default:
            return null;
    }
};

export default CardIcon;
