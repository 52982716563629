import React, { FC, Fragment, useState } from 'react';
import { Box, Stack } from '@mui/material';
import {
    ButtonContainer,
    StyledChangedNewsletter,
    StyledNewslettersDetails,
} from './EditNewsLetterDeliveryModal.styles';
import { getInitialValues, getNewslettersDifference } from './services/getters';
import type { NewsletterSchemaParams, NewsLetterSetSchema } from 'src/@types/newsletters-set';
import { getActiveNewsletters, NewsletterDifference } from 'src/services/udb-delivery-preferences';
import { sortByNumericIdWithPriorityChanel } from 'src/services/sorter';
import { updateUser } from 'src/services/unified-db-api';
import Line from 'src/components/Line';
import { BaseModal } from 'src/components/Modals/BaseModal';
import { ConfirmationModal } from 'src/components/Modals/ConfirmationModal';
import { SnackbarMessageVariants, UDBNewsletterChannel } from 'src/constants';
import { useGeneralModal, useIsSubmitDisabled, useReloadPage, useSnackbarMessage } from 'src/hooks';
import { FormWrapper } from 'src/components/Forms/components';
import { ProfileEmailDeliveryVertical } from 'src/@types/sso-api';
import { NewslettersSet } from 'src/components/NewslettersSet';
import { Button } from 'src/components/Buttons';

export type EditNewsLetterDeliveryModalProps = {
    vertical: ProfileEmailDeliveryVertical;
    userNewsletters: Record<UDBNewsletterChannel, number[]>;
    hasPhoneNumber: boolean;
    userUUID: string;
};

const newsLetterSchema: NewsletterSchemaParams = {
    subtitle: undefined,
};

const newsLetterSetSchema: NewsLetterSetSchema = {
    biopharma: newsLetterSchema,
    healthcare: newsLetterSchema,
    general_communications: newsLetterSchema,
    pageColumnParams: {
        newsletters: {
            display_title: 'block',
            column_title: 'Product',
            md: 4,
        },
        sampleIssue: {
            display: 'none',
        },
        date: {
            display: 'none',
        },
    },
};

const EditNewsLetterDeliveryModal: FC<EditNewsLetterDeliveryModalProps> = ({
    vertical,
    hasPhoneNumber,
    userUUID,
    userNewsletters,
}) => {
    const [difference, setDifference] = useState<NewsletterDifference[]>([]);
    const [cachedValues, setCachedValues] = useState<Record<string, boolean> | null>(null);

    const initialValues = getInitialValues(vertical);
    const { isSubmitDisabled, onFormChangeHandler } = useIsSubmitDisabled(initialValues);
    const { addMessage } = useSnackbarMessage();
    const { closeModal } = useGeneralModal();
    const { reloadPage } = useReloadPage();

    const onSubmit = (formValues: Record<string, boolean>) => {
        const newslettersChanges = getNewslettersDifference(formValues, initialValues, vertical)
            .sort(sortByNumericIdWithPriorityChanel(UDBNewsletterChannel.EMAIL));

        setCachedValues(formValues);
        setDifference(newslettersChanges);
    };

    const handleConfirm = () => {
        const newsletters = getActiveNewsletters(userNewsletters, difference);

        updateUser(userUUID, { newsletters })
            .then(() => {
                reloadPage();
            })
            .catch(() => {
                setDifference([]);
                addMessage('Failed to update newsletter subscriptions', SnackbarMessageVariants.WARNING);
            });
    };

    const handleGoBack = () => setDifference([]);

    if (difference.length) {
        return (
            <BaseModal
                open
                step={1}
                title="Confirm changes"
                handleClose={closeModal}
                handleGoBack={handleGoBack}
                dataTestId="edit-newsletters-confirmation-modal"
            >
                <ConfirmationModal
                    modalId=""
                    subtitle={(
                        <Stack spacing={{ xs: 1.5, sm: 2 }}>
                            {difference.map(({ name, chanel, value }, index) => (
                                <Fragment key={`${name}_${chanel}`}>
                                    {index !== 0 && <Line />}
                                    <StyledChangedNewsletter variant="caption" display="inline">
                                        {value ? 'Subscribed to ' : 'Unsubscribed from '}
                                        <StyledNewslettersDetails display="inline">{name}</StyledNewslettersDetails> via&nbsp;
                                        <StyledNewslettersDetails display="inline">{chanel}</StyledNewslettersDetails>
                                    </StyledChangedNewsletter>
                                </Fragment>
                            ))}
                        </Stack>
                    )}
                    confirmButtonText="Confirm"
                    mobileDirection="row"
                    onConfirm={handleConfirm}
                    onCancel={handleGoBack}
                />
            </BaseModal>
        );
    }

    return (
        <Box minWidth={{ xs: 0, sm: '600px', md: '750px' }}>
            <FormWrapper
                testId="edit-newsletters-form"
                onFormChange={onFormChangeHandler}
                initialValues={cachedValues ?? initialValues}
                onSubmit={onSubmit}
            >
                <NewslettersSet
                    subscribeAll
                    productNameOnly
                    hasScrollOverlay
                    vertical={vertical}
                    onFormChangeHandler={onFormChangeHandler}
                    newsLetterSetSchema={newsLetterSetSchema}
                    hasPhoneNumber={hasPhoneNumber}
                    paddingBottom={0.25}
                />
                <ButtonContainer
                    position="sticky"
                    bottom="1px"
                    direction="row"
                    spacing={{ xs: 2, sm: 2.5 }}
                    paddingX={{ xs: 2, sm: 4.5 }}
                    paddingTop={3}
                    paddingBottom={{ xs: 3, sm: 4.5 }}
                >
                    <Button fullWidth variant="secondary" onClick={closeModal} minSize="small">
                        Cancel
                    </Button>
                    <Button fullWidth disabled={isSubmitDisabled} type="submit" minSize="small">
                        Save
                    </Button>
                </ButtonContainer>
            </FormWrapper>
        </Box>
    );
};

export default EditNewsLetterDeliveryModal;
