import React, { FC, Fragment, useEffect, useState } from 'react';
import { type BoxProps, type InputProps } from '@mui/material';
import { ErrorMessage, Field, type FieldProps, useFormikContext } from 'formik';
import { StringSchema } from 'yup';
import { toUpperCaseWords } from 'src/services/formatters';
import {
    InputWithRevertButtonWrapper,
} from 'src/components/Inputs/components/InputRevertButton/InputRevertButton.styles';
import { InputRevertButton } from 'src/components/Inputs/components/InputRevertButton';
import { TextInput } from 'src/components/Inputs/TextInput';
import { FormikValuesType } from 'src/@types/forms';
import { StyledErrorMessage, StyledFieldContainer } from 'src/components/Inputs/StyledInput.styles';
import { Label } from 'src/components/Label';
import { ActiveValidators } from 'src/components/Inputs/components';
import { INPUT_MAX_LENGTH } from 'src/constants';

type LabeledInputProps = {
    name: string;
    type?: string;
    placeholder?: string;
    label?: string;
    inputProps?: Partial<InputProps>;
    testId?: string;
    labelIcon?: JSX.Element;
    disabled?: boolean;
    required?: boolean;
    hasPasswordActiveValidator?: boolean;
    hasSubmittingError?: boolean;
    disallowSpace?: boolean;
    inputMaxLength?: number;
    inputMinValue?: number;
    multiline?: boolean;
    rows?: number;
    hasRevertButton?: boolean;
    endAdornment?: JSX.Element;
    onRevert?: () => void;
    handleFocus?: () => void;
    handleBlur?: () => void;
    validator?: StringSchema;
    isRevertBtnVisible?: boolean;
    errorPosition?: 'relative' | 'absolute';
} & BoxProps;

const LabeledInput: FC<LabeledInputProps> = ({
    multiline,
    rows,
    required,
    name,
    type = 'text',
    label,
    placeholder,
    inputProps,
    testId,
    labelIcon,
    disabled,
    hasPasswordActiveValidator,
    hasSubmittingError,
    disallowSpace = false,
    inputMinValue,
    hasRevertButton = false,
    endAdornment,
    inputMaxLength = INPUT_MAX_LENGTH,
    handleFocus,
    handleBlur,
    onRevert,
    validator,
    isRevertBtnVisible = false,
    errorPosition = 'absolute',
    ...BoxProps
}) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [hasValidationError, setHasValidationError] = useState<boolean>();

    const { values, setFieldValue } = useFormikContext<FormikValuesType>();

    useEffect(() => {
        validator?.validate(values[name])
            .then((data) => {
                setHasValidationError(!data);
            })
            .catch((error) => {
                setHasValidationError(!!error);
            });
    }, [values[name]]);

    const preparedTestId = testId ?? `${name}-input`;
    const preparedLabel = label ?? toUpperCaseWords(name);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, {
        field,
        form,
    }: FieldProps) => {
        const value = event.target.value.trim();

        if (hasSubmittingError) {
            form.setErrors({});
        } else {
            form.setFieldError(name, undefined);
        }

        if (disallowSpace) {
            event.target.value = value;
        }

        if (type === 'number') {
            if (value === '') {
                field.onChange(event);
                return;
            }

            let numberValue = Number(value) || 0;
            if (inputMinValue !== undefined && inputMinValue > numberValue) {
                numberValue = inputMinValue;
            }
            event.target.value = numberValue.toString();
        }

        field.onChange(event);
    };

    const shouldShowActiveValidators = (hasValue: boolean): boolean => (
        (hasValue && hasValidationError) || isFocused
    );

    const isInvalid = ({ field, form }: { field: FieldProps['field'], form: FieldProps['form'] }): boolean | undefined => {
        return (!!form.errors[name] && !!form.touched[name]) || (!!field.value && hasValidationError);
    };

    const InputWrapper = hasRevertButton ? InputWithRevertButtonWrapper : Fragment;
    const inputWrapperProps = hasRevertButton ? {
        'data-is-button-visible': isRevertBtnVisible,
    } : null;

    return (
        <Field name={name}>
            {({ field, form, meta }: FieldProps) => (
                <StyledFieldContainer data-testid={preparedTestId} {...BoxProps}>
                    {preparedLabel && (
                        <Label
                            isFocused={isFocused}
                            icon={labelIcon}
                            htmlFor={name}
                            required={required}
                        >
                            {preparedLabel}
                        </Label>
                    )}
                    <InputWrapper
                        {...inputWrapperProps}
                    >
                        <TextInput
                            multiline={multiline}
                            rows={rows}
                            className={`form-input${isInvalid({ field, form }) && !isFocused ? ' invalid' : ''}`}
                            type={type}
                            name={field.name}
                            value={field.value}
                            onFocus={() => {
                                setIsFocused(true);
                                handleFocus?.();
                            }}
                            onBlur={() => {
                                setIsFocused(false);
                                handleBlur?.();
                                setFieldValue(name, typeof field.value === 'string' ? field.value.trim() : field.value);
                            }}
                            onChange={(event) => handleChange(event, { field, form } as FieldProps)}
                            placeholder={placeholder}
                            disabled={disabled}
                            InputProps={{
                                endAdornment: endAdornment || (
                                    hasRevertButton && meta.initialValue !== meta.value && (
                                        <InputRevertButton
                                            testId={`${preparedTestId}-revert-button`}
                                            onClick={() => {
                                                form.setFieldValue(field.name, meta.initialValue);
                                                onRevert?.();
                                            }}
                                        />
                                    )
                                ),
                                ...inputProps,
                                inputProps: {
                                    'data-testid': `${preparedTestId}-element`,
                                    'data-required': required || undefined,
                                    maxLength: inputMaxLength,
                                },
                            }}
                        />
                    </InputWrapper>
                    {hasPasswordActiveValidator ? (
                        shouldShowActiveValidators(!!field.value) && <ActiveValidators value={field.value} />
                    ) : (
                        <ErrorMessage
                            name={name}
                            render={(message) => (
                                message.trim() ? (
                                    <StyledErrorMessage
                                        dangerouslySetInnerHTML={{ __html: message }}
                                        position={errorPosition}
                                    />
                                ) : null
                            )}
                        />
                    )}
                </StyledFieldContainer>
            )}
        </Field>
    );
};

export default LabeledInput;
