import React, { FC } from 'react';
import { Box } from '@mui/material';
import { DiscountType } from 'src/constants';
import {
    StyledDiscountInfo,
    StyledPriceLabel,
} from 'src/pages/udb/CreateSubscription/components/PricingTermsStep/PricingTermsStep.styles';
import { getDiscountContentToRender } from 'src/pages/udb/CreateSubscription/services/pricing-terms-step-getters';

type FinalPriceRowTitleProps = {
    hasDiscount: boolean;
    selectedDiscountType?: DiscountType;
    discountAmount: number;
};

export const FinalPriceRowTitle: FC<FinalPriceRowTitleProps> = ({
    selectedDiscountType,
    discountAmount,
    hasDiscount,
}) => {
    const shouldRenderDiscount = hasDiscount
        && selectedDiscountType
        && discountAmount !== 0
        && !Number.isNaN(discountAmount);

    return (
        <Box>
            <StyledPriceLabel variant="caption">
                Final price
                {shouldRenderDiscount && (
                    <StyledPriceLabel display={{ xs: 'none', sm: 'inline' }}>
                        &nbsp;({
                            getDiscountContentToRender(discountAmount, selectedDiscountType)
                        })
                    </StyledPriceLabel>
                )}:
            </StyledPriceLabel>
            {shouldRenderDiscount && (
                <StyledDiscountInfo display={{ xs: 'block', sm: 'none' }}>
                    {
                        getDiscountContentToRender(discountAmount, selectedDiscountType)
                    }
                </StyledDiscountInfo>
            )}
        </Box>
    );
};

export default FinalPriceRowTitle;
