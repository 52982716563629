import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import { ssoApiClient } from 'src/services/sso-api';
import { EnableTFAModalForm } from 'src/components/Forms/EnableTFAModalForm';
import { Button } from 'src/components/Buttons';
import PageTitle from 'src/components/PageTitle';
import { SingleModalLayout } from 'src/layouts';

const ForceEnableTFAPage: FC = () => {
    const [showSecondStep, setShowSecondStep] = useState<boolean>(false);

    const logOut = () => {
        ssoApiClient.revokeToken().catch(() => null);
    };

    if (showSecondStep) {
        return (
            <EnableTFAModalForm
                ModalContainer={SingleModalLayout}
                manualSetupGoBackFunctionName="goBackAction"
                modalContainerProps={{
                    testId: 'enable-2fa-form',
                    headerTitle: 'Enable two-factor authentication',
                    variant: 'enable2faForm',
                    goBackAction: () => setShowSecondStep(false),
                }}
                manualSetupContainerProps={{
                    testId: 'enable-2fa-manually-setup',
                    headerTitle: 'Enable two-factor authentication',
                }}
            />
        );
    }

    return (
        <SingleModalLayout testId="force-enable-2fa">
            <PageTitle
                title="Enable 2FA to continue"
                subtitle="You were locked out of your account because you didn’t enable the 2FA in the requested period. In order to continue using your account please enable the two-factor authentication now."
                testId="form-title"
                marginBottom={3}
                variant="form"
            />
            <Stack spacing={{ xs: 2, sm: 2.5 }}>
                <Button fullWidth onClick={() => setShowSecondStep(true)}>
                    Enable now
                </Button>
                <Button fullWidth variant="secondary" onClick={logOut}>
                    Log out
                </Button>
            </Stack>
        </SingleModalLayout>
    );
};

export default ForceEnableTFAPage;
