import React, { FC } from 'react';
import { Grid, type GridProps } from '@mui/material';
import { StyledArrowBackIcon, StyledHeaderContainer, StyledHeaderTitle } from './Header.styles';
import { TextButton } from 'src/components/Buttons';
import { ReactComponent as LogoIcon } from 'src/assets/icons/endpoints-news-logo.svg';

export type HeaderProps = {
    goBackAction?: () => void;
    title?: string;
} & Pick<GridProps, 'marginY'>;

const Header: FC<HeaderProps> = ({ goBackAction, marginY, title }) => {
    return (
        <StyledHeaderContainer
            container
            paddingX={{ sm: 4.5, xs: 2 }}
            marginY={marginY}
            data-testid="modal-page-header-container"
        >
            <Grid item sm={2} xs={1} display="flex" alignItems="center">
                {!!goBackAction && (
                    <TextButton onClick={goBackAction}>
                        <StyledArrowBackIcon data-testid="arrow-back" />
                    </TextButton>
                )}
            </Grid>
            <Grid
                item
                sm={8}
                xs={10}
                textAlign="center"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                {title ? (
                    <StyledHeaderTitle>
                        {title}
                    </StyledHeaderTitle>
                ) : (
                    <LogoIcon />
                )}
            </Grid>
        </StyledHeaderContainer>
    );
};

export default Header;
